.cards {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 50px;

    --card-size: 50%;
    
    @include rmin($tablet) {
        --card-size: 33.33333%;
    }
    @include rmin($desktop) {
        --card-size: 25%;
    }

    & > &__item {
        flex-basis: var(--card-size);
        max-width: var(--card-size);
        margin-bottom: 10px;
    }

    &__item {
        position: relative;

        max-width: 50%;
        flex-basis: 50%;
        margin: 10px 0;
    }

    &__info-wrapper {
        p {
            font-size: 14px;
            text-align: center;
            font-weight: 300;

            @include rmin($tablet) {
                font-size: 18px;
            }

            @include rmin($desktop) {
                min-height: 55px;
            }
        }
    }

    &__status-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        height: 25px;

        font-size: 0;
    }

    &__status-indicator {
        width: auto;
        height: 100%;
    }

    &--quad-column > &__item {
        --card-size: 50%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;

        text-decoration: none;
        color: #000;
    }

    &__content--height-a {
        height: auto;
        margin-bottom: 15px;
    }

    &__image-wrapper {
        // new
        img {
            // width: auto;
            width: 100%;
            height: 160px;
            max-height: none;

            display: flex;
            margin: 0 auto;
            // object-fit: cover;
            object-fit: contain;
        }
    }

    &__favorite-btn {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 2;

        // display: none;

        background-color: transparent;
        cursor: pointer;
        color: var(--gray-100);

        &:hover,
        &--checked {
            color: var(--orange);
        }

        @include rmin($desktop) {
            top: 10px;
            right: 10px;
        }
    }

    &__delete-btn {
        &:hover,
        &:focus {
            color: var(--orange);
        }
    }

    &__favorite-icon {
        width: 16px;
        height: 16px;
        fill: currentColor;
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 10px;
    }

    &__brand-name {
        margin-bottom: 7px;

        font-size: 14px !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        text-align: center;
    }

    &__old-price {
        color: var(--gray);
        text-decoration: line-through;
        font-weight: 500;
    }

    &__sale-info {
        text-align: center;
    }

    &__sale-label {
        font-weight: 300;
        // margin-left: 5px;

        &:not(:empty)::before {
            content: "|";

            margin: 0 5px;
            text-decoration: none;
        }
    }

    &__price {
        margin-top: 2px;

        font-weight: 500;
        font-size: 15px;
        color: var(--orange);
    }

    &__detail,
    &__available-sizes-wrapper {
        display: none !important;
    }

    &__available-sizes-wrapper p {
        min-height: unset !important;
    }

    // select
    &__select-wrapper {
        position: relative;
        max-width: 240px;
        margin: 0 auto 10px;

        &::after {
            content: "";
            position: absolute;
            right: 40px;
            top: 0;
            bottom: 0;

            width: 1px;

            background-color: #b6b6b6;
        }
    }

    &__sizes-select {
        width: 100%;
        color: var(--gray);
        border: solid 1px #b6b6b6;
        margin: unset;
        padding: 10px 5px;

        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='4 -10 315 310' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23b6b6b6' d='M306 94.35l-35.7-35.7L153 175.95 35.7 58.65 0 94.35l153 153z'/%3E%3C/svg%3E");
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 15px;
        background-size: 10px;
        border-radius: 5px;
    }

    &--quad-column {
        .btn-product--sm {
            padding: 0 5px;
            height: 40px;
            font-size: 14px;
        }
    }

    &--quad-column &__sizes-select {
        background-size: 20px !important;
        margin: unset;
    }

    &--quad-column &__info-wrapper {
        min-height: 110px;
    }

    &--quad-column &__item--paddings {
        padding-left: 2px;
        padding-right: 2px;
    }
    &__brand-name {
        font-weight: 500 !important;
    }
    &__price {
        font-size: 17px;
    }
}

@include rmin($sm-phone) {
    .cards {
        &__image-wrapper {
            img {
                height: 190px;
            }
        }
    }
}

@include rmin($tablet) {
    .cards {
        & > &__item {
            margin-bottom: 20px;

            &--paddings {
                padding: 0 5px;
            }
        }

        &__status-wrapper {
            height: 30px;
        }

        &--quad-column > &__item {
            --card-size: 25%;
        }

        &__content--height-a {
            margin-bottom: 25px;
        }

        &__image-wrapper {
            img {
                height: 244px;
            }
        }

        &__favorite-btn {
            display: block;
        }

        &__favorite-icon {
            width: 22px;
            height: 22px;
        }

        &__brand-name {
            font-size: 16px !important;
        }

        &__price {
            margin-top: 7px;
            font-size: 25px;
        }

        &__sizes-select {
            font-size: 12px;
            background-size: unset;
        }

        &__sale-info {
            min-height: 28px;
        }
    }
}

@include rmin($desktop) {
    .cards {
        &__content {
            align-items: center;
        }

        &__image-wrapper,
        &__detail {
            width: 250px;
            height: 250px;

            img {
                // height: 200px;
            }
        }

        &__detail {
            left: 50% !important;
            transform: translateX(-50%);
        }

        &__detail,
        &__available-sizes-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;

            display: unset;

            background-color: #fff;
            opacity: 0;
            transition: opacity 0.25s ease-in-out;

            object-fit: cover;
            object-position: center;
        }

        &__image-wrapper:hover &__detail,
        &__info-wrapper:hover &__available-sizes-wrapper {
            opacity: 1;
        }

        &__info-wrapper {
            position: relative;
        }

        &__available-sizes-wrapper {
            display: block;
            height: 100%;
            background-color: #fff;

            p {
                text-align: center;
                font-weight: 500;
                font-size: 18px !important;
            }
        }

        &__available-sizes {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 5px;

            text-align: center;
            font-size: 14px !important;

            span {
                min-width: 25px;
                padding: 3px;
                margin-bottom: 5px;

                background-color: var(--orange);
                color: #fff;
                border-radius: 5px;

                // &::after {
                //     content: ';';
                // }
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            span.unavailable {
                border-color: var(--gray-100);
                color: var(--gray-100);
                border-width: 1.5px;
                border-style: solid;
                pointer-events: none;
                background-color: transparent;
            }
        }

        &__sizes-select {
            padding-left: 20px;
            padding-right: 20px;
            font-size: 15px;
        }

        // &__favorite-btn {
        //     top: 15px;
        // }

        &__status-wrapper {
            top: 15px;
            left: 15px;
        }

        &__item {
            margin: unset !important;
            padding: 25px;

            border-top: 1px solid var(--gray-300);

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                border-top: unset;
            }

            &:nth-child(3n + 2) {
                border-left: 1px solid var(--gray-300);
                border-right: 1px solid var(--gray-300);
            }
        }

        // cards--quad-column
        &--quad-column &__item {
            border: unset;
            margin-bottom: 35px !important;

            .btn-product--sm {
                font-size: 16px;
                height: 50px;
            }
        }

        &--quad-column &__info-wrapper {
            min-height: unset;
        }

        &--quad-column &__item--paddings {
            padding-left: unset;
            padding-right: unset;
        }
        &__brand-name {
            font-size: 20px !important;
            min-height: unset !important;
        }
        &__sale-info {
            font-size: 20px;
        }
        &__price {
            font-size: 30px;
        }
    }

    .main__product-cards {
        .cards__detail {
            top: 25px;
        }
    }
}
