.personal{
    &-acc__inner{
        display: flex;
        flex-direction: column;
    }
    &-acc__text{
        font-size: 20px;
        font-weight: 500;
        color: var(--black);
    }
    &-acc__order-info{
        margin-bottom: 25px ;
        text-align: center;
    }
    &-acc__wrapper{ 
        position: relative;
        padding-top: 90px;
    }
    &-acc__tabs-wrapper{
        position: absolute;
        top: 0;
        width: 100%;

        .for-customers__item {
            width: 25% !important;

            @include rmin($desktop) {
                width: 100% !important;
            }
        }
    }
    &-acc__info{
        margin-bottom: 14px !important;
    }
    &-acc__cabinet {
        margin: 0 -10px 35px !important;
    }
    &-acc__cabinet .wa-name{
        margin-bottom: 5px;
        font-size: 16px !important;
        color: var(--black) !important;
    }
    &-acc__cabinet .wa-value{
        font-weight: 500;
    }
    &-acc__edit-btn{
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 35px ;
        margin-top: 15px;

        font-size: 16px;
        font-weight: 500;
        letter-spacing: unset;
        color: var(--white);
        border-radius: 10px;
        background: linear-gradient(90deg, #FA5B0C 0%, #FFA173 90.81%);

        & svg{
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
    &-acc__edit-title{
        margin-bottom: 15px;
        padding: 0;

        font-size: 18px;
        font-weight: 500;
    }
    &-acc__main-input {
        display:flex;
        flex-direction: column;
        margin-bottom: 10px !important;
        font-size: 16px;
    }
    &-acc__main-input input,
    &-acc__main-input select{
        width: 100%;
        height: 31px;
        padding: 0 12px;
        font-size: 16px;
        border: 1px solid var(--gray-400);
        border-radius: 9px;
        background-color: var(--white);
    }
    &-acc__main-input select{
        color: var(--gray-500);
        appearance: none;
        background: url('../img/angle-down.svg') center no-repeat;
        background-position: 99% 50%;
    }
    &-acc__main-input input:hover{
        border: 1px solid var(--orange);
    }
    &-acc__address-shipping{
        flex-direction: column;
        margin-top: 15px !important;
    }
    &-acc__edit-form{
        width: 100%;
    }
    &-acc__save-btn{
        height: 35px;
        margin-right: 15px;
        min-width: 50%;

        font-size: 16px;
        font-weight: bold;
        color: var(--white);
        background: linear-gradient(90deg, #FA5B0C 0%, #FFA173 90.81%);
        border-radius: 10px;
        text-transform: capitalize;
    }
    &-acc__cancel-btn{
        display: inline-flex;
        align-items: center;
        justify-content: center;

        height: 35px;
        min-width: 45%;
        font-size: 16px;
        font-weight: bold;
        color: var(--gray-500) !important;
        background-color: var(--white-200);
        border-radius: 10px;
        text-decoration: unset !important;
        text-transform: capitalize;
    }
    &-form__title{
        margin-bottom: 25px;
        font-size: 24px;
        text-align: center;
    }
    &-form__wrapper{
        margin: 100px 0;
    }
    &-form__items{
        width: 300px;
    }
    &-form__inputs{
        max-width: 300px !important;

        & input{
            border: 1px solid var(--gray-400) !important;
        }

    }
    &-form__item{
        margin-bottom: 20px !important;
    }
    &-form__item--required:after{
        content: '*';
        color: var(--orange);
    }
    &-form__btns{
        display: flex;
        flex-direction: column !important;
    }
    &-form__btn{
        order:-1 !important;
        margin-bottom: 15px;
        font-size: 16px !important;

        & input{
            height: 35px !important;
            min-width: 280px;

            font-size: 16px !important; 
            letter-spacing: unset !important;
            text-transform: unset !important;
            border: unset !important;
        }

        &--rounded{
            border-radius: 10px !important;
        }
    }
    &-form__url{
        order: 2 !important;
    }
    &-form__help{
        margin: 0 auto;
        font-size: 14px;
    }
    &-form__restore{
        display: block;
        font-size: 14px;
        text-align: right;
    }
}

@include rmin($tablet){
    .personal{
        &-acc__navigation{
            margin-top: 70px;
        }    
        &-acc__info{
            margin-top: 50px !important;
        }    
        &-acc__edit-btn{
            margin-left: unset !important;
        }
        &-acc__cabinet{
            max-width: unset !important;
        }
        &-acc__input-field{
            width: 100%;
        }
        &-acc__save-btn{
            padding: 0 70px;
        }
        &-acc__cancel-btn{
            padding: 0 68px;
        }
    }
}
@include rmin($desktop) {
    .personal{
        &-acc__inner{
            display: flex;
            margin-top: unset;
            gap: 10px;
        }
            &-acc__tabs-wrapper{
                position: unset;
                width: auto;
                margin-top: unset !important;
            }
        &-acc__wrapper{
            padding-top: unset;

            & .tariff__table{
                table-layout: unset
            }
        }
        &-acc__navigation{
            margin-top: 35px;
        }
        &-acc__info{
            margin-top: 10px !important;
        }
        &-acc__order-info{
            text-align: left;
        }
        &-acc__cab-inner{
            display: flex;
        }
        &-acc__field{
            flex-direction: column;
        }
        &-acc__cabinet .wa-value{
            font-size: 20px;
        }
        &-acc__edit-btn{
            height: 50px;
            min-width: 250px;
            width: auto;
            font-size: 20px;
            color: var(--white);
            border-radius: 10px;
            background: linear-gradient(90deg, #FA5B0C 0%, #FFA173 90.81%);

            & svg{
                width: 22px;
                height: 22px;
            }
        }
        &-acc__edit-form{
            min-width: 600px;
            max-width: 600px;
        }
        &-acc__save-btn{
            min-width: 300px;
        }
        &-acc__cancel-btn{
            padding: 0 40px;
        }
        &-acc__fav-items>.cards__item{
            --card-size:33%;
        }
    }
}

@include rmin($desktop-s){
    .personal{
        &-acc__wrapper{
            padding-top: unset;

            & .tariff__table{
                table-layout:fixed;
            }
        }
    }
}