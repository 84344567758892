.brands {
    &__info {
        margin-top: 0;
        margin-bottom: 5px;
    }

    &__section {
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;

        @include rmin($sm-phone) {
            gap: 10px;
        }

        @include rmin($tablet) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include rmin($desktop) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__list-item {
        border: 0.5px solid var(--gray-300);

        @include rmin($sm-phone) {
            border-width: 1px;
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 15px;

            color: var(--gray-500);

            span {
                display: block;
                margin-top: 10px;

                font-size: 12px;
                text-align: center;
            }

            @include rmin($sm-phone) {
                padding: 15px 25px;

                span {
                    font-size: 14px;
                }
            }
            @include rmin($desktop) {
                span {
                    font-size: 16px;
                }
            }
        }

        &:hover {
            border-color: var(--orange);
        }

        &:hover a {
            color: var(--orange);
        }
    }
    &__item-img {
    }
}

@include rmin($desktop) {
    .brands {
        &__list-item {
        }
    }
}
