.input,
.s-order-page,
.wa-login-form-fields,
// .wa-forgotpassword-form-fields,
.wa-forgotpassword-form-wrapper,
.wa-signup-form-fields,
.multiform-wrap,
.input__form,
.wa-login-form-actions,
main .multiform-wrap.default-theme,
#wa-my-info-edit-wrapper {
    // max-width: 690px;
    width: 690px;
    // margin: 0 auto;
    box-shadow: none;
    border: none;

    &__form {}

    .wa-field-wrapper,
    .wa-plugin-fields-wrapper,
    .wa-field:not(.wa-field-confirmation-code),
    &__fieldset {
        display: block !important;
        width: auto !important;
        margin-bottom: 6px;
        padding-top: 0 !important;
        padding-right: 0 !important;
    }

    .wa-label,
    .wa-name,
    .multiform-gap-name,
    &__label-field {
        display: block !important;
        margin-bottom: 5px !important;

        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: inherit !important;
        color: inherit !important;
        white-space: inherit !important;
    }

    .multiform-gap-value input,
    .wa-value input {
        width: 100% !important;
        margin-bottom: 0;
    }

    .wa-input,
    .wa-value input,
    .wa-select,
    .wa-field-address-shipping .field select,
    .wa-dropdown-toggle,
    .select2-selection,
    .multiform-gap-value input,
    &__input-field {
        display: block;
        width: 100%;

        font: inherit !important;

        border: 1px solid var(--gray-2);
        border-radius: 10px;

        &:disabled {
            color: #bbb;
            border-color: var(--light-gray);
            background-color: #f0f0f0;
        }

        &:focus,
        &:hover {
            border-color: var(--orange) !important;
        }
    }

    .wa-value span {
      font-size: 0.9em;
    }

    .wa-input,
    .wa-value input,
    .multiform-gap-value input,
    .wa-select,
    .wa-dropdown-toggle,
    &__input-field {
        padding: 4px 12px !important;
    }
    .select2-container {
        display: block;
        width: auto !important;
    }
    .select2-dropdown {
        max-width: 90% !important;
    }
    .s-required .multiform-gap-name,
    &__fieldset--required &__label-field {
        &::after {
            content: '*';
        }
    }
    &__textarea-field {
    }
    &__review {
        display: none !important;
    }

    &__submit {
        max-width: 285px;
        width: 60%;
        margin: 10px auto;
    }
}

main .multiform-wrap.default-theme {
    max-width: 690px;
    width: auto;
    margin: 0;
}

.wa-forgotpassword-form-wrapper .wa-login-form-title {
    margin-bottom: 34px;
}


.wa-signup-form-wrapper {
}

.wa-login-form-fields,
.wa-login-form-actions,
.wa-signup-form-fields,
.wa-forgotpassword-form-fields,
.wa-forgotpassword-form-actions,
.wa-signup-form-actions {
  max-width: 450px;

  &.js-signup-form-fields,
  &.js-signup-form-actions,
  &.js-login-form-fields-block,
  &.js-forgotpassword-form-fields,
  &.js-forgotpassword-form-actions,
  &.js-login-form-actions {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .wa-login-forgotpassword-url {
    font-weight: 700;
    text-decoration: underline;
    color: var(--orange);
  }
}

.wa-login-form-wrapper .wa-login-form-actions,
.wa-forgotpassword-form-wrapper .wa-forgotpassword-form-actions,
.wa-signup-form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wa-signup-url,
    .wa-login-url {
        order: -1;
        margin-top: 0 !important;

        a {
          font-weight: 700;
          text-decoration: underline;
          color: var(--orange);
        }
    }
}

.wa-login-forgotpassword-url {
    margin: 10px 0;
}


.wa-signup-form-actions {
  margin-top: 20px !important;
}


@include rmin($desktop) {
    .input,
    .s-order-page,
    .wa-login-form-fields,
    .multiform-wrap,
    .input__form {
        &__form {
            // display: flex;
            // flex-wrap: wrap;
        }

        .wa-field-wrapper,
        .wa-plugin-fields-wrapper,
        &__fieldset {
            flex-grow: 1;
            flex-basis: 50%;
            margin-bottom: 15px;
        }

        .wa-step-auth-section .wa-field-wrapper.size-medium {
            flex-basis: calc(50% - 20px);

            &:not(:last-of-type):nth-child(odd) {
                margin-right: 20px;
            }
        }

        .wa-step-auth-section .wa-field-wrapper,
        .wa-plugin-fields-wrapper,
        &__fieldset {
            // &:not(:last-of-type):nth-child(odd) {
            //     flex-basis: calc(50% - 20px);
            //     margin-right: 20px;
            // }
            &:last-of-type {
                // flex-basis: 100%;
            }
        }

        &__submit {
            margin-left: 0;
            margin: 0;
        }
    }
}