.subscribe {
    margin-bottom: 25px;

    p {
        font-weight: 300;
    }

    h2 {
        padding: 0 5px;
        font-size: 20px;
    }

    &__form {
        margin: 15px 0;
    }

    &__form-wrapper {
        position: relative;
        max-width: 450px;
        margin: 0 auto;
    }

    &__choice-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 10px;

        input {
            margin: 0 5px;
            cursor: pointer;
        }

        label {
            margin: 0 15px;
            display: flex;
            align-items: center;

            cursor: pointer;
        }
    }

    &__input {
        padding: 0 15px;

        width: 100%;
        height: 35px;

        border-radius: 50px;
        box-shadow: 0px 3px 20px rgba(102, 102, 102, 0.25);
    }

    &__label {}

    &__button {
        position: absolute;

        top: 0;
        right: 0;

        padding: 0 35px;
        height: 35px;

        &::after {
            border: unset;
        }
    }

    &__text {
        margin: 5px;

        font-size: 12px;
        color: var(--gray);

        a {
            color: inherit;
        }
    }
}

@include rmin($tablet) {
    .subscribe {
        margin-bottom: 50px;

        h2 {
            font-size: 25px;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px;
        }

        &__text {
            font-size: 14px;
        }

        &__button {
            padding: 0 50px;
        }
    }
}
