.nav-menu {
    position: fixed;
    top: 0;
    left: -280px;
    z-index: 1;

    width: 280px;

    color: #000;

    background-color: #fff;

    overflow-x: hidden;
    overflow-y: auto;
    transition: transform 0.3s ease-in;
    // will-change: transform;
    max-height: 100vh;
    // height: 100%;
    // max-height: calc(var(--hv, 1vh) * 100);

    &__wrapper {
        // min-height: calc(var(--hv, 1vh) * 100 - 50px);
        height: calc(100% - 50px);
        overflow-x: hidden;
        overflow-y: auto;
        // overscroll-behavior-y: contain;
    }

    /***** LISTS *****/
    &__list {}

    &__sm-list {
        display: flex;
    }

    &__list--root {
        height: 100%;
    }

    &__list--opening {
        width: 100%;

        background-color: #fff;

        transform: translate3d(100%, 0, 0);
        // opacity: 0;
        transition: opacity 0.3s;
        will-change: transform, opacity;
        pointer-events: none;
    }

    &__list--second,
    &__list--third {
        background-color: #fff;

        transition: transform .3s, opacity .3s;
        overflow-y: auto;
        // overscroll-behavior-y: contzain;
    }

    &__list--second {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__list--third {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 3;
        // top: -51px;

        height: 100%;

        transform: translate3d(100%, 0, 0);
        // max-height: calc(var(--hv, 1vh) * 100 - 107px) !important;
        // max-height: calc(100vh - 101px);
    }

    /***** LIST ITEMS *****/
    &__list-item {}

    &__sm-list-item {
        flex-grow: 1;
        text-align: center;
    }

    &__list-item--root {
        // position: absolute;
        // top: 50px;
        display: none;
        width: 100%;
        height: 100%;

        transition: transform .3s, opacity .3s;
        transform: translate3d(0, 0, 0);
    }

    &__list-item--second {}

    &__list-item--hot {
        color: #f00;
        text-transform: uppercase;
    }

    &__list-item--customers,
    &__shops-row,
    &__item-title {
        margin-top: 25px;
    }

    /***** ITEM LINKS *****/
    &__item-link,
    &__login-button,
    &__item-title {
        display: block;
        width: 100%;
        padding: 10px 20px;
        padding-right: 25px;

        color: inherit;
        font-size: 13px !important;
        text-align: left;

        border-bottom: 1px solid #f1f1f1;
    }

    &__item-link {
        font-weight: 300;

        &--hot {
            color: #f00;
            text-transform: uppercase;
        }

        &--title {
            // font-size: 17px !important;
            font-weight: 500;
            color: var(--orangered);
        }

        &--title-black {
            font-weight: 500;
            color: #000;
        }
    }

    &__item-link--root {
        padding: 16px 13px;

        // font-size: 18px !important;
        font-weight: 500;

        background-color: #fff;
    }

    &__item-link--arrowed,
    &__item-link--previous {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 20px;
            left: auto;
            bottom: 0;

            height: 6px;
            width: 6px;
            margin: auto;

            border-top: 1px solid #303030;
            border-right: 1px solid #303030;
            transform: rotate(45deg);
        }
    }

    &__item-link--second {
        transition: transform 0.3s;
    }

    &__item-link--previous {
        padding-left: 35px;

        &::after {
            right: auto;
            left: 20px;

            transform: rotate(225deg);
        }
    }

    &__list-item--root>&__item-link--root {
        display: none;
    }

    &__item-link--opened {}

    &__brand-img {
        display: none;
    }

    &__sm-item-link {
        border-bottom: 0;
    }

    &__sm-item-link &__link-title {
        border-bottom: 3px solid transparent;
    }

    &__sm-item-link--opened &__link-title {
        border-bottom-color: currentColor;
    }

    &__item-link--customers {
        border-bottom: none;
        color: var(--orange);
    }

    &__login-button,
    &__item-title {
        padding: 0;

        text-align: center;
    }

    &__login-button {
        width: auto;
        margin: 10px 20px;
        padding: 10px 0;

        font-weight: bold;
        border-radius: 7.5px;

        transition: opacity 0.15s;

        border: 2px solid #151515;

        &:hover,
        &:focus {
            opacity: 0.75;
        }
    }

    &__login-button--filled {
        background-color: #151515;
        color: #fff;
    }

    &__item-title {
        font-size: 16px !important;

        border-bottom: none;
    }

    &__shops-row {
        display: flex;
        justify-content: space-evenly;

        text-align: center;
    }

    &__shops-info {
        padding: 5px 20px;

        color: inherit;

        svg {
            fill: currentColor;
        }

        &:hover,
        &:focus {
            color: var(--orange);
        }
    }

    &__shops-title {
        display: block;

        font-size: 13px !important;
    }

    &__contacts-phone {
        display: block;
        margin-top: 10px;

        color: var(--orange);
        font-size: 20px !important;
        font-weight: bold;
        text-align: center;

        span {
            display: block;
            margin-bottom: 5px;

            color: #000;
        }
    }

    /****** LIST OPENED ******/
    &__item-link--opened+&__list--opening,
    &__list-item--root-opened {
        display: block;

        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: unset;
    }

    // list--second, list--third to LEFT
    &__list--to-left &__item-link--root,
    &__list--to-left &__item-link--second {
        // transform: translate3d(-100%, 0, 0);
    }

    // &__list--to-left &__list--third {
    // 	opacity: 1;
    // 	visibility: visible;
    // }
}

.menu-opened .nav-menu {
    transform: translate3d(280px, 0, 0);
}

@include rmin($desktop) {
    .nav-menu {
        position: static;

        width: auto;
        height: auto;
        max-height: none;

        border-bottom: 1px solid #f1f1f1;
        transition: none;
        overflow: visible;
        transition: transform .3s ease-in;
        will-change: transform;

        &__wrapper {
            position: relative;
            z-index: 4;

            height: auto;
            overflow: visible;

            background-color: #fff;
        }

        &__sm-list {
            display: none;
        }

        &__list {
            &--lg-column {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                max-height: 100%;
            }
        }

        &__list-sale {
            &--sale {
                flex-basis: 75%;
                display: flex;
                flex-wrap: wrap;

                &>* {
                    flex-basis: 50%;
                }
            }

            &--brands {
                flex-grow: 1;
            }
        }

        &__list--root {
            display: flex;
            justify-content: center;
        }

        &__list--opening {
            display: block !important;

            // overflow: visible;
            pointer-events: auto;
        }

        &__list--second,
        &__list--third {
            position: static;
            overscroll-behavior: unset;
            overflow: unset;
        }

        // on hover -> will be opened
        &__list-wrapper {
            // &__list--second {
            // display: none;
            position: absolute;
            left: 0;

            display: unset;
            // flex-direction: row;
            // justify-content: center;
            width: 100%;
            height: auto;
            // padding: 30px 0;
            padding: 20px 40px;

            opacity: 0;
            background-color: #fff;
            transition: opacity 0.2s 0.15s;
            will-change: opacity;
            pointer-events: none;
            visibility: hidden;
        }

        &__list-bg {
            position: relative;

            display: flex;
            justify-content: space-between;
            max-width: 1030px;
            margin: 0 auto;
        }

        &__list--second {
            // position: relative;
            min-height: 400px;

            display: unset;
            width: 20%;
            height: auto;
        }

        &__item-img-link {
            max-width: 32.5%;
            flex-grow: 1;
        }

        &__item-img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        // &__list-item--root:hover &__list--second,
        // &__list-item--root:focus &__list--second {
        &__list-item--root--opened &__list-wrapper,
        &__list-item--root:hover &__list-wrapper,
        &__list-item--root:focus &__list-wrapper {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }

        &__list--third {
            display: flex !important;
            flex-wrap: wrap;
            align-content: flex-start;
            height: unset;
            height: 100%;
            // max-height: 168px;

            // border-right: 1px solid var(--gray);
            &-column {
                flex-direction: column;
            }
        }

        &__list--third>&__list-item--second:not(:first-child) {
            flex-basis: 33.333%;
        }

        &__list-item--second &__list--third {
            transform: unset;
            opacity: 0;
            pointer-events: none;
        }

        &__list--second>&__list-item--second-opened &__list--third,
        &__list--second>&__list-item--second-opened &__list--third {
            // position: relative;

            // flex-basis: 210px;
            opacity: 1;
            pointer-events: auto;
        }

        &__list--second &__list-item--second &__list--third {
            position: absolute;
            top: 0px;
            left: 50%;

            max-width: 55%;
            transform: translateX(-70%);
            // display: none !important;
        }

        &__list--second>&__list-item--second:nth-child(1n + 7) {
            display: none;
        }

        &__list-item--root {
            width: auto;

            transform: unset;
            transition: none;
            will-change: unset;
        }

        &__list-item--second:nth-child(4) &__list--third {
            border-right: none;
        }

        &__list-item--second-opened>&__item-link,
        &__item-link:hover,
        &__item-link:focus {
            text-decoration: underline;
        }

        &__item-link {
            padding: 15px;

            border: none;
        }

        &__item-link--root {
            padding: 22px 33px;

            font-size: 18px !important;
            border-bottom: none;

            &:hover,
            &:focus {
                color: var(--orange);
            }
        }

        &__item-link--previous::after,
        &__item-link--second::after {
            content: none;
        }

        &__item-link--second {
            font-size: 17px !important;
            font-weight: 500;
        }

        &__list--third &__item-link {
            // padding-right: 60px;
            font-size: 16px !important;
            text-align: center;
        }

        &__list-item--root>&__item-link--root {
            display: block;
        }


        /***********************/
        &__brands-list {
            display: flex;
            flex-wrap: wrap;

            text-align: center;

            &--big,
            &--small {
                flex-basis: 100%;

                &>* {
                    flex-basis: 33.333%;
                }
            }
        }

        &__list-item--brands a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &__brand-img {
            display: inline-block;
            max-width: 110px;
        }

        &__brand-title {
            display: none;
        }


        &__item-link--previous,
        &__list-item--customers,
        &__shops-row,
        &__contacts-phone,
        &__item-title,
        &__login-button {
            display: none;
        }
    }

    .form__wrapper--opened+.nav-menu {
        transform: translateY(60px);
    }

    .menu-opened .nav-menu {
        transform: none;
    }
}
