.form {
    &__wrapper {
        position: absolute;
        top: 50px;

        z-index: 4;

        width: 100%;
        padding: 12px;

        background-color: #000;
        opacity: 0;

        transform: translateY(-100%);
        transition: transform 0.3s ease-in;
        will-change: transform;

        &--opened {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__row {}

    &__row--flex {
        display: flex;
    }

    &__input {
        flex-grow: 1;
        padding: 8px;
        margin-top: auto;
        margin-bottom: auto;

        appearance: none;

        font-size: 14px;
        line-height: inherit;
        color: #000;
    }

    &__label {}

    &__submit-btn {
        margin-left: 14px;
        padding: 6px 10px;
        border: 1px solid #7b7b7b;
    }

    &__submit-icon {
        width: 20px;
        height: 20px;

        fill: #fff;
    }

    &__input,
    &__submit-btn {
        border-radius: 4px;
    }
}

.menu-opened {
    .form__wrapper {
        transform: translate3d(280px, -100%, 0);

        @include rmin($desktop) {
            transform: translateY(-100%);
        }
    }

    .form__wrapper--opened {
        transform: translate3d(280px, 0, 0);

        @include rmin($desktop) {
            transform: translateY(0);
        }
    }
}


@include rmin($desktop) {
    .form {
        &__wrapper {
            top: 76px;
            background-color: #fff;
            opacity: 1;

            &--opened {
                border-bottom: 1px solid #f1f1f1;
                box-shadow: 0 1px 10px var(--light-gray-2);
            }
        }

        &__input {
            background-color: var(--light-gray-2);

            &:focus,
            &:hover {
                box-shadow: 0px 0px 0px 1px var(--gray-2) inset;
            }
        }

        &__submit-btn {
            border: unset;
            background-color: var(--light-gray-2);

            &:hover,
            &:focus {
                background-color: var(--light-gray);
            }
        }

        &__submit-icon {
            fill: var(--gray);
        }

        &__submit-btn:hover &__submit-icon,
        &__submit-btn:focus &__submit-icon {
            fill: #fff;
        }
    }
}
