#page,
.custom-content-page {
  &__list {
      display: flex;
      flex-wrap: wrap;

      margin-top: 20px;
  }

  &__item {
      margin: 5px 0;
      width: 25%;

      span {
          font-size: 14px;
      }
  }

  h1 {
    font-size: 24px;
    text-align: center;
  }
  
 
    ul:not([class]),
    ol:not([class]) {
        margin-left: 5px;
        margin-bottom: 10px;
        
        li {
            margin: 7.5px 0;

            &::before {
                margin-right: 5px;
            }
        }
    }

    ul:not([class]) li::before {
        // content: "• ";

        color: var(--orange);
    }
    ol:not([class]) {
        counter-reset: section;
    }

    ol:not([class]) li {
        counter-increment: section;

        &::before {
            content: counters(section, '.') ") ";
        }
    }

//   ul {
//       list-style-type: disc;
//   }
//   ol {
//       list-style-type: decimal;
//   }

  &__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding: 5px 0 !important;
      border-bottom: unset !important;

      text-align: center;
  }

  p a {
      color: #2494bc;
      font-weight: 600;
  }

  &__shop-list {}

  &__shop-item {
      &:not(:last-child) {
          margin-bottom: 10px;
          border-bottom: $border-orange;
      }
  }

  &__shop-text-wrapper {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      text-align: center;

      h3 {
          color: #000 !important;
      }

      p {
          margin: 0 !important;
      }

      a {
          display: block;
          margin: 5px 0 !important;
      }

      strong {
          display: block;
          font-weight: 300;
          margin-top: 5px;
      }

      .cards__favorite-icon {
          margin-top: 5px;
          margin-right: 5px;
          fill: var(--light-orange);
      }
  }

  &__shop-container {}

  &__shop-img-wrapper {
      display: flex;
      margin: 20px 0;
  }

  &__shop-address {
      text-align: center;

      div {
          // display: flex;
      }

      address {
          font-style: normal;
      }

      a span {
          font-weight: 700;
          color: var(--light-orange) !important;
          border-bottom: 1px dashed var(--light-orange);
      }

  }

  &__bonus-title {
     font-size: 22px; 
     margin-top: 10px;
     margin-bottom: 15px;
  }

  &__btn-bonus {
      color: #fff !important;
      height: 50px;

      &::after {
          content: none;
      }
  }

  .tabs__content {
      margin-top: 0;
  }

  .tabs__item {
      padding: unset;
  }

  .tabs__content,
  .tabs__sub-content {
      h3 {
          margin-bottom: 15px;
          color: var(--orange);

          svg {
              margin-left: 3px;
              margin-bottom: -3px;
              width: 25px;
              height: 25px;

              fill: currentColor;
          }
      }

      p {
          margin: 15px 0;
      }

      a {
          text-decoration: none;
          color: #000;
      }
  }

  &__loyalty-tabs {
      flex-direction: column;
      margin-top: 20px;

      .tabs__sub-item {
          margin-right: unset !important;
          padding-top: 15px;
          padding-bottom: 15px;
          border: $border;

          text-align: center;
          font-weight: 700;
          font-size: 18px;
      }
  }

  #map {
      height: 320px;
      margin: 0 -10px;
  }
}

@include rmin($desktop) {
  #page,
  .custom-content-page {
      position: relative;

      // tabs
      .tabs__sub-list {
          justify-content: unset !important;
          margin-left: -10px;
      }
      .tabs__sub-item:first-child {
          margin-left: 12px;
      }

      h1,
      h2 {
          position: relative;
          padding: unset;
          margin-top: 0;
          margin-bottom: 30px;
          font-size: 24px;

          &::after {
              position: absolute;
              bottom: -15px;
              left: 50%;
              transform: translate(-50%, -50%);

              content: "";
              width: 85px;
              height: 4px;
              background-color: var(--orange);
          }
      }

      h1 {
        font-size: 32px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
          font-size: 16px;
      }

      .tabs__wrapper {
          display: flex;
      }

      .tabs__icon {
          width: 25px;
          height: 25px;
      }

      .tabs__content {
          flex: 1;
      }

      .tabs__sub-content {
          h3 {
              margin-bottom: 5px;
          }
      }

      &__shop-container {
          padding: 0 5px;
      }

      &__list {
          display: block;
          width: 220px;
          margin-top: unset;
      }

      &__item {
          width: unset;

          span {
              margin-left: 10px;
          }

          &:first-child {
              margin-top: unset;
          }
      }

      &__btn {
          flex-direction: row;
          margin: unset;
      }

      &__shop-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: unset;
      }

      &__shop-text-wrapper {
          align-items: unset;
          justify-content: center;

          .cards__favorite-icon {
              margin-top: unset;
          }
      }
      &__shop-text-wrapper {
          margin-bottom: 15px;
          font-size: 20px;

          text-align: left;
      }

      &__shop-address {
          display: flex;
          align-items: center;
          text-align: left;

          div {
              max-width: 330px;
          }

          a span {
              margin-left: 5px;
              font-size: 16px;
          }

          // a {
          //     display: none;
          // }
      }

      &__loyalty-tabs {
          flex-direction: unset;
          justify-content: center;

          :first-child {
              margin-right: -1px !important;
          }
          .tabs__sub-item {
              width: 350px;
              margin-bottom: unset;
              margin-left: unset;
          }
      }
      &__bonus-wrapper {
          display: flex;
          justify-content: center;
      }
      &__bonus-img {
          width: 350px;
      }
      &__bonus-text {
          width: 450px;
      }
      &__bonus-title {
         font-size: 35px; 
      }
      &__btn-bonus {
          max-width: 300px;
          margin: auto;
      }

      #map {
          height: 450px;
      }
  }
}

.contact-center {
    &__title,
    &__shop-container {
        text-align: center;
        justify-content: center;
    }
}