.tabs {
    &__title {
        h1 {
            font-weight: 500;
        }

        h2 {
            margin: 15px 0;
            padding: 0 15px;
            font-size: 24px;
            font-weight: 500;
        }
    }

    &__sub-title {
        margin-bottom: 14px;
        margin-top: 10px;

        color: #000 !important;
        text-align: center;
        font-size: 18px;
    }

    &__list {
        display: flex;
        justify-content: center;
    }

    &__content {
        display: none;
        margin-top: 25px;
    }

    &__content--current {
        display: block;
    }

    // &__content:not(:target) {
    //     display: none;
    // }
    // &__content:target {
    //     display: block;
    // }

    &__item {
        padding: 0 5px;

        cursor: pointer;

        button,
        a {
            color: var(--gray);
            padding: 0 10px 3px;
            font-size: 16px;
            font-weight: 500;
            background-color: transparent;
            text-decoration: none;
        }

        &:hover,
        &:focus {
            button,
            a {
                color: #000;
            }
        }
    }

    &__item--current {
        button,
        a {
            color: #000;
            border-bottom: 4px solid #000;
        }
    }

    &__icon {
        fill: var(--light-gray);
    }

    &__item--current &__icon {
        fill: var(--orange) !important;
    }

    // BEGIN SUBTABS
    &__sub-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__sub-content {
        display: none;
        margin-top: 10px;

        h3,
        strong {
            font-weight: 500;
        }

        p {
            font-weight: 300;
        }
    }

    &__sub-content--current {
        display: block;
    }

    &__sub-item {
        margin: 0 12px;

        font-size: 14px;
        color: var(--light-gray);

        cursor: pointer;
    }

    &__sub-link {
        position: relative;

        display: block;
        padding: 7.5px 0;
        width: -webkit-max-content;

        &:hover,
        &:focus {
            color: #000;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            left: 0;

            height: 1px;
            width: 100%;
        }
    }

    &__sub-category {
        max-height: 35px;
        padding: 0 15px;
        font-size: 14px;
        background: var(--gray-bg);
        text-transform: uppercase;
        scroll-snap-align: center;
        border-radius: 25px;
    }

    &__sub-category-filter {
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: 13px;
        color: var(--white);
        border-radius: 50px;
        background: var(--orange);
        scroll-snap-align: center;

        .product-filter__counter {
            margin-top: -2px;
            margin-left: 3px;
            padding: 2px 7px 0;
            color: var(--orange);
            background: var(--white);
        }
        .tabs__sub-link {
            &:hover,
            &:focus {
                color: none;
            }
        }
        &--active {
            color: var(--black);
            border: 1px solid var(--orange);
            background: var(--white);

            .product-filter__counter {
                color: var(--white);
                background: var(--orange);
            }
        }
    }

    &__sub-item--current &__sub-link {
        color: #000;

        font-weight: 500;

        &::after {
            background-color: #000;
        }
    }

    // END SUBTABS
}
.tabs-category {
    &__wrapper {
        position: relative;
        display: flex;
        align-items: center;
        align-content: stretch;
        gap: 7px;

        &--center {
            justify-content: center;
        }
    }

    &__list {
        display: flex;
        gap: 5px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        background: var(--gray-bg);
        border-radius: 25px;
    }
}

@include rmin($desktop) {
    .tabs {
        // margin-right: 50px;

        &__content {
            padding-left: 35px;
        }

        &__item {
            &--bordered {
            }
        }

        &__sub {
            margin-bottom: 15px;
        }

        &__sub-title {
            margin-top: 0;

            text-align: left;
        }

        &__sub-wrapper {
            padding-left: 35px;
        }

        &__sub-list {
            justify-content: space-between;
        }

        &__sub-category-filter {
            display: none;
        }

        &__sub-link {
            font-size: 20px;
        }

        &__sub-item {
            margin-left: unset !important;
            margin-right: 12px;

            font-size: 16px;

            // &:first-child {
            //     margin-left: 0;
            // }
        }

        &__sub-link {
        }
    }

    .tabs-category {
        display: none;
    }
}
