.refund {
    &__wrap {
        padding: 0 10px;
        margin: 25px auto;
    }
    &__profile-link-wrap {
        display: flex;
        justify-content: center;
    }
    &__profile-link {
        padding: 7px 15px 4px;
        
        background-color: var(--grayish);
        border-radius: 7px;

        &:hover {
            color: var(--orange);
        }
    }

    &-form__item {
        width: 100%;
        margin-bottom: 20px;
    }
    &-form__input {
        height: 32px;
        width: 100%;
        margin-top: 5px;
        padding: 3px 12px 0;

        border: 1px solid var(--gray-400);
        border-radius: 10px;

        font-size: 14px;
        font-weight: 300;

        &:hover,
        &:focus {
            border: 1px solid var(--orange);
        }
    }
    &-form__name {
        font-size: 14px;

        &--required::after {
            content: "*";
            margin-left: 5px;
            color: var(--orange);
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 35px;
        min-width: 100%;
        margin-top: 5px;
        font-size: 16px;
        font-weight: bold;
        color: var(--white);
        background: linear-gradient(90deg, #fa5b0c 0%, #ffa173 90.81%);
        border-radius: 10px;
    }
    &__added-title {
        margin-bottom: 10px;
        font-size: 18px;
        color: var(--orange);
    }
    &__policy {
        font-size: 12px;
        margin-bottom: 20px;

        & a {
            color: var(--orange);
            text-decoration: underline;
        }
    }
    &__title {
        margin-bottom: 25px;
        margin-top: 10px;
        padding: 0;
        text-align: left;
        font-size: 18px;
    }
    //choose items
    &__choose-items {
        margin-top: 15px;
        margin-bottom: 25px;
    }
    &__choose-title {
        margin-bottom: 15px;
        padding: 0;
        text-align: left;
        font-size: 18px;
    }
    &__choose-wrap {
        display: flex;
        flex-direction: column;
        padding: 15px;
        border: 1px solid var(--gray-400);
        border-radius: 10px;

        .alert {
            margin-top: 10px;
        }
    }
    &__choose-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14px;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }
    &__choose-count {
        min-width: 90px;
        margin-right: 35px;
        font-size: 16px;
        color: var(--gray-500);

        & span {
            margin-left: 5px;
            color: var(--black);
        }
    }
    &__choose-checkbox {
        display: flex;
        position: relative;
        font-size: 14px;
        font-weight: 300;
        & label {
            display: block;
            padding-left: 30px;
            margin-top: 2px;
        }

        label,
        input[type="submit"] {
            display: block;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;

            border: 1px solid var(--gray-400);
            border-radius: 5px;

            appearance: none;
        }
        input:checked {
            border: 4px solid var(--white);
            background-color: var(--orange-2);
            box-shadow: 0 0 0 1pt var(--black);
        }
    }
}

@include rmin($desktop) {
    .refund {
        &__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 800px;
            margin: 50px auto;
        }

        &__profile-link-wrap {
            display: unset;
            justify-content: unset;
        }
        &__profile-link {
            padding: 7px 7px 4px;
        }

        &__sub-content {
            max-width: 800px;
        }
        &-form__item {
            // max-width: 600px;
        }
        &-form__name {
            font-size: 16px;
        }
        &-form__input {
            font-size: 16px;
        }

        &__choose-item {
            justify-content: unset;
            font-size: 16px;
        }
        &__choose-count {
            margin-left: auto;
        }
        &__choose-checkbox {
            font-size: 16px;
            font-weight: 400;
        }
        &__btn {
            display: inline-flex;
            min-width: 280px;        
        }
    }
}
