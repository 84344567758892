.product {
    margin-top: 20px;

    &__text-wrapper {
        margin-top: 10px;

        .tabs__list {
            display: none;
        }

        .tabs__content {
            margin-top: 10px;
            display: block;
        }
    }

    &__info-wrapper {
        margin-top: 25px;

        h1 {
            padding: 0;
            font-size: 25px;
            font-weight: 500;
        }
    }

    &__img-container {
        height: 280px;

        img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
            object-fit: contain;
        }
    }

    &__img-wrapper {
        position: relative;
        max-width: 500px;
        margin: 0 auto;

        @include rmin($desktop) {
            margin: unset;
        }

        .cards__favorite-btn {
            right: 15px;
        }

        .cards__favorite-icon {
            width: 25px;
            height: 25px;
        }

        .glide__bullets {
            bottom: -17px;
        }

        .glide__bullet {
            margin: 0 1em;
            background-color: var(--light-gray);

            box-shadow: unset;

            img {
                display: none;
            }

            &:hover,
            &:focus {
                border-color: transparent;
            }
        }

        .glide__bullet--active {
            background-color: #000000;
        }
    }

    &__status-wrapper {
        display: flex;
        justify-content: space-between;

        margin: 5px 0;
        // padding-bottom: 10px;
        font-size: 14px;
        border-bottom: 1px solid var(--orange);
    }

    &__article {
        display: block;
        // margin-top: 10px;

        text-align: right;
    }

    &__status {
        color: var(--green);
        font-weight: 500;
    }

    &__status--not-available {
        color: var(--orange);
        // text-decoration: line-through;
    }

    &__status--few {
        color: #ffce00;
    }

    &__status--critical {
        color: #ec7474;
    }

    &__price-wrapper {
        position: absolute;
        top: 0;

        display: flex;
        justify-content: flex-start;

        span {
            font-size: 27px;
            margin-top: unset;
        }

        & span:first-child {
            order: 1;
            margin-left: 10px;
        }
    }

    &__old-price {
        color: var(--light-gray);
        font-weight: 500;

        margin-right: 10px;

        &.is-hidden {
            display: none;
        }
    }

    &__price {
        margin-top: unset;

        color: var(--orange);
    }

    &__price-position {
        // padding-top: 40px;
    }

    &__additionally-wrapper {
        display: flex;
        justify-content: flex-start;

        position: relative;

        @include rmin($desktop) {
            margin-top: 25px;
        }
    }

    &__one-additionaly-wrapper {
        position: relative;
    }

    &__one-additionaly-wrapper {
        .wa-error-text {
            margin: 0.25rem 0;
            color: #d02d14;
            font-size: 0.85rem;
            line-height: 1.17em;
            min-width: 120px;
        }
    }

    &__additionally-link {
        display: flex;
        align-items: center;
        min-width: max-content;

        text-decoration: none;
        color: #000;
        font-size: 12px;
        font-weight: 500;

        &:first-child {
            margin-right: 10px;
        }

        &:hover,
        &:focus {
            color: var(--orange);
        }
    }

    &__availability {
        margin-left: 3px;
        border-bottom: 1px dashed currentColor;
    }

    &__buy-wrapper {
        .cards__favorite-icon {
            margin-right: 15px;
            margin-top: 2px;
            width: 20px;
            height: 20px;
            // fill: #ffffff;

            &:hover,
            &:focus {
                // fill: currentColor;
            }
        }

        .btn-product {
            margin-bottom: 0;
            min-height: 50px;
        }

        .btn-product:disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .btn-product:disabled + .btn-product {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__buy-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 25px;
        .btn-product {
            font-size: 18px;
        }
    }

    &__buy-wrapper &__shop-cart--ua {
        width: 100%;
        
        @include rmin($desktop) {
            width: 50%;
        }
    }

    &__shop-cart {
        span {
            &::after {
                content: "Добавить в корзину";
            }
        }

        &--active {
            pointer-events: none;

            svg {
                display: none;
            }

            span {
                &::after {
                    content: "Товар добавлен в корзину";
                }
            }

            .btn-product {
                background: var(--green);
            }
        }
    }

    &__shop-cart--ua {
        span {
            &::after {
                content: "Додати у кошик" !important;
            }
        }

        &--active {
            pointer-events: none;

            svg {
                display: none;
            }

            span {
                &::after {
                    content: "Додано в кошик" !important;
                }
            }

            .btn-product {
                background: var(--green);
            }
        }
    }

    &__buy-click {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-right: 0 !important;

        // width: 100%;
        // height: 100%;

        font-size: 18px;
        color: var(--orange) !important;
        border: 2px solid var(--orange);
        cursor: pointer;
        background: unset;
        background-color: transparent !important;

        &:hover,
        &:focus {
            color: #fff !important;
            border: none !important;
        }
    }

    &__buy-click-form {
        .btn-details {
            width: 100%;
        }
    }

    &__buy-click-wrapper {
        // display: none;
        max-width: 230px;
        margin: 0 auto;

        .btn-details {
            width: 100% !important;
        }

        @include rmin($desktop) {
            max-width: 300px;
        }
    }

    // .btn-buy-one-click--active &__buy-click-wrapper {
    //     display: block;
    // }

    &__buy-click-wrapper {
        p {
            margin-top: unset;
            margin-bottom: 10px;
            padding: unset;

            color: #000000;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }
    }

    &__color-wrapper {
        order: -1;
        // margin-top: 40px;

        text-align: left;
    }

    &__color-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }

    &__color-item {
        // --size: 105px;
        width: var(--size);
        margin-bottom: 5px;
        border-radius: 10px;
    }

    &__color-btn {
        position: relative;

        display: block;
        width: 100%;
        height: var(--size);
        border-radius: 10px;
        border: 2.25px solid var(--gray-300);
        overflow: hidden;
        transition: border 0.1s ease-in-out;

        // background-color: #e7e7e7;
        // border-radius: 50%;

        &:hover {
            border: 2.25px solid var(--orange);
        }
        &--active {
            border: 2.25px solid var(--orange);
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;

            width: 18px;
            height: 18px;

            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0a9 9 0 100 18A9 9 0 009 0zM7.349 13.623L3.386 9.66l1.321-1.32 2.642 2.641 5.944-5.944 1.32 1.321-7.264 7.265z' fill='%23FA5A0C'/%3E%3C/svg%3E");
            opacity: 0;
            transition: opacity 0.15s;
            will-change: opacity;
        }
    }

    &__color-btn--selected::after,
    &__color-btn.selected::after {
        opacity: 1;
        will-change: unset;
    }

    &__comment-wrapper {
        p {
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 15px;

            @include rmin($desktop) {
                margin-top: unset;

                font-size: 18px;
                font-weight: 500 !important;
            }
        }
    }

    &__btn-title {
        font-size: 20px;
    }

    &__btn-expand {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: 10px;
        padding-bottom: 7px;
        width: 100%;
        border-bottom: $border;

        text-align: left;
    }

    &__info-expand {
        max-height: 0;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        overflow: hidden;
        visibility: hidden;
        transition: max-height 0.15s, opacity 0.15s;

        p {
            font-weight: 300;
        }
    }

    &__ico-arrow {
        width: 12px;
        height: 12px;
        transition: transform 0.25s;
    }

    &__btn-expand--opened + &__info-expand {
        max-height: 1500px;
        visibility: visible;
    }

    &__btn-expand--opened &__ico-arrow {
        transform: rotate(180deg);
    }

    &__btn-expand--opened &__btn-title {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;

            height: 3px;
            width: 200px;
            background-color: var(--orange);
        }
    }

    // delivery info block
    &__delivery-info-wrapper {
        font-weight: 500;
    }

    &__delivery-part {
        padding: 10px;
        // margin: 0 10px;

        &:not(:last-child) {
            border-bottom: 1px solid #000;
        }
    }

    &__delivery-info-wrapper p {
        padding-bottom: 5px;
    }

    &__charact-list {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
    }

    &__charact-item {
        width: 50%;
        margin-top: 5px;
        font-weight: 300;

        span {
            font-weight: 500;
            margin-right: 5px;
        }
    }

    &__options-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        padding-top: 40px;
    }

    &__options {
        // min-width: 300px;
        // max-width: 300px;

        @include rmin($desktop) {
            min-width: 300px;
        }
    }
    &__size {
        margin-top: 15px;
        .product__additionally-wrapper {
            margin-top: 15px;
            margin-bottom: 25px;
        }
    }

    &__sizes-indicator {
        // display: none;
        margin-top: 10px;
        margin-bottom: 10px;

        font-size: 14px;

        span {
            text-transform: uppercase;
        }
    }

    // POPUP: availability
    &__city-header {
        p {
            padding-bottom: 15px;

            font-size: 20px;
            font-weight: 500;
            text-align: center;
        }
    }

    &__city-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        padding: 10px 0;

        border-top: $border-light-orange;
        border-bottom: $border-light-orange;
        border-width: 3px;
    }

    &__city {
        margin: 5px 10px;
        color: var(--gray);

        &:hover,
        &:focus {
            cursor: pointer;
            color: #000000;
        }
    }

    .tabs__content {
        margin-top: 15px;
    }

    .tabs__item--current {
        color: #000000 !important;
        text-decoration: underline !important;
    }

    &__city-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        & > * {
            margin: 2px 0;
        }

        address {
            font-style: normal;
        }

        address,
        p {
            color: var(--dark-gray);
        }

        a {
            display: flex;
            align-items: center;

            color: #000000;
            font-weight: 500;
            text-decoration: none;

            svg {
                margin-left: 3px;
                fill: var(--orange);
            }

            &:hover,
            &:focus {
                color: var(--orange);
            }
        }
    }

    &__images-slider {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        max-height: 100vh;
        display: none;
        z-index: 500;

        .glide,
        .glide__track,
        .glide__slides,
        .product__img-container,
        .product-img__link-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        .glide {
            max-width: 100vw;
            max-height: 100vh;

            background-color: #fff;
        }

        .product__img-container,
        .product-img__link-wrapper {
            display: block;

            text-align: center;
        }

        .product-img__link-wrapper {
            overflow: hidden;
            text-align: center;
            transition: transform 0.25s;

            display: block;
            width: 100%;
            height: 100%;
            cursor: zoom-in;
        }

        .product__slider-close {
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 2;

            width: 12px;
            height: 12px;
            padding: 10px;
            box-sizing: content-box;

            font-size: 0;
            border-radius: 50%;

            &:hover,
            &:focus {
                background-color: #f5f5f5;
                border-color: #eee;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .glide__track,
        .glide__slides {
        }

        .glide__bullet {
            background-color: var(--light-gray);

            img {
                display: none;
            }
        }

        .glide__bullet--active {
            background-color: #000;
        }
    }

    &-img__link-wrapper--zoomed {
        width: 800px;
        max-width: none;
        // width: 2000px;
        // background-size: 400%;
    }

    &__zoom-arrows {
        display: none;
    }

    &__images-slider--opened {
        display: block;
    }

    &-bin__wrapper {
        // min-width: 220px;
        padding: 22px 12px !important;
        color: var(--gray-500);
        border: 1px solid var(--gray-400);

        &--active {
            border: 1px solid var(--orange) !important;
            background-color: var(--white-200) !important;

            & .wa-payment-name {
                color: var(--orange) !important;
            }
        }
    }

    &-bin__img-wrap {
        &--first {
            width: 34px;
            height: 25px;
            margin: 0 auto 10px;
        }

        &--second {
            width: 124px;
            height: 25px;
            margin: 0 auto 10px;
        }

        &--third {
            width: 148px;
            height: 25px;
            margin: 0 auto 10px;
        }
    }
}

@media (min-aspect-ratio: 3/4) {
    .product {
        &__images-slider &-img__link-wrapper {
            img {
                width: auto;
                height: 100%;
            }
        }
    }
}

@include rmin($desktop) {
    .product {
        margin-top: 60px;

        &__info-wrapper {
            flex-direction: column;
            margin-left: 0;
            max-width: 525px;
        }

        &__details-wrapper {
            display: flex;
            justify-content: center;
            gap: 20px;

            @include rmin($desktop-md) {
                gap: 50px;
            }
        }

        &__options-wrapper {
            display: flex;
            padding-top: unset;
        }

        &__img-wrapper {
            position: relative;

            display: flex;
            flex-basis: 30%;
            max-width: 500px;

            width: 100%;

            @include rmin($desktop-md) {
                max-width: 539px;
            }
        }

        &__images-slider,
        &__img-wrapper {
            .glide__bullet {
                margin: 0 0 1em;
                width: 85px;
                height: 85px;

                background-color: #fff;

                border-radius: unset;
                border: unset;
                box-shadow: unset;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    display: block;
                }

                &:hover {
                    border: unset;
                }
            }

            .glide__bullet--active {
                background-color: #fff !important;
            }

            .glide__bullets {
                top: 0;

                bottom: unset;
                left: -85px;

                display: flex;
                flex-direction: column;
                max-height: 100%;
                overflow-y: auto;

                transform: unset;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .glide__bullet--active {
                background-color: #000000;
            }
            .cards__favorite-icon {
                width: 44px;
                height: 44px;
            }
        }

        &__images-slider {
            .glide__bullets {
                position: absolute;
                top: 0;
                right: none;
                left: 0;
                z-index: 2;

                max-height: 95vh;
                overflow: auto;
                overflow-x: hidden;
                padding: 24px;
            }

            .glide__bullet {
                width: 70px;
                height: 70px;

                margin-left: 0;
                margin-right: 0;
            }
        }

        &__zoom-arrows {
            display: block;
        }

        &__img-container {
            display: flex;
            width: 539px;
            height: 550px;

            // img {
            //     object-fit: cover;
            // }
        }

        &__info-wrapper {
            display: flex;
            flex: 1;

            margin-top: unset;
            margin-left: 50px;

            h1 {
                font-size: 35px;
            }
        }

        &__text-wrapper {
            display: flex;
            margin-top: 60px;
            padding-top: 60px;
            padding-bottom: 20px;

            border-top: $border;
        }

        &__img-big {
            height: 100%;
            width: 325px;

            .cards__favorite-btn {
                top: 5px;
                right: 20px;
            }
        }

        &__img-list {
            display: flex;
        }

        &__price-wrapper {
            justify-content: unset;
        }

        &__one-additionaly-wrapper {
            position: static;
            margin-top: 0;
            width: 50%;

            .popup__wrapper {
                left: 50%;
                max-width: 65%;
                transform: translateX(-50%);
            }
        }

        &__buy-wrapper {
            flex-direction: row;
            gap: 15px;
            height: 55px;
            .btn-product {
                margin-bottom: 0;
                min-height: 55px;
            }
        }

        &__additionally-link {
            font-size: 14px;

            &:first-child {
                margin-right: 0;
                margin-left: 0px;
            }

            &:nth-child(2) {
                display: flex;
                order: -1;
                margin-right: 10px;
            }
        }

        &__buy-click {
            font-size: 18px;
        }

        &__price-wrapper {
            position: relative;
            top: 0;

            span {
                font-size: 40px;
            }

            & span:first-child {
                order: -1;
                margin-left: unset;
            }
        }

        &__charact-list {
            font-size: 16px;
        }

        &__status-wrapper {
            margin-top: 0;
            margin-bottom: 20px;
            padding-bottom: 10px;
            font-size: 16px;
        }

        &__article {
            margin-top: 0;
        }

        &__availability {
            margin-left: 7px;
        }

        &__color-wrapper {
            order: 1;
            margin-top: unset;
        }

        &__shop-cart {
            width: 50% !important;

            &--added {
                width: 300px !important;
                // max-width: unset;
            }
        }

        &__color-item {
            // width: 30%;
        }

        // delivery info block
        &__delivery-info-wrapper {
            display: flex;
        }

        &__delivery-part {
            --side-padding: 50px;
            max-width: 50%;
            padding: 0;
            padding-left: var(--side-padding);

            &:not(:last-child) {
                border-bottom: 0;
                border-right: 1px solid #000;
                padding-left: 0;
                padding-right: var(--side-padding);
            }
        }

        &__delivery-info-wrapper p {
            padding-bottom: 5px;
        }

        &__charact {
            flex-basis: 30%;
            max-width: 30%;
            margin-right: 80px;

            border-right: $border;

            .product__btn-expand {
                display: none;
            }

            .product__info-expand {
                visibility: unset;
                max-height: unset;
                width: unset;
            }

            .product__charact-list {
                display: block;
                flex-wrap: unset;
            }

            .product__charact-item {
                width: unset;
            }
        }

        &__text-wrapper {
            .collapse__wrapper {
                max-width: 800px;
                flex: 1;
                flex-direction: column;

                .tabs__item {
                    position: relative;

                    padding: unset;

                    &:not(:first-child) {
                    }

                    &:not(:last-child) {
                        margin-right: 50px;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 5px;
                            bottom: 5px;
                            right: -25px;

                            width: 2px;

                            background-color: #000;
                        }
                    }

                    button {
                        padding-left: 0;
                        padding-right: 0;

                        font-size: 20px;
                    }
                }

                .tabs__content--current {
                    display: block !important;
                }

                .tabs__content {
                    display: none;
                    margin-top: 20px;
                }

                .tabs__list {
                    display: flex;
                    justify-content: unset;
                }

                .tabs__item--current button {
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom-width: 1px;
                }

                .product__btn-expand {
                    display: none;
                }

                .product__info-expand {
                    visibility: unset;
                    max-height: unset;
                }
            }
        }

        &__options-wrapper {
            flex-direction: column;
            justify-content: space-between;
        }

        &__price-position {
            margin-top: -20px;
        }

        &__city-content {
            flex-direction: unset;
            justify-content: space-between;
        }

        &__city-content-list {
            margin: 0 25px;
        }

        &__city-content:not(:last-child) {
            margin-bottom: 15px;
        }

        &__images-slider--opened {
        }

        &-img__link-wrapper--zoomed {
            width: 800px;
            max-width: none;
        }

        &-bin__wrapper {
            min-width: 220px;
            padding: 22px 12px !important;
            border-right: unset;

            &:last-child {
                border-right: 1px solid var(--gray-400);
            }
        }
    }
}

@include rmin($desktop-s) {
    .product {
        &__additionally-link {
            font-size: 16px;
        }
    }
}
