.footer {
    position: relative;
    padding: 80px 15px 20px;

    @include rmin($sm-phone) {
        padding-left: 20px;
        padding-right: 20px;
    }

    background-color: #000;

    &__wrapper {
        // padding: 0 10px;
    }

    &__company-logo {
        margin-top: -5px;
        display: block;
        background-image: url(../img/logo.png);
        background-repeat: no-repeat;

        width: 120px;
        height: 25px;
        background-size: 120px 25px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 50%;
        flex-basis: 50%;
        color: var(--gray);

        font-size: 14px;
        margin-bottom: 15px;

        &:nth-child(1) {
            order: 0;
        }
        &:nth-child(2) {
            order: 1;
        }
        &:nth-child(3) {
            order: 2;
            margin-bottom: unset;
        }
        &:nth-child(4) {
            order: 3;
            margin-bottom: unset;
        }
    }

    &__list-item:nth-child(4) &__list-item-wrapper {
        align-items: unset;
        width: 240px;
    }

    &__schedule {
        p {
            color: #fff;
            font-weight: 500;
            font-size: 16px;
        }
    }

    &__ico {
        fill: #fff;
        width: 15px;
        height: 15px;

        &--m {
            width: 30px;
            height: 30px;
        }
    }

    &__title {
        color: #fff;
        font-weight: 500;
        font-size: 18px;

        margin-bottom: 5px;
    }

    &__link {
        text-decoration: none;
        color: currentColor;
        font-weight: 300;
    }
    &__social-list {
        display: flex;
    }

    &__social-item {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    &__title {
        font-size: 16px;
    }

    &__phone-number {
        display: block;
        margin: 10px 0 5px;

        text-decoration: none;
        color: var(--light-orange);
        font-size: 17px;
        font-weight: 500;

        @include rmin($sm-phone) {
            font-size: 18px;
        }
    }

    &__copyright {
        margin-top: 2px !important;
        margin-bottom: unset !important;
        font-size: 16px;
    }

    &__social-msg-container {
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        gap: 5px;
        margin-top: 10px;
    }

    &__social-msg-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 50%;
        background-color: var(--orange-2);

        &:hover {
            opacity: 0.75;
        }

        &--tg {
            background-color: #63b6f0;
        }

        &--viber {
            background-color: #7360f2;
        }
    }

    &__social-msg {
        svg {
            width: 17px;
            height: 17px;
            fill: var(--white);
        }
    }

    &__tg-bot {
        display: inline-flex;
        align-items: center;

        padding: 8px 20px;
        border-radius: 7px;

        color: #63b6f0;
        background-color: #001929;

        &:hover {
            background-color: #002e4a;
        }

        svg {
            width: 22px;
            height: 22px;
            margin: -1px;
            fill: currentColor;
        }

        span {
            margin-top: 1px;
            margin-left: 5px;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__tb-bot-ico-wrap {
        display: flex;
        background-color: var(--white);
        border-radius: 50%;
    }

    &__lang-wrap {
        margin-top: 10px;

        @include rmin($desktop) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            margin-top: unset;
        }
    }
    &__lang {
        display: inline-flex;
        margin-top: 5px;
        padding: 5px 10px 3px 10px;

        background-color: #262626;
        border-radius: 7px;

        @include rmin($desktop) {
            display: flex;
            margin-top: unset;
            padding: 3px 7px 1px 7px;
        }

        a {
            color: #656565;

            &:hover {
                color: var(--gray);
            }

            &:not(:first-child) {
                margin-left: 5px;

                &::before {
                    content: "|";
                    padding-right: 5px;
                    color: #656565;
                }
            }

            &.active {
                color: var(--orange-2);
                pointer-events: none;
            }
        }
    }
}

@include rmin($tablet) {
    .footer {
        &__wrapper {
            padding: 0 10px;
        }
        &__company-logo {
            margin: 0 auto;
        }
        &__phone-number,
        &__copyright {
            font-size: 18px;
        }

        &__schedule {
            p {
                font-size: 14px;
            }
        }

        &__list-item {
            display: flex;
            justify-content: space-around;
            margin-bottom: unset;
            width: 25%;
            flex-basis: 25%;

            &:first-child {
                align-items: center;
                text-align: center;
                flex-direction: column;
            }
            &:nth-child(2) {
                width: 25%;
                flex-basis: 25%;
            }
            &:nth-child(3) {
                width: 20%;
                flex-basis: 20%;
            }
            &:last-child {
                width: 30%;
                flex-basis: 30%;
            }
            &:not(:first-child) {
                border-left: 1px solid var(--dark-gray);
            }
        }
    }
}

@include rmin($desktop) {
    .footer {
        position: unset;
        // display: flex;
        padding: 30px 0;

        &__phone-number {
            font-size: 24px;
            margin: unset;
            margin-bottom: 2px;
        }

        &__company-logo {
            display: block;
            margin-top: unset;
            background-image: url(../img/logo-2.png);
            background-repeat: no-repeat;

            width: 160px;
            height: 100px;
            background-size: 160px 100px;
        }

        &__wrapper {
            display: flex;
            align-items: center;

            flex-basis: 1250px;
            max-width: 1250px;
            margin: 0 auto;

            a {
                display: flex;
            }
        }

        &__list {
            display: flex;
            width: 100%;
        }

        &__list-item-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__list-item {
            flex-basis: unset;
            font-size: 16px;

            &:first-child {
                align-items: center;
                text-align: center;
                flex-direction: unset;
                width: 34%;
                flex-basis: 34%;
                padding-right: 30px;
            }
            &:not(:first-child) {
                width: 22%;
                flex-basis: unset;
                border-left: 1px solid var(--dark-gray);
            }
        }

        &__tg-bot {
            padding: 5px 8px 5px 5px;
        }

        &__social-msg-container {
            position: unset;
            top: unset;
            left: unset;
            transform: unset;

            margin-bottom: 5px;
            margin-top: unset;
        }
        &__social-msg-wrap {
        }

        &__social-msg {
        }

        &__schedule {
            p {
                font-size: 16px;
            }
        }

        &__social-item {
            margin-right: 10px;
        }

        &__ico {
            width: 25px;
            height: 25px;

            &--m {
                width: 45px;
                height: 45px;
            }
        }

        &__link {
            &:hover,
            &:focus {
                color: var(--orange);
            }
        }

        &__title {
            font-size: 20px;
            margin-bottom: 5px;
        }

        &__copyright {
            font-size: 16px;
        }
    }
}
