// animation: slide-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

@keyframes slide-top {
	0% {
		transform: translateY(100px);
		pointer-events: none;
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		pointer-events: auto;
		opacity: 1;
	}
}


@keyframes slide-in-bottom {
	0% {
			transform: translateY(50px);
			opacity: 0;
	}

	100% {
			transform: translateY(0);
			opacity: 1;
	}
}