#megazoom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;

    background: #fff;
    cursor: pointer;
}

#megazoom img {
    // position: absolute;
    // left: 0;
    opacity: 0;
    // top: 50%;

    // transform: translateY(-75%);
    transition: opacity 0.6s 0.2s;
}

.megazoom__image-wrapper {
    height: 100%;

    text-align: center;
}

#megazoom img.loaded {
    opacity: 1;
}

@media (min-aspect-ratio: 3/4) {
    #megazoom {
        .megazoom__image-wrapper {
        }

        img {
            width: auto;
            height: 100%;
        }
    }
}
