.promo-n {
    &__show--categories {
        @include rmin($desktop) {            
            .main__product-cards--categories {
                margin-top: 125px;
            }
        }
    }

    &__show {
        .form__wrapper {
            top: calc(50px + 35px);

            @include rmin($desktop) {
                top: calc(76px + 50px);
            }
        }

        &:not(.menu-opened) .site-header__container--scroll-up > .site-header {
            transform: translateY(-147px) !important;
        
            @include rmin($desktop) {
                transform: translateY(-259px) !important;
            }
        }
        
    }
    &__show {
        @include r($desktop) {
            .nav-menu {
                padding-top: 35px;
            }
        }

        .site-header__container {
            min-height: 85px;
        }

        @include rmin($desktop) {
            .site-header__container {
                min-height: 195px;
            }
        }
    }

    &__wrap {
        position: relative;

        background-color: var(--orange);
        min-height: 60px;
        height: 60px;

        background-image: url("../../../img/pattern-1.svg");
        background-repeat: repeat-x;
        background-size: contain;
        background-position-x: -50%;

        overflow: hidden;

        &--header {
            z-index: 7;
            min-height: 35px !important;
            height: 35px !important;
            background-size: 100px !important;
        }

        &--product-d,
        &--categories {
            margin: 15px -15px;
        }

        @include rmin($desktop) {
            &--header {
                min-height: 50px !important;
                height: 50px !important;
                background-size: contain !important;
            }

            &--product-d,
            &--categories {
                margin: 15px 0;
            }

            &--categories {
                position: absolute;
                left: 0;
                right: 0;
            }

            min-height: 85px;
            height: 85px;
        }
    }

    &__text-wrap,
    &__text {
        display: flex;
        align-items: center;
        user-select: none;
    }

    &__text-wrap {
        height: 100%;

        color: var(--white);

        strong {
            // margin-bottom: -0.15%;
            font-size: 24px;
            line-height: 24px;
            text-transform: uppercase;
        }
    }

    &__text {
        white-space: nowrap;

        & > * {
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    &__ico {
        margin-top: -5px;
        width: 15px;
        height: 15px;
        fill: currentColor;
    }

    // animation
    &__wrap--header &__text-wrap {
        animation-duration: 5s;

        @include rmin($desktop) {
            animation-duration: 15s;
        }
    }

    &__wrap--categories &__text-wrap {
        @include rmin($desktop) {
            animation-duration: 10s;
        }
    }

    &__text-wrap {
        animation: slide-left 3.5s linear infinite;
    }

    @keyframes slide-left {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}
