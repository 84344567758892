.brand-details {
  margin-top: 30px;
  padding: 0 30px;

  // **************** //
  // header
  &__header {}
  
  &__img-wrapper {
    max-width: 400px;
    margin: 0 auto 30px;
  }
  
  &__img {}
  
  &__info-wrapper {}
  
  &__brand-name {
    padding: 0;
    margin-bottom: 10px;

    font-size: 30px;

    text-align: center;

    span {
      padding: 0 10px;
      border-bottom: 3px solid var(--orange-2);
    }
  }
  
  &__description-wrapper {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;

    font-size: 14px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }

  &__description-wrapper--opened {
    display: block;
  }

  i {
    display: block;
    margin: 0;
    margin-bottom: 10px;

    border: none;
  }
  
  &__sm-btn {
    display: block;
    width: 14px;
    height: 14px;

    margin: 0 auto;
  }

  // **************** //
  // categories
  &__categories-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  &__tabs-list {
    order: 1;
    display: block;

    text-align: center;
    border: 1px solid #C7CAC7;
    border-top: unset;
  }
  &__tabs-item {
    padding: 0;

    button {
      padding: 15px;
      font-size: 30px;

      border: unset;
    }
  }
}
