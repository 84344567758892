.advantages {
    margin-bottom: 20px;
    position: relative;
    padding: 0 10px;

    &__list {
        display: flex;
        flex-wrap: wrap;

        border-radius: 15px;
        box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
        background-color: #fff;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;

        padding: 15px;

        &:nth-child(1) {
            border-bottom: $border;
            border-right: $border;
        }

        &:nth-child(2) {
            border-bottom: $border;
        }

        &:nth-child(3) {
            border-right: $border;
        }

        p {
            font-weight: 300;
            letter-spacing: 0.05em;
            text-align: center;

            font-size: 16px;

            margin-top: 10px;
        }

        span {
            color: var(--orange);
            font-weight: 300;
            font-size: 36px;
            line-height: 43px;
            text-align: center;
            letter-spacing: 0.05em;
        }
    }
}

@include rmin($tablet) {
    .advantages {
        width: 100%;
        // margin-top: -50px;
        margin-top: 20px;
        // bottom: 40px;

        &__list {
            flex-wrap: unset;
        }

        &__item {
            padding: 25px;

            p {
                width: 115px;
            }

            span {
                line-height: 35px;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                border: unset;
            }

            &:nth-child(3) {
                h3 {
                    width: 75px;
                }
            }

            &:not(:last-child) {
                border-right: $border;
            }
        }
    }
}

@include rmin($desktop) {
    .advantages {
        margin-top: -40px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        max-width: $max-width;

        &__list {
            margin: 0 auto;

            border-radius: 10px;
        }

        &__item {
            width: 25%;
        }
    }
}

@include rmin($desktop-md) {
    .advantages {
    }
}

@include rmin($desktop-lg) {
    .advantages {
    }
}
