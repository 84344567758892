.table {
    &__main-title {
        max-width: 150px;
        margin: 0 auto;
        margin-bottom: 15px;
        padding: 6px 0;

        text-align: center;
        font-size: 14px;
        border-bottom: 3px solid #000;

        span {
            font-weight: 500;
        }
    }

    &__container {
    }
    &__table-wrapper {
        padding: 0 15px;
        margin-bottom: 30px;

        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    /* --------------------------------- */
    // START table styles
    &__th,
    &__td {
        padding: 10px;
    }

    &__table {
        min-width: 310px;
        width: 99.99%;
    }

    &__thead {
    }
    &__tr {
        font-size: 12px;
        font-weight: 700;
        text-align: center;
    }
    &__th {
    }

    &__tbody {
        border: 0.2px solid #000;
    }
    &__tbody--filled-rows &__tr:nth-child(odd) {
        background-color: var(--grayish);
    }
    &__td {
        border-right: 0.2px solid #000;
        border-left: 0.2px solid #000;
    }
    // END table styles
    /* --------------------------------- */

    &__th--mb-only {
    }
    &__th--desk-only {
        display: none;
    }

    &__notice {
        display: flex;
        align-items: center;
        padding: 5px 15px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        font-size: 10px;

        &--with-bgc {
            background-color: var(--grayish);
        }
    }
    &__notice-icon {
        min-width: 2em;
        min-height: 2em;
        max-width: 2em;
        max-height: 2em;
        margin-right: 1em;
    }
    &__notice-text {
    }

    &-added__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 280px;
        height: 25px;
        padding: 0 10px;

        font-weight: bold;
        font-size: 12px;
        color: var(--gray-500) !important;
        background-color: var(--white-200);
        border-radius: 5px;

        & svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }

        input {
            color: var(--black);
            background-color: transparent;
        }

        &:hover input {
            color: var(--orange) !important;
        }
    }
    &__text-added {
        position: absolute;
        left: 0;
        // width: 100%;
        width: 150px;
        margin-top: 5px;

        padding-top: 0 !important;
        padding-right: unset !important;
        border-bottom: unset !important;
    }
}

@include rmin($desktop) {
    .table {
        &__main-title {
            max-width: none;
            margin: 0 30px;
            padding-top: 30px;

            text-align: left;
            font-size: 16px;

            border-bottom: 1px solid var(--grayish);

            span {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    right: -20px;

                    height: 3px;
                    background-color: #000;
                }
            }
        }

        &__container {
        }
        &__table-wrapper {
            padding: 0 30px;
            margin-bottom: 10px;
        }

        /* --------------------------------- */
        // START table styles
        &__th,
        &__td {
        }

        &__table {
        }

        &__thead {
        }
        &__tr {
            font-size: 13px;
        }
        &__th {
            margin: 0 15px;
        }

        &__tbody {
            border: none;
        }
        &__tbody--filled-rows &__tr:nth-child(odd) {
        }
        &__td {
            border: none;
        }
        // END table styles
        /* --------------------------------- */

        &__th--mb-only {
            display: none;
        }
        &__th--desk-only {
            display: block;
            max-width: 75px;
            margin: 0 auto;
        }

        &__notice {
            display: flex;
            justify-content: center;
            padding: 15px 30px;
            font-size: 14px;

            &:not(:last-child) {
                margin-bottom: 14px;
            }

            &--with-bgc {
            }
        }
        &__notice-icon {
        }
        &__notice-text {
        }

        &__text {
            vertical-align: top;
            word-wrap: break-word;
        }
    }
    .tariff__table {
        .order-status {
            display: inline-block !important;
            white-space: unset !important;
        }
    }
}

.table {
    &__text {
        font-weight: 300;
        font-size: 14px;

        &:nth-child(5) {
            padding: 10px;
            text-align: center;
        }
    }

    &__text:last-child {
        padding-right: 10px;
        text-align: center;
    }

    &-head {
        width: 100%;
    }

    &-head__text {
        position: relative;
        display: flex;

        @include rmin($desktop) {
            position: unset !important;
            display: table-row;
        }
    }

    &-head__title,
    &__text {
        text-align: left;
        padding: 10px 5px;
    }
    &-head__title {
        font-size: 14px;
        color: var(--black);
    }

    &-head__title:first-of-type {
        padding-left: 160px;
        width: 250px;

        @include rmin($desktop) {
            padding-left: 5px;
        }
    }

    &-head__title:nth-of-type(2) {
        width: 100px;
    }

    &-head__title:nth-of-type(3) {
        width: 250px;
    }

    &-head__title:nth-of-type(4) {
        width: 185px;
    }

    &-head__title:nth-of-type(5),
    &-head__title:nth-of-type(6) {
        width: 150px;
        text-align: center;
    }

    &__text {
        &:nth-of-type(1) {
            padding-left: 160px;

            @include rmin($desktop) {
                padding-left: 5px;
            }
        }

        &:nth-of-type(1),
        &:nth-of-type(3) {
            min-width: 250px;
        }
        &:nth-of-type(2) {
            min-width: 100px;
        }
        &:nth-of-type(4) {
            min-width: 185px;
        }
        &:nth-of-type(5),
        &:nth-of-type(6) {
            min-width: 150px;
        }

        &:nth-of-type(6) {
            text-align: center;
        }
    }
}

.tariff {
    &__table {
        width: 100%;
        table-layout: fixed;
        border: 1px solid rgba(218, 218, 218, 0.5);

        tbody {
            border: 1px solid rgba(218, 218, 218, 0.5);
        }
        & th {
            border-bottom: 1px solid rgba(218, 218, 218, 0.5);
        }
        // & tr:last-child td {
        //     padding-bottom: 45px !important;
        //     padding-top: 40px !important;
        //     margin-top: 30px !important;
        // }
    }

    &__table-wrap {
        position: relative;
        width: 100%;
        margin-bottom: 3.5rem;
        overflow-x: scroll;
    }
}

@include rmin($desktop) {
    .tariff__table-wrap {
    }

    .tariff__table-wrap::-webkit-scrollbar {
        display: none;
    }

    .tariff__table {
        // & tr:last-child td {
        //     padding-bottom: 25px !important;
        //     padding-top: 10px !important;
        // }
    }

    .table {
        &__text {
            vertical-align: top;
            font-size: 15px;
        }
        &-head__title {
            word-break: break-word;
        }
        &-head__title:first-of-type {
            // width: 80px;
            width: 12%;
        }
        &-head__title:nth-of-type(2) {
            // width: 125px;
            width: 17%;
        }
        &-head__title:nth-of-type(3) {
            // width: 200px;
            width: 25%;
        }
        &-head__title:nth-of-type(4) {
            // width: 100px !important;
            width: 15% !important;
        }
        &-head__title:nth-of-type(5) {
            // width: 120px;
            width: 14%;
        }
        &-head__title:nth-of-type(6) {
            // width: 130px;
            width: 17%;
        }
        &__text-added {
            position: unset;
            width: unset;
        }
        &-added__btn {
            width: max-content;
        }
    }
}
