.tabs-filter {
    max-width: 950px;
    margin: 0 auto;

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        margin-bottom: 15px;
    }

    &__btn {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        border: none;
        text-align: left;
        background: var(--grayish);
        color: var(--black);
        padding: 10px 15px;
        text-decoration: none;
        // transition: all 0.2s ease-in;
        border-radius: 9px;

        svg {
            width: 22px;
            fill: currentColor;
        }

        &.active,
        &:hover {
            background: var(--black-200);
            color: var(--white);
        }

        label {
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 5px;
        }
        input {
            display: none;
        }
    }

    &__link {
        color: var(--orange) !important;
        // transition: all 0.3s ease-in-out;

        &:hover {
            text-decoration: underline;
        }
    }

    details {
        padding: 15px 0;
        border-bottom: 0.5px solid var(--gray-400);
        list-style: none;

        p {
            margin: 5px 0 0 0 !important;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.65px;

            @include rmin($desktop) {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 1.4px;
            }
        }
    }

    details > summary {
        position: relative;
        padding-right: 25px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        list-style: none;

        @include rmin($desktop) {
            font-size: 18px;
        }
    }
    details > summary::-webkit-details-marker {
        display: none;
    }

    summary:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(50%, -50%) rotate(135deg);
        right: 10px;
        width: 10px;
        height: 10px;
        border-right: 2px solid var(--gray-400);
        border-top: 2px solid var(--gray-400);
        transition: transform 0.3s ease;
    }

    details[open] summary:before {
        transform: translate(50%, -50%) rotate(-45deg);
        border-right: 3px solid var(--orange);
        border-top: 3px solid var(--orange);
    }
}

@include rmin($tablet) {
    .tabs-filter {
        &__list {
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;
        }
    }
}

@include rmin($desktop) {
    .tabs-filter {
        &__btn {
            gap: 15px;
            font-size: 18px;
            padding: 25px;

            svg {
                width: unset;
            }
        }

        details {
            padding: 25px 0;

            p {
                font-size: 18px;
            }
        }

        details > summary {
            font-size: 20px;
        }
    }
}
