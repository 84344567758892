.svg-icon {
    display: inline-block;
    vertical-align: baseline;
    margin: 0 0 0 3px;
    width: 16px;
    height: 16px;
    text-indent: -9999px;
    text-decoration: none !important;
    background: transparent 50% no-repeat;
    background-size: contain;
    overflow: hidden;
}

.reviews__list .svg-icon.size-11,
.reviews__list .svg-icon.size-12 {
    height: 20px;
    width: 20px;
}

.reviews__list .svg-icon {
    display: inline-block;
    vertical-align: baseline;
    margin: 0 0 0 3px;
    width: 16px;
    height: 16px;
    text-indent: -9999px;
    text-decoration: none !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    overflow: hidden;
}
.svg-icon.star,
.svg-icon.star-full,
.svg-icon.star-hover {
    background-image: url("../img/svg/icon.star.red.svg");
}
.svg-icon.size-11,
.svg-icon.size-12 {
    height: 20px;
    width: 20px;
}
.svg-icon:first-child {
    margin-left: 0;
}

.svg-icon {
    display: inline-block;
    vertical-align: baseline;
    margin: 0 0 0 3px;
    width: 16px;
    height: 16px;
    text-indent: -9999px;
    text-decoration: none !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    overflow: hidden;
}
.svg-icon.star,
.svg-icon.star-full,
.svg-icon.star-hover {
    background-image: url("../img/svg/icon.star.red.svg");
}
.svg-icon.star-half {
    background-image: url("../img/svg/icon.star.half_red.svg");
}
.svg-icon.star-yellow {
    background-image: url("../img/svg/icon.star.yellow.svg");
}
.svg-icon.star-empty {
    background-image: url("../img/svg/icon.star.gray.svg");
}

// Кастомный чекбокс

.container {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    border: 1px solid var(--gray-400);
}

// /* On mouse-over, add a grey background color */
// .container:hover input ~ .checkmark {
//     background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: var(--orange);
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, 50%) rotate(45deg);
}
