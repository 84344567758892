.order-status {
  padding: 2px 5px;
  color: #fff;
}

h1 .order-status {
  font-size: 0.75em;
  margin-left: 10px;
}

.static-page__wrapper {
  h1 ~ p,
  p ~ h3 {
    padding: 0 10px;
    max-width: 500px;
    margin: 20px 0;
    padding: 0;
  }
  p ~ h3 {
    font-size: 1.3em;
  }
  img {
    width: auto;
  }
}

.table td.align-right {
  text-align: right;
}

p.gray ~ .table {
  margin-left: 0;
}

@include rmin($desktop) {
  .static-page__wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    flex-grow: 1;
    
    h1 ~ p,
    p ~ h3 {
      max-width: none;
    }
  }
}