.collapse {
  &__wrapper .tabs__content--current{
    margin-bottom: unset !important;
  }
}

@include rmin($desktop) {
  .collapse {
    &__wrapper {
      display: flex;
    }
  }
}