.dropdown-n {
    display: none;

    &__drop-list {
        max-height: none;
        overflow-y: auto;
        &__input {
            margin: 10px 5px;
            margin-bottom: 5px;
            padding: 0px 15px;

            width: 96%;
            overflow: visible;
            height: 35px;
            border-radius: 10px;
            background: var(--gray-200, #f8f8f8);
        }
        &__btn {
            display: flex;
            justify-content: center;
            gap: 5px;
            margin: 5px;
        }
        .btn-details {
            border-radius: 10px !important;
            padding: 0;
            margin: 0;
            width: 100%;
            height: 35px !important;
        }

        .product-filter__options-list {
            max-height: 100%;
            .product-filter__option {
                display: flex;
                align-items: center;
                border-bottom: 0.5px solid var(--gray-300);

                @include r($desktop) {
                    min-height: 49px;
                }
            }
            .product-filter__option-text {
                font-size: 14px;
                color: var(--gray-700);
            }
            .product-filter__option-input
                + .product-filter__option-text::before {
                margin-right: 7px;

                border-radius: 4.5px;
                border: 1.105px solid
                    var(--black-gray-variations-gray-2, #cdcdcd);
            }
            .product-filter__option-input
                + .product-filter__option-text::after {
                content: "";

                transform: translateY(-50%) scale(1.2);
                background-color: var(--orange-2);
                border-radius: 3px;
            }
            .product-filter__option-input:checked
                + .product-filter__option-text::before {
                border: 2px solid var(--orange-2);
            }
        }
        &--mobile {
            .product-filter__options-list {
                .product-filter__option-text {
                    font-size: 18px;
                }
            }
            .dropdown-n__drop-list__input {
                margin-bottom: 10px;
                padding: 10px 15px;
                font-size: 16px;
                height: 40px;
            }
        }
    }

    &__menu-link {
        display: flex;
        align-items: center;

        width: 100%;
        height: 35px;
        padding: 10px;

        color: var(--gray-700);

        &:hover {
            color: var(--orange);
        }

        @include rmin($desktop) {
            height: 45px;
        }
    }

    &__menu-item {
        &:not(:last-child) {
            border-bottom: 0.35px solid var(--gray-300);
        }

        &--2lvl {
            padding-left: 15px;
            font-weight: 300;
        }
        &--3lvl {
            padding-left: 25px;
            font-weight: 300;
        }
    }
}

@include rmin($desktop) {
    .dropdown-n {
        display: block;
        &--bg {
            display: flex;
            justify-content: center;
            width: 100vw;
            margin-top: -10px;
            margin-left: calc(-50vw + 50%);
            padding: 5px 0;
            background-color: var(--white-200);
        }

        &--bg &__btn-wrap {
            background-color: var(--white);
        }

        &__wrap {
            max-width: 1260px;
            width: 100%;
        }
        &__list {
            display: flex;
            gap: 5px;
        }
        &__list-top {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        &__list-top-5 {
            grid-template-columns: repeat(5, 1fr);
        }

        &__item {
            position: relative;
        }

        &__item--active &__menu-list {
            display: block;
        }

        &__item--active &__btn-wrap {
            &::after {
                top: 45%;

                transform: rotate(45deg);
            }
        }

        &__item-bottom &__menu-list {
            max-height: unset;
            overflow: hidden;
        }

        &__item-bottom {
            .product-filter__options-list {
                overflow-y: unset;
                margin: unset;
                // max-height: 175px;
                max-height: 300px;

                transition: unset;
                will-change: unset;
            }
        }

        &__menu-list {
            display: none;
            z-index: 7;
            position: absolute;
            top: 50px;
            width: 100%;
            max-height: 350px;

            padding-top: 5px;

            overflow-y: scroll;
            background-color: var(--white);
            box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.12);
        }

        &__btn-wrap {
            position: relative;

            display: flex;
            align-items: center;
            gap: 5px;
            padding-left: 10px;
            padding-top: 2px;
            height: 50px;

            background-color: var(--white-200);
            cursor: pointer;

            &:hover {
                background-color: var(--grayish);
            }

            &::after {
                content: "";
                position: absolute;

                top: 50%;
                right: 10px;
                z-index: 1;

                display: block;
                width: 12px;
                height: 12px;
                border-top: 2.5px solid var(--gray-400);
                border-left: 2.5px solid var(--gray-400);

                transform: translateY(-50%) rotate(225deg);
            }
        }
        &__btn {
            font-size: 16px;
        }
        &__btn-white {
            background-color: var(--white) !important;
        }

        &__sort &__btn-wrap {
            height: unset !important;
            padding-top: unset;
            background-color: unset !important;

            &::after {
                content: none !important;
            }
        }
        &__sort &__btn {
            display: flex;
            align-items: center;
            gap: 7px;

            svg {
                margin-top: -5px;
                width: 20px !important;
                height: 20px !important;
            }
        }

        &__sort &__menu-list {
            top: 30px !important;
        }

        &__sort {
            .product-filter__actions button {
                display: flex;
                align-items: center;
            }
            .product-filter__desktop {
                align-items: center;
            }
        }
        &__sort-list {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            gap: 5px;

            a {
                padding: 5px 15px;
            }

            a:hover {
                color: var(--orange-2);
            }
        }
    }
}
