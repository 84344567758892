.s-cart-empty-wrapper .s-actions-wrapper a,
.wa-buttons-wrapper .wa-login-submit,
.wa-signup-form-actions .wa-buttons-wrapper input[type="submit"],
.wa-forgotpassword-form-fields .wa-forgotpassword-button input[type="submit"],
.btn-details,
#wa-my-info-wrapper #wa-my-info-edit,
#wa-my-info-edit-wrapper .wa-value input[type="submit"] {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 40px;

    color: #fff;
    text-decoration: none;
    font-weight: 500 !important;
    text-transform: uppercase;
    // letter-spacing: 0.1em;

    background: linear-gradient(90deg, #fa5b0c 0%, #ffa173 90%);
    border-radius: 25px !important;
    border: none;

    &:hover,
    &:focus {
        background: linear-gradient(90deg, #e74e02 0%, rgb(255, 154, 104) 90%);
    }

    &::after {
        content: "";
        width: 12px;
        height: 12px;
        border-bottom: 4px solid #fff;
        border-right: 4px solid #fff;
        transform: rotate(-45deg);

        background-size: 12px 12px;
        background-repeat: no-repeat;

        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.wa-signup-form-actions .wa-buttons-wrapper input[type="submit"],
.wa-buttons-wrapper .wa-login-submit,
.wa-forgotpassword-form-fields .wa-forgotpassword-button input[type="submit"],
.wa-forgotpassword-form-fields .wa-forgotpassword-button input[type="submit"] {
    border-radius: 10px !important;
}

.wa-forgotpassword-form-fields .wa-forgotpassword-button {
    margin-top: 1em !important;
    font-size: 14px !important;
}

.wa-signup-form-actions .wa-buttons-wrapper input[type="submit"],
#wa-my-info-edit-wrapper .wa-value input[type="submit"] {
    appearance: none;
}

.btn-link {
    max-width: 500px;
    margin: 0 10px;
    &::after {
        content: none;
    }
}

.btn-n {
    &__default {
        height: 45px;
        width: 225px;

        font-size: 16px;
        color: var(--white);
        border-radius: 10px;
        background: linear-gradient(90deg, #fa5b0c 0%, #ffa173 90%);
        cursor: pointer;

        &:hover,
        &:focus {
            background: linear-gradient(
                90deg,
                #e74e02 0%,
                rgb(255, 154, 104) 90%
            );
        }
    }
}

.btn-disabled {
    pointer-events: none;

    color: var(--gray) !important;
    background-color: var(--gray-300) !important;
    background: unset;
}

.s-cart-empty-wrapper .s-actions-wrapper a,
.btn-see-more {
    margin: 0 auto;
    max-width: 250px;
    text-transform: unset;

    margin-top: 15px;

    &::after {
        border: unset !important;
    }
}
.s-cart-empty-wrapper .s-actions-wrapper a {
    padding: 0 15px;
    height: 55px;
    margin-top: 30px;

    font-size: 14px;
    border-radius: 5px;
    background: unset;
    background-color: var(--orange);
}

main .default-theme .multiform-submit input[type="submit"] {
    display: block;
    width: 100%;
    padding: 13px 0;
    max-width: 345px;

    color: #fff;
    font-size: 18px;
    font-weight: 500;

    background-color: var(--orange-2);
}

.js-submit-order-button,
.wa-order-dialog.wa-confirm-dialog .wa-button,
.btn-product {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    // letter-spacing: 0.1em;

    height: 50px;
    width: 100%;

    border-radius: 10px;

    background: linear-gradient(90deg, #fa5b0c 0%, #ffa173 90%);

    &:hover,
    &:focus {
        background: linear-gradient(90deg, #e74e02 0%, rgb(255, 154, 104) 90%);
    }
}

.btn-product--sm {
    justify-content: center;
    padding: 0 25px;
    max-width: 240px;
    margin: 0 auto;
    font-size: 16px;

    svg {
        margin-right: 10px;
    }
}

.wa-order-dialog.wa-confirm-dialog .wa-button {
    display: inline-block;
    font-size: 16px;

    width: auto;
    height: auto;
    padding: 10px;

    background: var(--orange);
}
.wa-order-dialog.wa-confirm-dialog .wa-button.js-cancel {
    background: var(--green);
}

.btn-rounded {
    border: 1px solid var(--orange);
    border-radius: 7px;
    padding: 3px 5px;
}

.btn-rounded-v2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    min-height: 45px;
    width: max-content;
    padding: 0 15px;

    border: 2px solid var(--orange);
    border-radius: 9px;
    transition: all 0.1s ease-in-out;
    span {
        font-size: 16px;
        font-weight: 500;
        color: var(--orange);
    }

    svg {
        fill: var(--orange);
    }

    &:hover {
        background: var(--orange);
        svg {
            fill: var(--white);
        }
        span {
            color: var(--white);
        }
    }

    &:active {
        transform: scale(0.98);
    }

    @include rmin($desktop) {
        min-height: 55px;
        width: 265px;

        span {
            font-size: 18px;
        }
    }
}

.btn-fill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    width: 100%;
    min-height: 45px;
    border-radius: 9px;
    color: var(--white);
    background: var(--orange);
}

.btn-del {
    background: transparent;
    color: var(--gray-500);
    border-radius: 10px;
    border: 1px solid var(--gray-500-gray-dark, #909090);
    transition: all 0.1s ease-in-out;
    &:hover {
        background: unset;
        border: 1px solid var(--orange);
        color: var(--orange);
    }
}
.btn-rect {
    border-radius: 7px;
}

.btn-load-more {
    justify-content: start;
    max-width: 280px;
    width: 100%;
    height: auto;
    margin-top: 30px;
    padding: 10px 30px;

    font-size: 22px;

    span {
        margin: 0 auto;
    }

    &::after {
        content: none;
    }

    svg {
        // margin-right: 20px;
    }
}

.btn-buy-one-click {
    flex-direction: column;
    background: unset;
    color: var(--light-orange);

    border: 1px solid var(--orange);
    border-radius: 10px;
    padding: 3px 5px;

    &:hover,
    &:focus {
        background: unset;
        color: var(--orange);
    }

    &--active {
        height: 220px;
        align-items: flex-start;
    }
}

.wa-shipping-pickup-dialog .wa-variant-actions {
}
.checkout-result.success .s-actions-wrapper a,
.wa-filters-list .js-set-filter,
.wa-shipping-pickup-dialog .wa-variant-actions button {
    font-weight: 500;
    font-size: 16px;

    background-color: var(--orange-2) !important;
    padding: 10px 15px;
    color: #fff !important;
}
.wa-shipping-pickup-dialog .wa-variant-actions .js-use-variant {
}

.wa-filters-list .js-set-filter {
}
