body:not(.menu-opened) .site-header__container--scroll-up > .site-header {
    transform: translateY(-50px) !important;

    @include rmin($desktop) {
        transform: translateY(-146px) !important;
    }
}

.site-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    background-color: #000;
    transition: transform 0.5s;
    will-change: transform;

    &__container {
        min-height: 50px;

        @include rmin($desktop) {
            min-height: 145px;
        }
    }

    &__container--promo {
        min-height: 85px;

        @include rmin($desktop) {
            min-height: 195px;
        }
    }

    a {
        font-size: inherit;
        text-decoration: none;
    }

    &__top-line,
    &__contacts-info,
    &__user-actions {
        display: flex;
    }

    &__top-line {
        position: relative;
        z-index: 5;

        height: 50px;

        transition: transform 0.3s ease-in;
        // will-change: transform;
        border-bottom: $border-header;
        background-color: #000;

        * {
            color: #fff;
            fill: currentColor;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        height: 50px;
        padding: 10px;

        border-right: 1px solid var(--dark-gray);

        & > * {
            height: 100%;
            // box-sizing: content-box;
        }
    }

    /***** burger toggler *****/
    &__nav-toggler-wrapper {
        position: relative;

        width: 55px;
        padding: 0;
    }

    &__nav-toggler {
        position: relative;
        top: 0;
        right: 0;
        z-index: 0;

        display: flex;
        align-items: center;
        width: 100%;

        font-size: 24px;
        line-height: 24px;
        color: #291d05;
        text-transform: uppercase;

        background-color: transparent;
        touch-action: manipulation;

        .shape {
            position: absolute;
            left: 50%;
            top: 50%;

            width: calc(100% - 50%);
            height: 18px;

            transform: translate(-50%, -50%);
        }

        .line {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;

            background-color: currentColor;
            transform-origin: left center;
            transform: scaleX(1);
            transition: transform 0.25s;
            will-change: transform;

            &:nth-child(2) {
                top: 8px;
            }

            &:last-child {
                top: 16px;
            }
        }

        .close {
            position: absolute;
            left: 50%;
            top: 50%;

            width: 35px;
            height: 35px;

            color: #fff;

            transform: translate(-50%, -50%) rotate(0) scale(0);

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;

                background: currentColor;
            }

            &::before {
                width: 3px;
                height: 25px;
                margin: -12px 0 0 -1px;
            }

            &::after {
                width: 25px;
                height: 3px;
                margin: -1px 0 0 -12px;
            }
        }
    }

    &__nav-toggler--opened {
        .line:first-child {
            transform: translate(0.75px, 8px) rotate(-45deg) scaleX(0.6);
        }

        .line:last-child {
            transform: translate(0.75px, -8px) rotate(45deg) scaleX(0.6);
        }
    }

    &__customers-block {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 4;

        display: none;
        width: 215px;
        height: auto !important;
        // padding-top: 35px;

        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background-color: #fff;

        &::before {
            // content: '';
            position: absolute;
            top: 0;
            left: 50%;

            transform: translateX(-50%);
            @include arr(60, 15, #000, b);
        }
    }

    &__customers-block &__customers-list {
        position: static;

        display: block !important;
        height: auto;
        max-height: none;

        transform: none;
        border-right: none;
        overflow: unset;
    }

    &__customers-block &__customers-item {
        color: #000;
    }

    &__customers-item a {
        padding: 10px 20px !important;

        text-align: center;

        border: none;

        &:hover,
        &:focus {
            color: var(--orange);
        }
    }

    /***** contacts-info *****/
    &__contacts-info {
        display: none;

        font-size: 12px;
    }

    &__city-wrapper {
        display: none;
    }

    &__city {
    }

    &__phone-wrapper {
    }

    &__phone {
        padding: 0px 5px;
        max-width: 65px;

        text-align: center;
    }

    /***** logo *****/
    &__logo-wrapper {
        position: absolute;
        top: 0;
        left: 101px;
        right: 93px;
        bottom: 0;
    }

    &__logo-link {
        border: none;
    }

    &__logo-img {
        min-width: 97px;
        width: 50%;
        max-width: 150px;
        height: auto;
    }

    /***** user-actions *****/
    &__user-actions {
        flex-grow: 1;
        justify-content: space-between;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__user-action {
        padding: 10px 12.5px;
    }

    &__action-search {
    }

    &__action-user {
        // display: none;
    }

    &__action-like {
        margin-left: auto;

        a {
            border-left: 1px solid var(--dark-gray);
        }
    }

    &__action-shop {
        .site-header__item {
            position: relative;

            border-right: unset;
        }
    }
    &__action-shop .s-count {
        position: absolute;
        right: 5px;
        top: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--size);
        height: var(--size);
        --size: 12px;

        font-weight: bold;
        font-size: 8px;
        line-height: 8px;

        background-color: var(--orange);
        border-radius: 50%;
    }

    &__action-user {
        display: none;
    }

    /***** menu-opened *****/
    // &__
}

@include rmin($desktop) {
    .site-header {
        &__top-line {
            height: auto;
        }

        &__top-menu {
            height: 85px;
        }

        &__item {
            min-width: 65px;
            height: 75px;
        }

        /***** burder toggler *****/
        &__nav-toggler-wrapper {
        }
        &__nav-toggler {
            .line {
                // transform-origin: left;
            }

            .line:nth-child(2) {
                transition: opacity 0.2s;
            }

            .shape {
                top: 45%;
            }

            &::after {
                content: "Menu";
                position: absolute;
                bottom: 5%;
                left: 50%;

                font-size: 12px;
                color: #fff;
                text-transform: uppercase;

                transform: translateX(-50%);
            }
        }

        &__nav-toggler--opened {
            .line:first-child {
                transform: rotate(45deg) scaleX(0.7);
            }

            .line:nth-child(2) {
                opacity: 0;
            }

            .line:last-child {
                transform: rotate(-45deg) scaleX(0.7);
            }
        }

        &__nav-toggler--opened + &__customers-block {
            display: block;
        }

        /***** contacts info *****/
        &__contacts-info,
        &__city-wrapper {
            display: block;
        }

        &__contacts-info {
            display: flex;
        }

        &__city-wrapper,
        &__phone-wrapper {
            font-size: 18px;
        }

        &__city {
            min-width: 85px;
        }

        &__phone {
            max-width: none;
            padding-left: 30px;

            border-right: none;
        }

        /***** logo *****/
        &__logo-wrapper {
            left: 320px;
            right: 320px;
        }

        &__logo-img {
            max-width: 240px;
        }

        /***** user actions *****/
        &__user-actions {
            flex-grow: 0;
            margin-left: auto;

            svg {
                width: 27px;
                height: 27px;
            }
        }

        &__action-user {
            display: block;
        }
        &__action-shop .s-count {
            right: 12.5px;
            top: 20px;

            width: var(--size);
            height: var(--size);
            --size: 17px;

            font-weight: bold;
            font-size: 9px;
            line-height: 9px;
        }
    }
}
