.fav{
    &-page__inner {
        margin: 0 auto 85px;
        text-align: center;
    }
    &-page__btn{
        padding: 11.5px;
        font-size: 18px;
        line-height: 21px;
    }
    &-page__desc{
        display: inline-block;
        margin: 0 auto;
        max-width: 344px;
    }

    &-page__items .cards__select-wrapper{
        max-width: 300px;

        &:after{
            background-color: transparent;
        }
    }
    &-page__items  .cards__sizes-select{
        background-size: 11px !important;
    }
    &-page__items  select{
        padding: 9px 17px;

        font-size: 14px;
        font-weight: 500;
        color: var(--gray-400);
        border: 1px solid var(--gray-400);
        border-radius: 10px;
    }
    &-page__items button{
        max-width: 300px;
        font-size: 11px !important;
    }
    &-page__items .cards__item .btn-product--sm{
        height: 44px !important;
    }
}
@include rmin($tablet){
    .fav{
        &-page__items>.cards__item{
            --card-size: 33%;
        }
    }
}

@include rmin($desktop){
    .fav{
        &-page__items select{
            font-size: 18px;
        }
        &-page__items button{
            font-size: 18px !important;
        }
        &-page__items>.cards__item{
            --card-size: 25%;
        }
    }
}
