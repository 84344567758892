.promo-n__static {
    &-slider {
        position: relative;
        z-index: 5;

        &--rounded {
            border-radius: 15px;
            overflow: hidden;
        }
    }
    &-wrap {
        background-color: var(--black);
        min-height: 35px;
        height: 35px;

        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;

        &--header {
        }
        &--only-text {
        }
        &--promocode {
        }

        @include rmin($desktop) {
            min-height: 50px;
            height: 50px;
        }
    }

    &-text-wrap,
    &-text {
        display: flex;
        align-items: center;
        user-select: none;
        justify-content: center;
    }

    &-text-wrap {
        height: 100%;

        color: var(--white);

        strong {
            margin-bottom: -0.15%;
            text-transform: uppercase;
        }

        span {
            display: none;
        }
    }

    &--only-text &-text-wrap {
        strong {
            font-size: 18px;
            line-height: 18px;

            @include rmin($desktop) {
                font-size: 24px;
                line-height: 24px;
            }
        }
    }
    &--promocode &-text-wrap {
        strong {
            font-size: 14px;
            line-height: 14px;

            @include rmin($desktop) {
                font-size: 18px;
                line-height: 18px;
            }
        }
    }

    &--promocode &-promocode {
        display: flex;
        align-items: center;
        gap: 7px;
        padding: 2px 5px;

        font-size: 14px;
        border: 1.5px solid var(--white);
        text-transform: uppercase;
        cursor: pointer;

        svg {
            margin-bottom: 3px;
            width: 20px;
            height: 20px;
            fill: currentColor;
        }

        @include rmin($desktop) {
            padding: 5px 12px;
            font-size: 16px;
        }
    }

    &-text {
        @include rmin($desktop) {
            white-space: nowrap;
        }

        & > * {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.tooltip {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 8px;
    transform: translate(-50%, -50%);
    background-color: var(--orange);
    color: #fff;
    border-radius: 10px;
}

.promo-n__sport {
    margin: 15px 0;
    max-width: 367px;
    min-height: 40px;
    background-position: 170px;
    border-radius: 12px;
    .promo-n__static-text {
        font-size: 14px;
        strong {
            text-transform: none;
        }
        span {
            display: block;
            font-size: 16px;
            margin: 0;
            color: var(--orange);
        }
        button {
            display: flex;
        }
    }
}

@include rmin($desktop) {
    .promo-n__sport {
        margin: 25px 0;
        min-height: 50px;
        background-position: 210px;
        border-radius: 15px;
        .promo-n__static-text {
            font-size: 18px;
            button {
                transition: transform 0.1s ease-in-out;
                &:hover {
                    transform: scale(1.1);
                }
                &:active {
                    transform: scale(0.9);
                }
            }
        }
    }
}
