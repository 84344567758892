.about-us {
    padding: 0 10px;

    h1 {
        font-size: 20px;
        margin-top: 50px;
        margin-bottom: 20px;
        padding: 0 35px;
        text-align: center;
        font-weight: 500;
    }

    &__description-wrapper,
    &__description {
        max-width: 1000px;
        text-align: center;
    }

    &__description-wrapper {
        margin: 0 auto;
    }

    &__description {
        width: 100%;
        padding: 0;

        color: var(--black-100);

        overflow: unset;
        min-height: unset;
        max-height: unset;
        height: 100%;

        h2 {
            font-size: 26px;
            margin-top: 25px;
            margin-bottom: 10px;
        }

        p {
            font-weight: 300;
        }

        strong {
            font-weight: 500;
        }

        &--clip {
            min-height: 155px;
            max-height: 155px;

            overflow: hidden;
        }
    }

    &__show-btn {
        margin-top: 15px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        text-decoration: underline;

        // &::before {
        //     display: block;
        //     color: var(--gray);
        //     text-decoration-color: currentColor;
        //     content: "Показать еще текст";
        // }

        // &:hover::before {
        //     color: var(--orange) !important;
        //     text-decoration-color: currentColor;
        // }
    }

    // &__description &__show-btn {
    //     &::after {
    //         display: none;
    //         content: "Скрыть текст";
    //     }
    // }

    // &__description--show &__show-btn {
    //     &::before {
    //         display: none;
    //         content: "Показать еще текст";
    //     }

    //     &::after {
    //         display: block;
    //         color: var(--orange);
    //         text-decoration-color: currentColor;
    //         content: "Скрыть текст";
    //     }
    // }

    &__show-btn {
        &--first {
            display: block;
            color: var(--gray);
            text-decoration-color: currentColor;

            &:hover {
                color: var(--orange) !important;
                text-decoration-color: currentColor;
            }
        }
        &--second {
            display: none;
        }
    }

    &__description--show &__show-btn {
        &--first {
            display: none;
        }
        &--second {
            display: block;
            color: var(--orange);
            text-decoration-color: currentColor;
        }
    }
}

@include rmin($tablet) {
    .about-us {
        padding: 0 25px;

        h1 {
            font-size: 35px;
        }
    }
}
