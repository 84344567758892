.product__set {
    &-list {
        gap: 10px;

        @include rmin($desktop) {
            gap: 15px;
        }
    }

    &-inner {
        margin: 35px 0;
        margin-right: -10px;

        & h3 {
            font-size: 16px;
            font-weight: 500;
        }
    }

    &-items {
        display: flex;
        margin-top: 10px;
        overflow-x: scroll;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 115px;

        text-align: center;
        text-decoration: none;

        @include rmin($desktop) {
            width: 140px;
        }
    }

    &-item:hover &-text {
        color: var(--orange);
    }

    &-link {
        display: block;
        width: 100%;
    }

    &-text {
        margin-top: 5px;

        font-size: 14px;
        font-weight: 300;

        @include rmin($desktop) {
            font-size: 16px;
        }
    }

    &-price {
        font-weight: 500;
    }

    &-img-wrap {
        display: flex;
        border: 1px solid var(--gray-300);
        border-radius: 9px;
        overflow: hidden;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@include rmin($desktop) {
    .product__set {
        &-img-wrap {
        }

        &-inner {
            margin-bottom: 0;
        }

        &-items {
            flex-wrap: wrap;
            margin-top: 15px;
            overflow-x: unset;
        }

        &-item {
        }

        &-price {
            margin-top: 3px;
            font-size: 14px;
        }
    }
}
