.category {
    &__info,
    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__info {
        margin-top: 25px;
    }

    &__title {
        h1 {
            position: relative;

            margin-top: 20px;
            margin-bottom: 10px;
            padding: 0;

            font-size: 25px;
        }

        &-ico {
            display: none;
        }
    }

    &__description {
        max-width: 875px;
        padding: 0 15px;

        text-align: center;
        color: var(--dark-gray);
    }

    &__description h2 {
        margin-top: 0 !important;
        padding: 0 !important;
        font-size: 20px;
        line-height: 22px;

        @include rmin($desktop) {
            font-size: 28px;
            line-height: 30px;
        }
    }
    &__description h2:not(:first-child) {
        margin-top: 15px !important;

        @include rmin($desktop) {
            margin-top: 25px !important;
        }
    }

    // content
    &__main-content {
    }

    // filters
    &__filters-wrapper {
    }

    &__filter--fixed {
        position: fixed;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 25px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            position: relative;

            margin-top: 20px;
            margin-bottom: 10px;

            font-size: 25px;
        }

        &-ico {
            display: none;
        }
    }

    &__description {
        // max-width: 800px;
        padding: 0 15px;

        text-align: center;
        color: var(--dark-gray);
    }

    &__info,
    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        h1 {
            position: relative;

            margin-top: 20px;
            margin-bottom: 10px;

            font-size: 25px;
        }

        &-ico {
            display: none;
        }
    }

    &__info-dscr {
        align-items: flex-end;
    }

    &__description-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        // width: 100%;
        max-width: 875px;
        margin-top: 0;
    }

    &__description {
        width: 100%;
        margin-top: 0;
        padding: 0;

        text-align: center;
        color: var(--dark-gray);

        overflow: unset;
        min-height: unset;
        max-height: unset;
        height: 100%;

        &--clip {
            // min-height: 65px;
            // max-height: 65px;
            min-height: 125px;
            max-height: 125px;

            overflow: hidden;
        }
    }

    &__description--clip {
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }

    &__show-btn {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-decoration: underline;

        &::before {
            display: block;
            content: "Показать";
        }
    }

    &__description &__show-btn {
        &::after {
            display: none;
            content: "Скрыть";
        }
    }

    &__description--show &__show-btn {
        &::before {
            display: none;
            content: "Показать";
        }

        &::after {
            display: block;
            content: "Скрыть";
        }
    }

    &__filter {
        // position: absolute;
        // top: 68px;
        // left: 0px;
        // right: 0px;
        // z-index: 3;

        // padding: 12px 10px;

        background-color: #fff;
    }

    &__filter--fixed {
        position: fixed;
    }

    // products
    &__products-wrapper {
        flex: 1;
        margin-top: 30px;
    }
}

.menu-opened .product-filter__main-fields {
    top: 0;
}

@include rmin($tablet) {
    .category {
        &__title {
            h1 {
                font-size: 35px;
                margin-top: unset;
                margin-bottom: 30px;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: 50%;

                    width: 70px;
                    height: 4px;
                    margin-left: -35px;

                    background-color: var(--orange);
                }
            }

            &-ico {
                display: block;

                fill: var(--orange);
            }
        }
    }
}
.category-v2 {
    .checkbox-toggler {
        box-shadow: 0 0 0 2px var(--orange) !important;
        .toggler {
            background: var(--orange) !important;
        }
    }

    @include rmin($desktop) {
        .category__filters-wrapper {
            display: none;
        }
    }
}

@include rmin($desktop) {
    .category {
        // category-info
        &__info {
            margin-top: 15px;
            margin-bottom: 50px;
        }

        &__info-dscr {
        }

        &__main-content {
            display: flex;
        }
        &__main-content-v2 {
            .category__filters-wrapper {
                display: none;
            }
        }

        // filter
        &__filters-wrapper {
            flex-grow: 1;
            max-width: 300px;
            margin-right: 85px;
        }

        &__filter {
            position: relative;
            top: 0;

            padding: 0;
        }

        // &__description--clip {
        // display: unset;
        // width: 100%;

        // text-overflow: unset;
        // overflow: unset;
        // white-space: unset;
        // }

        // &__show-btn {
        // 	&::before {
        // 		content: '';
        // 	}
        // }

        &__products-wrapper {
            position: relative;
            margin-top: 60px;
        }
        &__title h1 {
            font-size: 45px;
        }
    }
}
