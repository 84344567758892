.error-page {
  text-align: center;

  &__title {
    display: block;
    margin: 10px 0;

    font-size: 24px;
  }
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;

    span {
      margin-right: 5px;
    }

    svg {
      max-width: 20px;
    }

    &--small {
      font-size: 14px;
    }
  }
  &__link {
    display: block;
    max-width: 230px;
    margin: 10px auto 20px;
    padding: 15px 10px;

    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: var(--orange);
    border-radius: 5px;
  }
  &__img-wrapper {
    max-width: 500px;
    margin: 10px auto 0;
  }
  &__img {
  }
}

@include rmin($tablet) {
  .error-page {
    &__title {
      margin: 20px 0;
  
      font-size: 34px;
    }
    &__text {
      margin-bottom: 20px;
      font-size: 20px;
  
      span {
        margin-right: 10px;
      }

      svg {
        max-width: 35px;
      }
  
      &--small {
        font-size: 16px;
      }
    }
    &__link {
      margin: 20px auto 40px;
      padding: 15px;  
    }
    &__img-wrapper {
      margin: 20px auto 0;
    }
    &__img {
    }
  }
}
