.product-label {
    margin-top: 5px;
    &__list {
        display: flex;
        gap: 5px;
        // white-space: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 11px;
        height: 25px;
        font-weight: 600;
        font-size: 13px;
        border-radius: 7px;
        background: var(--gray-bg);
        scroll-snap-align: center;
        white-space: nowrap;
        &--stock {
            color: var(--white);
            background: var(--orange);
        }
    }
    &__promocode-wrap {
        display: flex;
        cursor: pointer;
        gap: 5px;
    }
}

@include rmin($desktop) {
    .product-label {
        display: flex;
        width: var(--widthLimit);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &__list {
            display: flex;
            gap: 5px;
            transition: 1s linear;
            white-space: unset;
            overflow-x: unset;
        }
        &__item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 11px;
            
            height: 25px;
            
            font-weight: 600;
            font-size: 13px;
            border-radius: 7px;
            background: var(--gray-bg);
            transition: transform 0.5s;
            
            &--stock {
                color: var(--white);
                background: var(--orange);
            }
        }
    }

    .product-label__list.limited:hover {
        transform: translateX(calc(-100% + var(--widthLimit)));
    }
}
