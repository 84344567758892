.why-we {
    p:first-child {
        margin-top: 30px;
        margin-bottom: 10px;
        padding: 0 35px;
        text-align: center;
        font-weight: 500;
        font-size: 25px;

        @include rmin($desktop) {
            font-size: 35px;
            margin-top: 50px;
            margin-bottom: 20px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        padding-top: 15px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 50%;

        font-size: 14px;
        text-align: center;

        a {
            color: var(--light-orange);
            padding: 0 10px;

            &:hover {
                color: var(--orange);
            }
        }

        p {
            margin-top: 10px !important;
        }

        .icon {
            width: 50px;
            height: 50px;
        }
    }
}

@include rmin($desktop) {
    .why-we {
        &__list {
            // justify-content: space-around;
        }

        &__item {
            margin-left: 30px;
            margin-right: 30px;
            width: 25%;

            font-size: 18px;

            .icon {
                width: 100px;
                height: 100px;
            }
        }
    }
}
