.brands-slider {
    &__wrapper {
        margin: 0 auto;
        width: 75%;
    }
    &.brands-slider__wrapper {
        .glide__slide {
            width: auto;
        }
    }

    .glide {
        --just-content: center;
    }

    &.brands-slider__wrapper {
        .glide:not([class*="glide--"]) {
            .glide__slides {
                justify-content: var(--just-content);
            }

            .glide__slide {
                max-width: 60%;
            }
        }
    }

    .glide__slide-cover {
        display: flex;
        align-items: center;
        // height: 75px;
    }

    .glide__arrow {
        width: 30px;
        height: 30px;

        &::after {
            width: 10px;
            height: 10px;
            background-size: 10px 10px;

            right: 10px;
            top: 8px;
        }
    }

    .glide__arrow--left {
        left: -40px;

        &::after {
            left: 10px;
        }
    }

    .glide__arrow--right {
        right: -40px;
    }

    &__link-wrapper {
        display: none;
        // display: flex;
        // justify-content: center;
        // // margin-top: 5px;
    }

    &__link {
        color: #000;
        font-weight: 700;
        font-size: 16px;

        text-align: center;
        text-decoration: none;
        border-bottom: 2px solid #000;

        &:hover,
        &:focus {
            color: var(--orange);
            border-color: var(--orange);
        }
    }
}

@include rmin($tablet) {
    .brands-slider {
        &__wrapper {
            width: 85%;
        }

        .glide__arrow--left {
            left: -4.2em;
        }

        .glide__arrow--right {
            right: -4.2em;
        }
        .glide {
            --just-content: flex-start;
        }
    }

}

@include rmin($desktop) {
    .brands-slider {
        &__wrapper {
            position: relative;
            width: auto;
            // margin-right: 180px;
            margin-right: 40px;
            margin-left: 40px;
        }

        &__link-wrapper {
            position: absolute;
            // right: -140px;
            top: 20px;
        }

        &__link {
            font-size: 20px;
            padding: 0 5px;
            border-bottom-width: 3px;
        }

        .glide__arrow--left {
            left: -40px;
        }

        .glide__arrow--right {
            // right: -180px;
            right: -40px;
        }

        .glide__slide-cover {
            padding: 5px 15px;
        }
    }
}
