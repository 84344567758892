html,
body {
    // max-width: 100vw;
    // height: 100%;

    overflow-x: hidden;

    @include rmin($desktop) {
        overflow-x: unset;
    }
}

html {
    overflow-y: scroll;

    @include rmin($desktop) {
        overflow-y: unset;
    }
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    font-size: 16px;
    line-height: 1.4;
    overflow-y: auto;

    * {
        // letter-spacing: 0.05em;
        font-family: $font-main;
    }

    @include rmin($desktop) {
        overflow-y: unset;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong,
    button {
        font-weight: 500;
    }

    p {
        font-weight: 300;
    }
}

.page-backdrop {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;

    width: 100%;
    height: 100%;

    pointer-events: none;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;

    @include rmin($desktop) {
        top: 0;

        &--opened {
            opacity: 1;
            pointer-events: all;
        }
    }
}

main {
    flex-grow: 1;
}

main,
.page-backdrop {
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}

.menu-opened {
    main,
    .site-header__top-line,
    .page-backdrop {
        transform: translate3d(280px, 0, 0);
        transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    }

    .page-backdrop {
        opacity: 1;
        pointer-events: all;
    }

    @include rmin($desktop) {
        main,
        .site-header__top-line,
        .page-backdrop {
            transform: none;
            transition: none;
        }

        .page-backdrop {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.nowrap {
    white-space: nowrap;
}

.recently-watched.tabs__content {
    // display: block !important;
}

.custom-content-page,
.content-wrapper,
h1,
#page {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 10px;
}

.s-order-page {
    max-width: $max-width !important;
    width: auto !important;
    margin: 0 auto !important;
    padding: 0 10px !important;
}

img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.visually-hidden,
.visually-hidden::after,
.visually-hidden::before {
    position: absolute !important;
    z-index: -1 !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    opacity: 0 !important;
    font-size: 1px !important;
    clip: rect(0 0 0 0) !important;
}

.icon {
    width: 35px;
    height: 35px;
}

.vector-icon {
    fill: var(--orange);
    width: 35px;
    height: 35px;
}

.vector-brands-logo {
    fill: #000;
}

.main-text {
    color: var(--gray);
    text-align: center;
    margin: 15px 0;
}

.alert {
    display: none;
    padding: 5px 10px 2px;

    font-weight: 500;
    border-radius: 5px;
    font-size: 14px;

    &--danger {
        color: #fc0d1b;
        background-color: #f8e9e9;
    }

    @include rmin($desktop) {
        font-size: 16px;
    }
}

// startwebsite popup
.startwebsite-popup__modal {
    box-shadow: none;
}

.startwebsite-popup__close_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--white);
}

.startwebsite-popup__close_icon::before {
    color: var(--orange);
}
// end

// glidejs slider
.glide__arrow {
    width: 60px;
    height: 60px;
    border-radius: 50% !important;
    background-color: #fff !important;
    border: 2px solid var(--light-gray) !important;

    &:hover,
    &:focus {
        border: 2px solid var(--orange) !important;
        border-color: var(--orange) !important;
    }

    &::after {
        content: "";
        width: 15px;
        height: 15px;

        border-bottom: 2px solid var(--gray);
        border-right: 2px solid var(--gray);
        transform: rotate(-45deg);
        background-size: 15px 15px;
        background-repeat: no-repeat;

        position: absolute;
        right: 22px;
        top: 20px;
    }
}

.glide__arrow--left {
    left: 5em;

    &::after {
        left: 22px;
        transform: rotate(135deg);
    }
}

.glide__arrow--right {
    right: 5em;
}

@include rmin($desktop) {
    .glide:not(#brands) .glide__slides {
        min-height: 310px;
    }
}

// .glide:not([class*="glide--"])  {
//     .glide__arrows {
//         display: none;
//     }
// }

section h2 {
    margin-top: 30px;
    margin-bottom: 10px;

    padding: 0 35px;

    text-align: center;
    font-weight: 500;
    font-size: 25px;
}

section {
    .about-us {
        h1 {
            margin-top: 30px;
            margin-bottom: 10px;

            padding: 0 35px;

            text-align: center;
            font-weight: 500;
            font-size: 25px;
        }
    }
}

// go to top
.scroll-to-top {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0.5;

    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border: none;

    &:hover {
        opacity: 1;
    }

    .glide__arrow {
        width: 50px;
        height: 50px;

        &:after {
            top: 18px;
            right: 15px;
            transform: rotate(-135deg);
        }
    }
}

// end go to top

.sort {
    &__wrapper {
        display: flex;
        margin-bottom: 40px;
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
    }

    &__list {
        display: flex;
    }

    &__item {
        color: var(--gray);
        margin: 0 15px;

        &:hover {
            color: #000;
        }
    }

    &__item--current {
        color: #000;
        border-bottom: $border-header;
        border-bottom-width: 2px;
    }

    &__link {
        text-decoration: none;
        color: currentColor;
    }
}

.breadcrumbs {
    &__navigation {
        display: none;
    }
}

.shop_favorites {
    display: none;
}

@include rmin($tablet) {
    .breadcrumbs {
        &__navigation {
            display: block;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__item {
            &:not(:last-child) {
                &::after {
                    content: "-";
                    margin: 0 3px;
                }
            }
        }

        &__link {
            color: #000;
            font-size: 16px;
            text-decoration: none;

            &:hover,
            &:focus {
                color: var(--orange);
            }
        }
    }
}

@include rmin($desktop) {
    section h2 {
        font-size: 35px;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    section {
        .about-us {
            h1 {
                font-size: 35px;
                margin-top: 50px;
                margin-bottom: 20px;
            }
        }
    }

    &__item {
        padding: 0 35px;

        button {
            font-size: 20px;
        }
    }

    .scroll-to-top {
        bottom: 30px;
        right: 30px;
    }
}

//typography
.typography {
    &__inner {
        margin: 50px 0;
        font-size: 20px;
        letter-spacing: 0.05em;
    }

    &__inner h3 {
        font-size: 24px;
        font-weight: 500;
        color: var(--orange);
    }

    &__item {
        margin-top: 25px;
    }

    &__item h5 {
        font-size: 20px;
        font-weight: 600;
        color: var(--black);
    }

    &__item h5 span {
        font-weight: 500;
        color: var(--orange);
    }

    &__item p {
        font-weight: 300 !important;
        margin-top: 15px;
    }

    &__text {
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        margin-top: 25px;

        &--main {
            color: var(--orange);
        }
    }

    &__item a {
        font-weight: 400 !important;
        color: var(--orange-2) !important;
        text-decoration-line: underline;
    }

    &__item--md {
        & h5 {
            margin-top: 25px !important;
        }
    }

    &__list--md {
        font-size: 20px;
        margin-left: 0 !important;
        margin-bottom: 25px !important;

        & li {
            margin-top: 10px !important;
        }
    }

    &__text--md {
        display: inline-block;
        margin-top: 15px;
        font-size: 20px;
    }

    &__list,
    &__item {
        counter-reset: item;
        margin: 25px 0 0;
    }

    &__item ol,
    &__item ul {
        & > * {
            margin-top: 5px;
        }
    }

    &__item li {
        font-weight: 300;
    }

    &__item ul,
    &__item ol {
        margin-left: 15px;
        margin-top: 15px;
    }

    &__item ul li,
    &__item ol li {
        position: relative;
    }

    &__item ul li {
        display: flex;
        align-items: flex-start;

        padding-left: 15px;
        font-weight: 300 !important;
    }

    &__item ul li::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        width: 5px;
        height: 5px;

        margin-right: 15px;
        background: var(--orange);
        border-radius: 50%;
        list-style-type: circle;
    }

    &__item ol {
        & li {
            padding-left: 20px;

            &::before {
                position: absolute;
                left: 0;
                content: counters(item, ".") "." " ";
                counter-increment: item;
                color: var(--orange);
                list-style-type: decimal;
            }
        }
    }

    &__list-item li {
        padding-left: 25px !important;
        font-size: 16px;
    }

    &__img {
        margin: 50px 0 85px;
    }

    &__tabs {
        margin-right: 35px;
    }
}

.t-crop {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--one-l {
        -webkit-line-clamp: 1;
    }

    &--two-l {
        -webkit-line-clamp: 2;
    }

    &--tree-l {
        -webkit-line-clamp: 3;
    }

    &--four-l {
        -webkit-line-clamp: 10;
    }
}

@include rmin($tablet) {
    .typography {
        &__text {
            font-size: 20px;
        }

        &__list-item li {
            font-size: 20px;
        }
    }
}

.wa-login-form-fields,
.wa-forgotpassword-form-wrapper,
.wa-signup-form-fields {
    width: 100% !important;
}

.wa-forgotpassword-form-wrapper,
.wa-signup-form-wrapper,
.wa-login-form-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
}

.wa-signup-form-actions {
    display: flex;
    flex-direction: column;

    .wa-buttons-wrapper {
        margin-top: 10px;
    }
}

.wa-login-form-wrapper {
    .wa-login-form-actions {
        display: flex;
        flex-direction: column;
        width: 100%;

        .wa-buttons-wrapper {
            order: -1;
        }
    }

    //
    .wa-adapters-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 10px;
        margin-bottom: 25px;
    }
    .wa-adapters-section {
        .wa-section-header {
            color: var(--gray-500);
        }
        .wa-section-divider {
            color: var(--gray-500);

            span {
                position: relative;
                padding: 0 10px;
                background-color: var(--white);

                &::after {
                    z-index: -1;
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    display: block;
                    width: 300px;
                    height: 1px;
                    background-color: var(--gray-2);
                }
            }
        }
    }

    .wa-adapters-list {
        display: flex;
        margin-top: 15px;
        margin-bottom: 15px;

        li {
            margin-left: 5px;
            margin-right: 5px;
        }
        a {
            display: flex;
            &:hover {
                opacity: 0.75;
            }
        }

        a img {
            width: 45px;
        }
    }
}

.wa-checkbox-wrapper .s-icon {
    display: none;
}

.wa-auth-adapters.wa-connect {
    display: flex;
    flex-direction: column;

    margin-bottom: 35px;
}

.wa-auth-adapters {
    h3 {
        margin-bottom: 5px;
        order: -1;
    }
    p {
        order: -1;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    img {
        width: 40px;
        margin-right: 7px;
    }

    li span,
    li a {
        display: inline-flex;
        align-items: center;

        border-radius: 90px;
        padding: 4px 20px 4px 4px;
    }

    li a {
        &:hover {
            background-color: var(--white-100);
        }
    }

    li span {
        color: var(--orange-2);

        &::after {
            content: "(Выполнен вход)";
            margin-left: 5px;
        }
    }
}

.flexdiscount-discounts {
    padding: 3px;
}

.flexdiscount-discounts-heading {
    border-radius: 5px;
    background: var(--white-100);
}

.flexdiscount-discounts-body.flexdiscount-interactive {
    position: relative;
    padding-top: 10px;
    padding-bottom: 60px;
    padding-left: 5px;
    padding-right: 5px;

    border-radius: 8px;

    .h3 {
        text-align: center;
        color: var(--orange);
    }

    th:nth-child(2) {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
    }

    .fl-discount-skus {
        td:first-child {
            color: var(--gray-500);
            padding-top: 5px;
            padding-bottom: 5px;
        }
        td:last-child {
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;

            text-align: center;
        }
    }

    @include rmin($desktop) {
        padding-bottom: 45px;

        th:nth-child(2) {
            bottom: 25px;
        }

        .fl-discount-skus {
            td:last-child {
                bottom: -12px;
            }
        }
    }
}

@include rmin($desktop) {
    .typography {
        &-tabs__wrapper {
            margin-top: 35px;
        }
    }

    .wa-login-form-fields,
    .wa-signup-form-fields {
        width: 450px !important;
    }

    .wa-forgotpassword-form-wrapper,
    .wa-signup-form-wrapper,
    .wa-login-form-wrapper {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .wa-signup-form-actions {
        display: unset;
        flex-direction: unset;

        .wa-buttons-wrapper {
            margin-top: unset;
        }
    }

    .wa-login-form-wrapper {
        .wa-login-form-actions {
            display: unset;
            flex-direction: unset;

            .wa-buttons-wrapper {
                order: unset;
            }
        }
    }
}

ul {
    list-style: unset !important;
    list-style-type: unset !important;

    li,
    ol {
        list-style: none !important;
        list-style-type: none !important;
    }
}

.b-r-10 {
    border-radius: 10px !important;
}

.price-range {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 5px;

    &-item {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        color: var(--gray-500);

        span {
            min-width: 22px;
        }
        input {
            padding: 10px 7px 7px 7px;
            display: flex;
            flex: 1;
            border: $border;
            border-radius: 7px;
            font-size: 16px;

            &:focus {
                border-color: var(--orange-2);
            }
        }
    }
}

.error {
    color: var(--orangered);
}

.empty-page__wrap {
    height: -webkit-fill-available;
}

.spinner__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
.spinner {
    display: block !important;
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--orange-2);
    box-shadow: 0 0 0 0 rgba(255, 111, 0, 0.267);
    animation: l2 1.5s infinite linear;
    position: relative;
    &::before,
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        box-shadow: 0 0 0 0 rgba(255, 111, 0, 0.267);
        animation: inherit;
        animation-delay: -0.5s;
    }
    &::after {
        animation-delay: -1s;
    }
    @keyframes l2 {
        100% {
            box-shadow: 0 0 0 40px #0000;
        }
    }
}
