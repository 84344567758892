.a-look {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 5px;
    background-color: var(--white-200);

    @include r($desktop) {
        margin-left: -10px;
        margin-right: -10px;
    }

    @include rmin($desktop) {
        gap: 25px;
        padding: 25px;
        border-radius: 25px;
    }

    &__title {
        font-size: 18px;
        text-align: center;

        @include rmin($desktop) {
            font-size: 24px;
        }
    }

    &__list {
        display: flex;
        gap: 7px;

        @include r($desktop) {
            width: 100%;
            // overflow-x: scroll;

            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }

        @include rmin($desktop) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &__item {
        min-width: 200px;
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        gap: 7px;
        height: 100%;

        background-color: var(--white);
        border-radius: 50px;

        svg {
            min-width: 15px;
            min-height: 15px;
            width: 15px;
            height: 15px;
            fill: var(--gray-400);
        }
        span {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
        }

        &:hover {
            color: var(--orange-2);
        }

        @include rmin($desktop) {
            padding: 10px 35px;
            gap: 10px;

            svg {
                width: 20px;
                height: 20px;
            }
            span {
                font-size: 18px;
            }
        }
    }
}
