.reviews {
    &__form {
        span {
            display: block;

            margin-bottom: 5px;

            font-size: 14px;
            font-weight: 300;
            color: #000000;
        }

        input {
            border: $border;
            width: 100%;
            height: 35px;

            padding: 5px 10px;
            margin-bottom: 10px;

            border-radius: 10px;

            font-size: 15px;

            &:hover,
            &:focus {
                border-color: var(--orange);
            }
        }

        button {
            border-radius: 10px;
            margin-top: 10px;
            margin-bottom: 15px;
            width: 100%;
            height: 35px;

            text-transform: unset;
            font-size: 16px;

            &::after {
                display: none;
            }
        }
    }

    &__list {
    }

    &__item {
        padding-bottom: 10px;

        &:not(:last-child) {
            border-bottom: $border;
        }

        p {
            font-size: 18px;
            font-weight: 500;
        }

        &:first-child {
            margin-top: 10px;
        }
    }

    &__text {
        margin: 7px 0 !important;
        font-size: 18px !important;
    }
    
    &__top-wrapper p {
        margin-bottom: unset !important;
    }

    &__top-wrapper &__text {
        font-weight: 300 !important;
    }

    &__bottom-wrapper {
        display: flex;

        time {
            margin-right: 5px;
        }
    }
}

@include rmin($desktop) {
    .reviews {
        &__inputs-wrapper {
            display: flex;

            margin-top: 10px;

            label {
                &:first-child {
                    width: 30%;
                    margin-right: 20px;
                }

                &:last-child {
                    flex: 1;
                }
            }
        }

        &__item {
          position: relative;
          padding-top: 15px;
        }

        &__bottom-wrapper {
          position: absolute;
          top: 15px;
          right: 0;
        }

        &__form {
            .btn-details {
                width: 30%;
                padding: unset;
            }
        }
    }
}
