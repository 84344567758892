.icon-T-shirt {
  width: 1.12em;
  height: 1em;
}
.icon-accessories_beysbolki {
  width: 1em;
  height: 1em;
}
.icon-accessories_fitness {
  width: 1em;
  height: 1em;
}
.icon-accessories_myachi {
  width: 1em;
  height: 1em;
}
.icon-accessories_noski {
  width: 1em;
  height: 1em;
}
.icon-accessories_perchatki {
  width: 1em;
  height: 1em;
}
.icon-accessories_plavanie {
  width: 1em;
  height: 1em;
}
.icon-accessories_poyas {
  width: 1em;
  height: 1em;
}
.icon-accessories_rukzaki {
  width: 1em;
  height: 1em;
}
.icon-accessories_shapki {
  width: 1em;
  height: 1em;
}
.icon-accessories_sharfi {
  width: 1em;
  height: 1em;
}
.icon-accessories_sumki {
  width: 1em;
  height: 1em;
}
.icon-accessories_uhod {
  width: 1em;
  height: 1em;
}
.icon-adidas {
  width: 3.09em;
  height: 1em;
}
.icon-adidas1 {
  width: 1.47em;
  height: 1em;
}
.icon-arrow-back {
  width: 1em;
  height: 1em;
}
.icon-arrow-bottom {
  width: 1em;
  height: 1em;
}
.icon-arrow-gray {
  width: 1em;
  height: 1em;
}
.icon-arrow {
  width: 1em;
  height: 1em;
}
.icon-asics {
  width: 3.09em;
  height: 1em;
}
.icon-best_price {
  width: 4.09em;
  height: 1em;
}
.icon-call {
  width: 1em;
  height: 1em;
}
.icon-cap {
  width: 1.36em;
  height: 1em;
}
.icon-cash-v2 {
  width: 0.96em;
  height: 1em;
}
.icon-cash {
  width: 1em;
  height: 1em;
}
.icon-close-2 {
  width: 1em;
  height: 1em;
}
.icon-close-gray {
  width: 1.06em;
  height: 1em;
}
.icon-close-popup {
  width: 1em;
  height: 1em;
}
.icon-close {
  width: 1em;
  height: 1em;
}
.icon-copy-promo {
  width: 1em;
  height: 1em;
}
.icon-cross {
  width: 1em;
  height: 1em;
}
.icon-current-color {
  width: 1em;
  height: 1em;
}
.icon-deliv {
  width: 1em;
  height: 1em;
}
.icon-delivery {
  width: 1em;
  height: 1em;
}
.icon-done {
  width: 0.97em;
  height: 1em;
}
.icon-envelope {
  width: 1.03em;
  height: 1em;
}
.icon-exclamation {
  width: 1em;
  height: 1em;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
}
.icon-faq-all {
  width: 1em;
  height: 1em;
}
.icon-faq-deliv {
  width: 1em;
  height: 1em;
}
.icon-faq-gift {
  width: 1em;
  height: 1em;
}
.icon-faq-pay {
  width: 1em;
  height: 1em;
}
.icon-faq-questions {
  width: 1em;
  height: 1em;
}
.icon-faq-ref {
  width: 1em;
  height: 1em;
}
.icon-faq-tab-1 {
  width: 1em;
  height: 1em;
}
.icon-faq-tab-bonys {
  width: 1.43em;
  height: 1em;
}
.icon-faq-tab-cart {
  width: 1.4em;
  height: 1em;
}
.icon-faq-tab-deliv {
  width: 1.4em;
  height: 1em;
}
.icon-faq-tab-q {
  width: 1em;
  height: 1em;
}
.icon-faq-tab-res {
  width: 1em;
  height: 1em;
}
.icon-faq {
  width: 1.03em;
  height: 1em;
}
.icon-favorite-outline {
  width: 1em;
  height: 1em;
}
.icon-favorite {
  width: 1em;
  height: 1em;
}
.icon-fb {
  width: 1em;
  height: 1em;
}
.icon-fc-brands {
  width: 1em;
  height: 1em;
}
.icon-fc-delivery {
  width: 1.47em;
  height: 1em;
}
.icon-fc-loyalty {
  width: 1em;
  height: 1em;
}
.icon-fc-news {
  width: 1em;
  height: 1em;
}
.icon-fc-phone {
  width: 1em;
  height: 1em;
}
.icon-fc-return {
  width: 1em;
  height: 1em;
}
.icon-fc-shops {
  width: 1em;
  height: 1em;
}
.icon-fc-warranty {
  width: 1em;
  height: 1em;
}
.icon-filter-all {
  width: 1em;
  height: 1em;
}
.icon-filter-sort {
  width: 1em;
  height: 1em;
}
.icon-free-delivery {
  width: 1em;
  height: 1em;
}
.icon-geo {
  width: 1em;
  height: 1em;
}
.icon-heart-2 {
  width: 1em;
  height: 1em;
}
.icon-hoodie {
  width: 1em;
  height: 1em;
}
.icon-hot {
  width: 3.69em;
  height: 1em;
}
.icon-info {
  width: 1em;
  height: 1em;
}
.icon-instagram {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_botinki {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_butsi {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_krossovki {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_plyajnaya {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_polubotinki {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_sandali {
  width: 1em;
  height: 1em;
}
.icon-kids_obuv_sapogi {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_bruki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_futbolki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_kostum {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_kupalniki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_kurtki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_legginsi {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_mayki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_plavki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_shorti {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_thermobelie {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_tolstovki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_vetrovki {
  width: 1em;
  height: 1em;
}
.icon-kids_odejda_zhiletky {
  width: 1em;
  height: 1em;
}
.icon-left-arrow {
  width: 0.96em;
  height: 1em;
}
.icon-like {
  width: 1.13em;
  height: 1em;
}
.icon-load-more {
  width: 1em;
  height: 1em;
}
.icon-logout {
  width: 1.05em;
  height: 1em;
}
.icon-mastercard {
  width: 1em;
  height: 1em;
}
.icon-men_bruki {
  width: 0.57em;
  height: 1em;
}
.icon-men_futbolky {
  width: 0.94em;
  height: 1em;
}
.icon-men_kostum {
  width: 0.9em;
  height: 1em;
}
.icon-men_kurtki {
  width: 0.97em;
  height: 1em;
}
.icon-men_mayki {
  width: 0.65em;
  height: 1em;
}
.icon-men_obuv_botinki {
  width: 1em;
  height: 1em;
}
.icon-men_obuv_butsi {
  width: 1em;
  height: 1em;
}
.icon-men_obuv_krossovki {
  width: 1em;
  height: 1em;
}
.icon-men_obuv_plyajnaya {
  width: 1em;
  height: 1em;
}
.icon-men_obuv_polubotinki {
  width: 1em;
  height: 1em;
}
.icon-men_obuv_sandali {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_bruki {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_futbolki {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_kostum {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_kurtk {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_mayki {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_nijnee {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_plavki {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_rubashki {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_shorts {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_taitsy {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_thermobelie {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_tolstovky {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_vetrovky {
  width: 1em;
  height: 1em;
}
.icon-men_odejda_zhiletky {
  width: 1em;
  height: 1em;
}
.icon-men_plavki {
  width: 1.16em;
  height: 1em;
}
.icon-men_rubashki {
  width: 1em;
  height: 1em;
}
.icon-men_shorts {
  width: 1em;
  height: 1em;
}
.icon-men_taitsy {
  width: 0.61em;
  height: 1em;
}
.icon-men_thermobelie {
  width: 0.85em;
  height: 1em;
}
.icon-men_tolstovky {
  width: 1em;
  height: 1em;
}
.icon-men_vetrovky {
  width: 0.94em;
  height: 1em;
}
.icon-men_zhiletky {
  width: 0.62em;
  height: 1em;
}
.icon-menu {
  width: 1em;
  height: 1em;
}
.icon-new-email {
  width: 1em;
  height: 1em;
}
.icon-new {
  width: 2.65em;
  height: 1em;
}
.icon-nike {
  width: 1.4em;
  height: 1em;
}
.icon-other-email {
  width: 0.96em;
  height: 1em;
}
.icon-pen {
  width: 0.96em;
  height: 1em;
}
.icon-phone {
  width: 1em;
  height: 1em;
}
.icon-pin {
  width: 0.88em;
  height: 1em;
}
.icon-place {
  width: 1em;
  height: 1em;
}
.icon-product-box {
  width: 1em;
  height: 1em;
}
.icon-radder-logo {
  width: 4.13em;
  height: 1em;
}
.icon-radder {
  width: 4.13em;
  height: 1em;
}
.icon-reebok {
  width: 1.4em;
  height: 1em;
}
.icon-retweet {
  width: 0.94em;
  height: 1em;
}
.icon-right-arrow-b {
  width: 1em;
  height: 1em;
}
.icon-right-arrow-line {
  width: 0.96em;
  height: 1em;
}
.icon-right-arrow {
  width: 1em;
  height: 1em;
}
.icon-ruler {
  width: 1em;
  height: 1em;
}
.icon-sale {
  width: 2.17em;
  height: 1em;
}
.icon-sc-pattern {
  width: 1.07em;
  height: 1em;
}
.icon-search {
  width: 0.92em;
  height: 1em;
}
.icon-security {
  width: 1em;
  height: 1em;
}
.icon-shop-2 {
  width: 1em;
  height: 1em;
}
.icon-shop-empty {
  width: 1em;
  height: 1em;
}
.icon-shop {
  width: 1em;
  height: 1em;
}
.icon-shopping-cart {
  width: 1em;
  height: 1em;
}
.icon-sneakers {
  width: 0.97em;
  height: 1em;
}
.icon-stock {
  width: 0.96em;
  height: 1em;
}
.icon-telegram {
  width: 1em;
  height: 1em;
}
.icon-tg-2 {
  width: 1em;
  height: 1em;
}
.icon-tg {
  width: 1em;
  height: 1em;
}
.icon-time {
  width: 1em;
  height: 1em;
}
.icon-trash {
  width: 1em;
  height: 1em;
}
.icon-user {
  width: 1em;
  height: 1em;
}
.icon-viber {
  width: 1em;
  height: 1em;
}
.icon-visa-card {
  width: 0.96em;
  height: 1em;
}
.icon-visa {
  width: 1em;
  height: 1em;
}
.icon-why-we-1 {
  width: 0.99em;
  height: 1em;
}
.icon-why-we-2 {
  width: 0.93em;
  height: 1em;
}
.icon-why-we-3 {
  width: 0.74em;
  height: 1em;
}
.icon-woman_obuv_botinki {
  width: 1em;
  height: 1em;
}
.icon-woman_obuv_krossovki {
  width: 1em;
  height: 1em;
}
.icon-woman_obuv_plyajnaya {
  width: 1em;
  height: 1em;
}
.icon-woman_obuv_polubotinki {
  width: 1em;
  height: 1em;
}
.icon-woman_obuv_sandali {
  width: 1em;
  height: 1em;
}
.icon-woman_obuv_sapogi {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_bra {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_bruki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_futbolki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_kostumi {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_kupalniki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_kurtki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_legginsi {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_mayki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_platya {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_rubashki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_shorti {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_termo {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_tolstovki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_vetrovki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_yubki {
  width: 1em;
  height: 1em;
}
.icon-woman_odejda_zhiletky {
  width: 1em;
  height: 1em;
}
