.btn-approve--modal {}

.popup-cart {
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 15px;

        transition: background-color 0.3s ease-in;
        content: paint;
        pointer-events: none;
    }

    &__backdrop--triggered {
        background-color: rgba(#000, 0.3);
        pointer-events: auto;
    }

    &__backdrop--triggered &__card {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    &__card {
        --padding: 15px;
        --color: #a6d92b;
        width: 500px;
        // max-width: 400px;

        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        transform: translate3d(0, 100vh, 0);
        opacity: 0;
        transition: transform 0.5s ease-in-out, opacity 0.3s ease-in;
        will-change: transform, opacity;

        // common
        &__logo-link,
        &__main-content,
        &__footer-link {
            display: block;

            color: #F9BC18;
            text-transform: uppercase;
            font-weight: 700;
            padding: var(--padding);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        position: relative;
        min-height: 250px;
    }

    &__close-btn {
        position: absolute;
        top: 25px;
        right: -15px;

        padding: 20px;

        transform: translate(-50%, -50%);
        border: none;
        background-color: unset;
        cursor: pointer;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;

            width: 20px;
            height: 2px;
            border-radius: 1px;

            background-color: #000;
            transform: translate(-50%, -50%);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__header {
        position: relative;
    }

    &__title {
        margin-top: 0;

        .category__title {
            h1 {
                margin-bottom: 0 !important;
                padding: 25px 15px 0;
                text-align: center;

                &::after {
                    width: 150px;
                    margin-left: -75px;
                }
            }
        }
    }

    &__footer {
        margin-top: 10px;
        margin-bottom: 25px;
    }

    &__btn {
        &:hover {
            animation: pulsate-bck 0.7s ease-in-out infinite both;

            @keyframes pulsate-bck {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(0.95);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
    }
}
