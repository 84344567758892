.table {
  margin: 20px auto;
  width: auto;
  
  tbody {
    padding: 0 25px;
  }

  tr {
    width: 100%;
  }

  td {
    display: table-cell;
    vertical-align: middle;
    white-space: normal;
    padding: 5px;
    
    font-size: 0.75em;
    border: 1px solid rgba(0,0,0,0.1);

    a {
      font-weight: 500;
      color: #006bd8;
    }

    a:visited {
      color: #85c;
    }


    .gray {
      display: none;
    }
  }

  .order-status {
    padding: 2px 5px;
    color: #fff;
  }
}

@include rmin($tablet) {
  .table {
    td {
      padding: 10px;

      .gray {
        display: inline;
      }
    }
  }
}
