.for-customers {
    &__list {
        display: flex;
        flex-wrap: wrap;

        margin-top: 20px;
    }

    &__list .tabs__item--current a {
        background-color: var(--white);
        color: var(--black);
    }

    &__item {
        margin: 5px 0;
        width: 33% !important;

        span {
            font-size: 14px;
        }
    }

    &__shop-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        font-weight: 200;
    }

    &__btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding: 5px 0 !important;
        border-bottom: unset !important;

        text-align: center;

        &:hover {
            color: var(--black);
        }
    }

    &__item-mob {
        &:hover span {
            color: #000 !important;
        }
    }

    &__link--red {
        color: var(--orange-2) !important;
    }

    &__title {
        position: absolute;
        top: 250px;
        width: 100%;
        margin-top: 20px;
    }

    .tabs__content {
        p a {
            // color: #2494bc;
            font-weight: 500;
        }

        ul:not([class]),
        ol:not([class]) {
            margin-left: 5px;
            margin-bottom: 10px;

            li {
                margin: 7.5px 0;

                &::before {
                    margin-right: 5px;
                }
            }
        }

        ul:not([class]) li::before {
            // content: "• ";

            color: var(--orange-2);
            font-size: 1.4em;
        }

        ol:not([class]) {
            counter-reset: section;
        }

        ol:not([class]) li {
            counter-increment: section;

            &::before {
                content: counters(section, ".") ") ";
                color: var(--orange-2);
            }
        }
    }

    &__shop-item:not(:last-child) {
        margin-bottom: 10px;
        border-bottom: $border-orange;
    }

    &__shop-text-wrapper {
        text-align: center;
    }

    &__shop-text-wrapper h3 {
        color: #000 !important;
    }

    &__shop-text-wrapper p {
        margin: 0 !important;
    }

    &__shop-text-wrapper a {
        margin: 5px 0 !important;
    }

    &__shop-text-wrapper strong {
        display: block;
        font-weight: 300;
        margin-top: 5px;
    }

    &__shop-text-wrapper .cards__favorite-icon {
        margin-right: 5px;
        fill: var(--orange-2);
    }

    &__shop-img-wrapper {
        display: flex;
        margin: 20px 0;

        position: relative;
        width: 100%;
        padding-top: 56.25%;
        // height: 0;
        // --aspect-ratio: 16/9;
        // padding-bottom: calc(100%/(var(--aspect-ratio)));
    }

    &__shop-img-wrapper img {
        border-radius: 15px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &__shop-address {
        display: flex;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    &__shop-address address {
        font-style: normal;
    }

    &__shop-address a span {
        font-weight: 700;
        color: var(--orange-2) !important;
        border-bottom: 1px dashed var(--orange-2);
        font-size: 14px;
    }

    &__icon {
        width: 21px;
        height: 25px;
        margin-right: 15px;

        fill: var(--orange-2);
    }

    &__bonus-img {
        img {
            border-radius: 25px;
        }
    }

    &__bonus-title {
        margin-top: 25px;
        margin-bottom: 15px;
        padding: 0 25px !important;

        font-size: 18px;
        font-weight: 500 !important;

        @include rmin($desktop) {
            margin-top: unset;
            font-size: 26px;
        }
    }

    &__btn-bonus {
        padding: 7px !important;
        min-height: 45px;
        
        font-size: 16px;
        color: #fff !important;

        @include rmin($desktop) {
            width: 100%;
            min-height: 55px;
            font-size: 20px;
        }
    }

    &__btn-bonus::after {
        content: none;
    }

    .tabs__content {
        margin-top: 35px;
    }

    .tabs__item {
        padding: unset;
    }

    .tabs__title {
        margin-bottom: 35px;
        text-align: center;
    }

    .tabs__content,
    .tabs__sub-content {
        h3 {
            margin-bottom: 15px;
            // color: var(--orange-300);

            svg {
                margin-left: 3px;
                margin-bottom: -3px;
                width: 25px;
                height: 25px;

                fill: currentColor;
            }
        }

        p {
            margin: 15px 0;
        }
    }

    &__loyalty-tabs {
        width: 100%;
        grid-template-columns: 1fr;
        margin: 35px 0;
    }

    &__loyalty-tab {
        justify-content: center;
        padding: 10px 15px;
        min-height: 50px;
        margin: unset;
        border-radius: 9px;
    }

    &__loyalty-tabs .tabs__sub-item {
        width: 100%;
        // max-width: 350px;
        // margin-right: unset !important;
        // padding: 5px 15px;
    }

    &__loyalty-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__loyalty-item {
        label {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 10px;
            padding: 5px 10px;
            width: 100%;
            border-radius: 9px;
            color: var(--white);
            background: var(--black);

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__loyalty-text {
        margin: 0 !important;
        font-weight: 300;
        font-size: 15px;
        letter-spacing: 0.65px;

        &--bold {
            font-weight: 500;
        }

        @include rmin($desktop) {
            font-size: 16px;
        }
    }

    &__loyalty-ico {
        margin-right: 12px;
        fill: var(--orange-2);
    }

    &__loyalty-rules {
        margin-top: 25px;
    }

    &__loyalty-rules .for-customers__loyalty-text {
        margin: 15px 0 !important;
    }

    &__loyalty-conditions {
        margin: 0 auto;
        max-width: 750px;
    }

    &__loyalty-conditions-title {
        font-size: 16px;
    }

    &__loyalty-conditions-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__loyalty-conditions-item {
        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 0 !important;
            margin-top: 15px !important;

            li {
                font-weight: 300;
                line-height: 26px;
                letter-spacing: 1px;
                margin: 0 !important;
            }
        }

        .tabs-filter__link {
            font-weight: 500;
        }
    }

    &__item-mob {
        width: 100% !important;
        background-color: var(--white-200);
    }

    &__item:hover {
        svg {
            fill: var(--orange);
        }
    }

    &__item-mob .for-customers__btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px !important;

        color: var(--gray);
        border-radius: 5px;

        &:hover {
            color: var(--orange-2);

            .for-customers__btn {
                background-color: var(--white-100);
                border-radius: 5px;
                color: var(--orange-2);
            }

            svg {
                fill: var(--orange);
            }
        }
    }

    & .tabs__icon {
        width: 27px !important;
        height: 27px !important;
    }

    &__item-mob .tabs__icon {
        width: 20px !important;
        height: 20px !important;
        margin-right: 5px;
        fill: var(--light-gray);
    }

    #map {
        height: 320px;
        margin: 0 -10px;
    }

    .cards__favorite-select__wrapper,
    .product__shop-cart {
        display: none;
    }

    .cards__favorite-item {
        margin-bottom: 0 !important;
    }

    .cards__favorite-info {
        margin-top: 5px;
        font-size: 13px !important;
    }

    .tabs__sub-title {
        margin-top: 25px;
        font-size: 18px;
    }

    .cards__favorite-title {
        margin-bottom: 0 !important;
    }

    .cards__favorite-price {
        font-size: 17px;
    }

    // .tabs__sub-item {
    //     margin: 0 15px 0 0;
    // }
}

.tabs {
    // &__customers-link {
    //     color: var(--gray-2) !important;
    //     font-size: 16px;
    //     font-weight: 500;
    // }

    // &__customers-link:after {
    //     content: none;
    // }

    &__customers-sub {
        margin-top: 15px;
    }

    &__customers-sub__content {
        margin: 25px 0 35px;
    }

    &__customers-title {
        margin-top: 25px;

        font-size: 16px;

        &--red {
            color: var(--orange-2);
        }
    }

    &__customers-text {
        font-weight: 300;
        font-size: 16px;
    }

    &__customers-added {
        margin-bottom: 35px;
        font-weight: bold;
    }

    &--current {
        color: var(--black);
    }

    &__content--current {
        margin-bottom: 50px !important;
    }

    details > summary {
        padding: 5px;
        background-color: #eee;
        color: #333;
        border: 1px #ccc solid;
        cursor: pointer;
        list-style: none;
    }
}

@include rmin($desktop) {
    .for-customers {
        position: relative;
        margin: 0;

        &__breadcrumbs {
            margin-left: 32px;
        }

        // tabs
        .tabs__sub-list {
            justify-content: unset !important;
        }

        &__tab-title .tabs__title {
            margin-top: 30px;
            padding: 25px 0 25px 32px;

            text-align: left;
            background: var(--white-200);
        }

        &__tab-title .tabs__title:after {
            display: none;
        }

        &__tab-title .tabs__title h1 {
            margin: 0;
            font-size: 35px !important;
        }

        &__tab-title .tabs__title {
            font-size: 35px !important;
        }

        .tabs__content,
        .tabs__sub-content {
            & p {
                margin: 15px 0 25px;
            }
        }

        .tabs__title {
            position: relative;
            margin-bottom: 60px;
            width: 100%;

            h2,
            h1 {
                padding: unset;
                margin-top: 0;
                font-size: 24px;
            }

            h1 {
                font-size: 45px;
            }

            &::after {
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translate(-50%, -50%);

                content: "";
                width: 85px;
                height: 4px;
                background-color: var(--orange);
            }
        }

        .tabs__sub-wrapper {
            padding: 0;
        }

        h4 {
            font-size: 16px;
        }

        .tabs__wrapper {
            display: flex;
            flex-direction: column;
        }

        .tabs__content {
            flex: 1;
            padding-left: 0;
        }

        .tabs__sub-content h3 {
            margin-bottom: 25px;
        }

        &__tabs {
            min-width: 300px;
            // margin-top: 120px;
        }

        &__title {
            position: unset;
        }

        &__shop-container {
            padding: 0 5px;
            align-items: flex-start;

            font-weight: 200;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            align-items: baseline;
            gap: 10px;
            margin-top: unset;

            .tabs__item a {
                background-color: var(--white-200);
                color: var(--black);
                border-radius: 9px;
            }

            .tabs__item--current,
            .tabs__item:hover {
                a {
                    background-color: var(--black-200);
                    color: var(--white);
                }

                .tabs__icon {
                    fill: var(--white) !important;
                }
            }
        }

        &__item {
            margin: 0;
            width: 100% !important;
        }

        &__item span {
            font-size: 14px;
        }

        &__item:first-child {
            margin-top: unset;
        }

        &__btn {
            gap: 5px;
            padding: 10px !important;
            margin: unset;

            .tabs__icon {
                fill: var(--black);
            }
        }

        &__shop-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: unset;
        }

        &__shop-text-wrapper {
            align-items: unset;
            justify-content: center;

            margin-top: 25px;
            margin-bottom: 54px !important;
            font-size: 20px;
            text-align: left;
        }

        &__shop-text-wrapper .cards__favorite-icon {
            margin-top: unset;
        }

        &__shop-address {
            text-align: left;
            width: unset;
            justify-content: flex-start;
        }

        &__shop-address div {
            max-width: 330px;
        }

        &__shop-address a span {
            margin-left: 5px;
            padding-bottom: 3.5px;
        }

        &__shop-img-wrapper {
            width: 55%;
            padding-top: 36.25%;
        }

        &__loyalty-tabs {
            grid-template-columns: repeat(3, 1fr);
            margin-top: 25px;
            margin-bottom: 35px;
        }

        &__loyalty-item {
            label {
                gap: 10px;
                margin-bottom: 13px;
                padding: 7px 10px;
                font-size: 18px;
                border-radius: 12px;

                span {
                    margin-top: 2px;
                }
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        &__loyalty-text {
            font-size: 18px;
        }

        &__loyalty-conditions-title {
            font-size: 20px;
        }

        &__loyalty-conditions-list {
            gap: 35px;
            margin-top: 35px;
        }

        &__loyalty-conditions-item {
            font-size: 20px;

            ul {
                margin-top: 35px !important;
            }
        }

        &__bonus-wrapper {
            display: flex;
            gap: 74px;
            margin-bottom: 25px;
            padding: 25px 0;
            padding-left: 155px;
            background: var(--white-200);
        }

        &__bonus-wrapper ~ .tabs__sub-wrapper {
            max-width: 800px;
            margin: 20px auto 0;
        }

        &__bonus-img {
            width: 377px;
        }

        &__bonus-text {
            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            width: 450px;
        }

        &__btn-bonus {
            max-width: 356px;
        }

        #map {
            height: 450px;
        }

        &__item-mob {
            background: none !important;
        }

        &__item-mob .for-customers__btn {
            justify-content: flex-start;
            padding: 0 !important;
            color: var(--gray-2);
        }

        &__item-mob .tabs__icon {
            margin-right: 0;
            width: 35px !important;
            height: 35px !important;
        }

        .tabs__sub-title {
            font-size: 24px;
        }

        .cards__favorite-price {
            font-size: 30px;
        }

        .cards__favorite-info {
            font-size: 20px !important;
        }
    }

    .tabs {
        &__content--current {
            // margin-top: 100px !important;
            margin-bottom: 75px !important;
        }

        &__customers-sub__content {
            margin: 30px 0 0;
        }

        &__customers-title {
            font-size: 18px;
        }

        &__customers-text {
            font-weight: 300;
            font-size: 18px;
        }
    }
}
