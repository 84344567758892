.buy-with {
    margin-top: 20px;

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        margin-top: 5px;
        // margin-bottom: 5px;
        width: 33.3333%;
    }

    .tabs {
        &__content {
            display: none;
            margin-top: 25px;
        }

        &__content--current {
            display: block;
        }
    }

    .tabs__list {
        display: block;
    }

    .tabs__item {
        text-align: center;

        button {
            font-size: 20px;
            padding-left: unset;
            padding-right: unset;
        }

        &:first-child {
            margin-bottom: 10px;
        }
    }
}

@include rmin($desktop) {
    .buy-with {
        .tabs__list {
            display: flex;
        }

        .tabs__item {
            padding: 0 15px;
        }

        &__list {
            justify-content: center;
        }

        &__item {
            width: 22.5%;
        }
    }
}
