.recently-watched {
    p:first-child {
        // padding: 0 15px;
        margin-top: 30px;
        margin-bottom: 10px;
        padding: 0 35px;
        text-align: center;
        font-weight: 500;
        font-size: 25px;

        @include rmin($desktop) {
            font-size: 35px;
            margin-top: 50px;
            margin-bottom: 20px;
        }
    }

    .cards__available-sizes-wrapper p,
    .cards__brand-name {
        margin-top: unset !important;
    }

    .cards__item {
        flex-basis: unset;
        max-width: unset;
        margin: unset;
    }

    .cards__image-wrapper {
        padding: 0 5px;
        position: static;

        img {
            height: 135px;
        }
    }

    .cards__info-wrapper {
        p {
            text-align: center;
            margin-bottom: 0;
        }

        h3 {
            display: none;
        }
    }

    .glide__arrow--right {
        right: -1.75em !important;
    }

    .glide__arrow--left {
        left: -1.75em !important;
    }

    .brands-slider__wrapper {
        width: 90%;
        margin: auto;
    }
}

@include rmin($sm-phone) {
    .recently-watched {
        .cards__image-wrapper {
            img {
                height: 165px;
            }
        }
    }
}

@include rmin($tablet) {
    .recently-watched {
        .cards__image-wrapper {
            position: relative;

            img {
                height: 210px;
            }
        }
    }
}

@include rmin($desktop) {
    .recently-watched {
        .cards__item {
            flex-basis: unset;
            max-width: unset;
            margin: unset;
        }

        .cards__info-wrapper {
            p {
                text-align: center;
                margin-bottom: 0;
            }

            h3 {
                display: block;
            }
        }

        .glide__arrow--right {
            right: -3em !important;
        }

        .glide__arrow--left {
            left: -3em !important;
        }

        .cards__image-wrapper {
            img {
                height: 250px;
            }
        }
    }

    .recently-watched.tabs__content--current {
        margin-top: 25px !important;
        margin-bottom: 50px !important;
    }
}
