@mixin common-styles {

    &__navigation,
    &>ul {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__list,
    &>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item,
    &>ul>li {
        &:not(:last-child) {
            &::after {
                content: '-';
                margin: 0 3px;
            }
        }
    }

    &__link,
    &>ul>li>a {
        color: #000;
        font-size: 16px;
        text-decoration: none;
        font-weight: 300;

        &:hover {
            color: var(--orange);
        }
    }
}


.breadcrumbs,
main,
.static-page__wrapper {

    &__navigation,
    &>ul {
        display: none;
    }

    &>ul+h1 {
        text-align: center;
    }
}

.static-page__wrapper>ul+h1 {
    text-align: left;
}

.for-customers-block .breadcrumbs,
main,
.static-page__wrapper {
    @include common-styles;
}

@include rmin($tablet) {

    .breadcrumbs,
    main,
    .static-page__wrapper {
        @include common-styles;

        .static-page__wrapper {
            &>ul {
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: unset;
            }
        }
    }

}