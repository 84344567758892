#wa-my-info-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px;
  
  .wa-form {
    margin: 10px 0;
  }

  .wa-field {
    margin: 0;
    padding-top: 3px;
  }


  .wa-form .wa-field .wa-value p {
    margin-bottom: 20px;
  }

  .wa-name {
    padding-top: 0.05em;
    //// padding-bottom: 10px;
    font-size: 0.95em;
    color: #888;
  }
  .wa-value {
    //// margin-left: 180px;
    margin-bottom: 5px;
    position: relative;

    a {
      text-decoration: underline;
      color: var(--orange);
    }
  }

  .field > span:first-of-type {
    display: block;
  }

  .field > span:first-of-type,
  .wa-field-address-shipping .field input,
  .wa-field-address-shipping .field select {
    margin-bottom: 5px;
  }

  .wa-form > .wa-field > .wa-value:first-child {
    display: flex !important;
    align-items: center;

    input[type="submit"] {
      width: auto !important;
      margin-right: 10px;
    }
  }
}

@include rmin($tablet) {
  #wa-my-info-wrapper {
    .wa-form .wa-field {
      display: flex;
      // width: 155px;

      .wa-name {
        min-width: 135px;
        margin-right: 20px;
      }
    }
    .wa-form .wa-field input[type="button"] {
      margin-top: 10px;
      margin-left: 155px;
    }
  }
}
