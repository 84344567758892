.offer-page {
  &__content {
  }

  &__offer-item {
    margin-bottom: 30px;

    text-align: center;
  }
  &__offer-number {
    position: relative;

    display: inline-block;
    padding: 0 15px;
    // margin-bottom: 10px;

    font-size: 18px;
    font-weight: 700;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;

      width: 7px;
      height: 2px;

      line-height: 24px;
      background-color: #000;
      // transform: translateY(-50%);
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  &__offer-title {
    margin: 10px 0 15px;

    font-size: 18px;
    font-weight: 700;

    &::before,
    &::after {
      content: '';

      --vertical-margin: 10px;

      display: block;
      width: 300px;
      max-width: 20%;
      height: 1px;
      margin: 0 auto;

      background-color: #808080;
    }
    &::before {
      margin-bottom: var(--vertical-margin);
    }
    &::after {
      margin-top: var(--vertical-margin);
    }
  }
  
  &__photos-wrapper {
  }
  &__photos-list {
    // display: flex;
    // justify-content: space-around;
  }
  &__photos-item {
    // max-width: 40%;
    margin-bottom: 10px;
  }
  &__photos-img {
  }

  &__slider-title {
    margin-bottom: 15px;
    
    font-size: 18px;
    color: #000;
  }
}

@include rmin($tablet) {
  .offer-page {

    &__photos-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    &__photos-item {
      margin-bottom: 15px;
      max-width: 40%;
    }
  }
}