.search-results {
    &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__amount {
        margin: 5px auto 15px;
        color: var(--gray);
        font-size: 22px;
        text-align: center;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__content {
        margin-bottom: 75px;
    }

    &__filter-name {
        font-weight: bold;
        font-size: 16px;
    }

    &__btn {
        margin-top: 10px;
    }

    &__product-cards {
        border: none !important;
    }

    &__cards {
        --card-size: 100%;
    }

    &__cards .cards__item {
        border: none !important;
    }

    &__cards .cards__sale-info {
        margin-top: 10px;
    }

    &__ico {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    &__load-more {
        font-size: 18px;
    }

    &__see-more {
        padding: 0 15px;
    }

    .cards__image-wrapper img {
        height: 285px;
        object-fit: contain;
    }
}

@include rmin($sm-phone) {
    .search-results {
        .cards__image-wrapper img {
            height: 375px;
        }
    }
}

@include rmin($tablet) {
    .search-results {
        &__amount {
            font-size: 28px;
        }

        &__cards {
            --card-size: 50%;
        }

        &__cards .cards__sale-info {
            font-size: 20px;
        }

        &__list {
            flex-direction: row;
        }

        &__btn {
            margin-top: 0;
        }
    }
}

@include rmin($desktop) {
    .search-results {
        &__wrapper {
            flex-direction: row;
            justify-content: center;
        }

        &__amount {
            margin: 25px auto 35px;
        }

        &__filter-name {
            margin-right: 21px;
        }

        &__cards {
            --card-size: 25%;
        }

        .cards__image-wrapper img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
        }
    }
}
