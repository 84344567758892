.clothes-sizes {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 0;
        gap: 10px;
    }
    &__item {
        width: calc(25% - 8px);
        margin-bottom: 0;
        // margin-bottom: 5px;

        &:not(:last-child) {
            margin-right: 0;
        }

        @include rmin($tablet) {
            width: 10.83%;
        }
        @include rmin($desktop) {
            margin-bottom: 0;
        }
    }
    &__btn {
        display: block;
        min-width: 37px;
        padding: 4px 2px;

        color: inherit;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;

        border-width: 2.5px;
        border-color: #000;
        border-radius: 9px;

        cursor: pointer;

        &:hover,
        &:focus {
            border-color: var(--orange);
            color: var(--orange);
        }

        @include rmin($tablet) {
            padding: 7px 2px;
            font-size: 16px;
        }
        @include rmin($desktop) {
        }
    }
    &__size-label {
        cursor: pointer;
    }
    input:checked + &__btn {
        border-color: var(--orange);
        color: var(--orange);
    }
    input[data-disabled] + &__btn,
    input:disabled + &__btn,
    &__size-label--disabled,
    &__item--disabled {
        border-color: var(--light-gray);
        color: var(--light-gray);
        pointer-events: none;
    }
}

@include rmin($desktop) {
    .clothes-sizes {
        &__list {
            // justify-content: unset;
        }
        &__item {
            .btn-rounded {
                padding-left: 2px;
                padding-right: 2px;
            }
        }
        &__btn {
            // font-size: 12px;
            // min-width: 32px;
            // border-width: 2px;
        }
    }
}
