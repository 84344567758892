.order-d {
    &__wrap {
        &>* {
            &:not(:first-child) {
                margin-top: 25px;
            }
        }

        h1 {
            display: flex;
            align-items: center;

            padding: unset;
            font-size: 18px;
        }

        .order-status {
            margin-top: -4px;
            font-size: 12px;
            border-radius: 5px;
        }

        .table {
            width: 100%;
            font-size: 14px;

            tbody tr th {
                color: var(--black);
                font-weight: 500;
                font-size: 16px;

                &:nth-child(1) {
                    width: 50%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    width: 25%;
                    padding-right: 10px;
                    text-align: right;
                }
            }

            td {
                font-size: inherit;
                border: unset;
                border-bottom: 0.5px solid rgba(0, 0, 0, .1);
            }

            tr {
                font-weight: 200;
            }

            .no-border {
                color: var(--black);
                font-weight: 500;
            }

            .large {
                color: var(--orange-2);
                font-weight: 700;
            }
        }
    }

    &__shipp,
    &__payment {
        &>* {
            font-size: 16px;
        }

        p {
            margin-top: 15px;
            font-weight: 500;
        }

        h3 {
            color: var(--gray);
        }

        strong {
            color: var(--black);
        }
    }

    &__shipp {}

    &__payment {
        .plugin {
            font-weight: 200;
        }
    }

    &__delivery-wrap {
        padding: 15px;
        background-color: var(--light-gray-2);

        p {
            font-weight: 500;
        }
    }

    &__delivery {
        display: flex;
        align-items: center;

        img {
            width: 18px;
        }

        span {
            margin-left: 10px;
            font-weight: 200;
        }
    }
}

@include rmin($desktop) {
    .order-d {
        &__wrap {
            h1 {
                font-size: 22px;
            }

            .order-status {
                font-size: 14px;
            }

            .table {
                font-size: 16px;

                tbody tr th {
                    font-size: 18px;

                    &:nth-child(1) {
                        width: 80%;
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        width: 10%;
                    }
                }
            }
            
            .s-order-page {
                flex-direction: column !important;
            }
        }

        &__shipp,
        &__payment {
            &>* {
                font-size: 18px;
            }

            p {
                margin-top: unset;
                text-align: right;
            }
        }

        &__shipp {
            display: flex;
            justify-content: space-between;
        }
    }
}
