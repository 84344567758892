.news {
    &__page-description {
        text-align: center;
        margin: auto;
    }

    &__list-wrapper {
        margin-top: 25px;
        margin-bottom: 50px;
    }

    &__item {
        margin-bottom: 15px;
        box-shadow: 0px 3px 20px rgba(102, 102, 102, 0.25);
    }

    &__link {
        text-decoration: none;
        color: #000;
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 15px;

        time {
            margin-bottom: 5px;
        }

        h3 {
            text-align: center;
            padding: 0 15px;
            font-size: 16px;
        }
    }

    &__img-wrapper {
        display: flex;
    }

    &__datetime {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray-500);
    }
    &__text {
        & > * {
            margin-bottom: 15px;
            color: var(--dark-gray);
        }
    }
    &__more-btn{
        max-width: 300px;
        height: 40px;
        font-size: 18px;
        letter-spacing: unset;
        border-radius: 10px !important;
    
    }
}

@include rmin($tablet) {
    .news {
        margin-top: -15px;

        &__page-description {
            width: 75%;
        }

        &__list-wrapper {
            margin-top: 45px;
        }

        &__list {
            display: flex;
            justify-content:center;
            flex-wrap: wrap;
        }

        &__item {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;

            max-width: 30%;
            flex-basis: 30%;
        }

        &__img-wrapper {
            img {
                // height: 125px;
            }
        }

        &__info-wrapper {
            h3 {
                padding: unset;
                font-size: 16px;
            }
        }
        &__more-btn{
            height: 50px;
            font-size: 20px;
        }
    }
}

@include rmin($desktop) {
    .news {
        &__list-wrapper {
            margin-top: 25px;
            margin-bottom: 85px;
        }
        &__list{
            justify-content: space-between;
        }
        &__item {
            max-width: 30%;
            flex-basis: 30%;
            margin-left: unset;
            margin-bottom: 75px;

            &:hover {
                box-shadow: 0px 3px 25px rgba(102, 102, 102, 0.35);
            }
        }

        &__item:hover &__info-wrapper h3 {
            color: var(--orange);
        }

        &__img-wrapper {
            img {
                // height: 150px;
            }
        }

        &__info-wrapper {
            h3 {
                padding: 0 15px;
                font-size: 20px;
            }
            time{
                font-size: 18px;
            }
        }
        &__more-btn{
            margin-top: -20px;            
        }
    }
}
