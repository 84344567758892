.popup,
.wa-order-dialog {
	&__wrapper {
		z-index: 1;

		position: absolute;
		top: 0;
		left: 0;

		padding: 20px 0;
		width: 100%;

		background-color: #ffffff;
		border-radius: 5px;
		box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
		transform: translateY(100px);
		opacity: 0;
		pointer-events: none;
		transition: transform 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940),
			opacity 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940);
		will-change: transform, opacity;

		&--active {
			transform: translateY(0);
			pointer-events: auto;
			opacity: 1;
		}

		&--pb-0 {
			padding-bottom: 0;
		}
	}

	.wa-header {
		margin-bottom: 10px;
	}

	.wa-name {
		font-weight: 700 !important;
	}
	.wa-price {
		color: var(--orange);
	}

	.wa-header {
		font-size: 18px !important;
	}
	.wa-dialog-content p {
	}

	.wa-signup-url a,
	.wa-login-forgotpassword-url,
	.wa-login-submit {
		font-size: 16px !important;
		
		color: var(--orange) !important;
	}
	.wa-login-submit {
		margin: 10px 0 !important;
		padding: 10px;

		background-color: var(--orange);
		color: #fff !important;
	}

.js-login-form-actions,
	.wa-signup-url {
		margin-top: 0 !important;
	}

	&__item-wrapper {
		// position: relative;
	}
	&__icon {
		position: absolute;
		top: 10px;
		right: 10px;

		width: 20px;
		height: 20px;

		fill: var(--orange);
		cursor: pointer;
	}
}
.wa-variant-wrapper .wa-variant-title,
.js-show-variant-details .wa-name {
	color: var(--orange-2) !important;
}

@include rmin($tablet) {
	.popup,
	.wa-order-dialog {
		.wa-map-section {
			display: none !important;
		}

		.wa-dialog-body.with-full-height {
			top: 50% !important;
			left: 50% !important;

			width: auto !important;
			min-width: 520px !important;

			transform: translate(-50%, -50%);
		}
		&__icon {
			// top: 25px;
			// right: 25px;
		}
	}
}
