.order-page{
    &__inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 100px auto;
        max-width: 344px;
    }
    &__title{
        margin-top: 25px;
        font-weight: bold;
        font-size: 24px;
    }
    &__text{
        display: block;
        margin-top: 25px;
        font-size: 16px;
    }
    &__btns {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }
    &__btn{
        display: inline-flex;
        align-items: center;
        justify-content: center;

        min-width: 300px;
        padding: 9px;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        border-radius: 10px;

        &:first-child{
            margin-bottom: 10px;
        }
        &--main{
            background-color: var(--black-200) !important;
            color: var(--white) !important;
        }
        &--secondary{
            color: var(--gray-500);
            background-color: var(--white-100);
        }
    }

    // 
    // &__dark {
    //     display: flex;
    //     align-items: center;
    //     gap: 10px;
    //     font-size: 14px;
    //     border: none;
    //     text-align: left;
    //     background: var(--grayish);
    //     color: var(--black);
    //     padding: 10px 15px;
    //     text-decoration: none;
    //     transition: all 0.2s ease-in;
    //     border-radius: 9px;

    //     svg {
    //         width: 22px;
    //         fill: currentColor;
    //     }

    //     &.active,
    //     &:hover {
    //         background: var(--black-200);
    //         color: var(--white);
    //     }

    //     label {
    //         display: flex;
    //         justify-content: center;
    //         width: 100%;
    //         gap: 5px;
    //     }
    //     input {
    //         display: none;
    //     }
    // }
    // 
}
.order{
    &__email-inner{
        padding: 10px;
        max-width: 700px;
        background-color: var(--white);
    }
    &__address-inner{
        display: flex;
        justify-content: space-between;
        align-items: center;        
        margin-bottom: 30px;
        padding: 0 0 25px 0;
        border-bottom: 1px solid var(--gray-300);

        & svg{
            width: 16px;
            height: 16px;
            fill: var(--gray-300);
        }

        & .wa-header{
            font-size: 16px;
            font-weight: 600;
        }
    }
    &__email-code{
        & label{
            display: block;
            font-size: 16px;
        }
        & input{
            width: 100%;
            height: 31px;
            margin-top: 3px;
            padding: 0 10px;
            font-size: 16px;
            color: var(--gray-500);
            border:1px solid var(--gray-400);
            background-color: var(--white-200);
            border-radius: 9px;
        }
    }
    &__email-present{
        display: flex;
        margin-top: 15px;


        & label{
            display: block;
            font-size: 16px;
        }
        & input{
            width: 125px;
            height: 31px;
            margin-right: 10px;
            padding: 0 10px;
            font-size: 16px;
            color: var(--gray-500);
            border:1px solid var(--gray-400);
            background-color: var(--white-200);
            border-radius: 9px;
        }
        & input:hover{
            border:1px solid var(--orange);
            background-color: var(--white);
        }
    }
    &__email-proof{
        display: inline-block;
        font-size: 16px;

        & .wa-time{
            margin-left: 5px;
            color: var(--orange);
        }
    }
    &-email__footer{
        margin-top: 30px;
        font-size: 22px;
        font-weight: bold;

        & button{
            font-size: 22px;
            font-weight: bold;
        }
    }
}


@include rmin($desktop){
    .order{
        &__email-inner{
            padding: 25px;
        }
        &__address-inner{
            & svg{
                width: 21px;
                height: 21px;
            }
            & .wa-header{
                font-size: 22px;
            }
        }
    }    
}