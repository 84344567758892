.pagination {
    &__wrap {
        margin-top: 20px;

        &--bottom {
            margin-bottom: 25px;

            @include rmin($desktop) {
                margin-bottom: 50px;
            }
        }
    }
    &__nav {
    }
    &__list {
        display: flex;
        justify-content: center;
        gap: 3px;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 10px;
        min-width: 35px;

        background-color: var(--white-200);
        color: var(--orange-2);
        border-radius: 8px;

        &:hover {
            background-color: var(--orange-2);
            color: var(--white);
        }

        &.current {
            background-color: var(--orange-2);
            color: var(--white);
        }

        &.current,
        &.dotts {
            pointer-events: none;
        }
        &.dotts {
            background-color: transparent;
        }

        .prev {
        }

        .next {
        }
    }
}

@include rmin($desktop) {
    .pagination {
        &__wrap {
            margin-top: 35px;
        }
        &__list {
            gap: 7px;
        }
        &__item {
            padding: 8px 15px;
            min-width: 40px;
        }
    }
}
