.s {
    &-layout {
        input[type="text"],
        input[type="search"],
        input[type="password"],
        input[type="email"],
        input[type="url"],
        input[type="date"],
        input[type="phone"],
        input[type="number"],
        select,
        textarea {
            width: 100%;
            padding: 10px;

            font-size: 16px;
            border: 1px solid var(--gray-500);
            border-radius: 9px;

            &::placeholder {
                font-size: 16px;
                font-weight: 500;
                color: var(--gray-400);
            }

            &:focus {
                border: 1px solid var(--orange);
            }
        }
    }

    &-layout {
        .wa-address input[type="text"],
        .wa-address input[type="search"],
        .wa-address input[type="password"],
        .wa-address input[type="email"],
        .wa-address input[type="url"],
        .wa-address input[type="date"],
        .wa-address input[type="phone"],
        .wa-address input[type="number"],
        .wa-field-address-street input[type="text"],
        .wa-field-address-city input[type="text"],
        .wa-field-address-zip input[type="text"],
        select,
        textarea {
            border: 1px solid var(--gray-500);

            &:focus {
                border: 1px solid var(--orange);
            }
        }
    }

    &-layout > &-column.middle {
        vertical-align: middle;
    }

    &-layout > &-column.right {
        text-align: right;
    }

    &-layout textarea {
        resize: none;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper {
        & h3 {
            margin-bottom: 15px;
        }

        & header {
            padding: 18px !important;
        }

        & .s-header--sm {
            padding: 1.2rem 1.8rem;
            line-height: 1.85rem;
        }

        border-radius: 10px;
        border: 1px solid var(--gray-400);
        // overflow: hidden;

        &:not(:first-child) {
            margin-top: 15px;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header {
        & h5 {
            display: flex;
            font-weight: 500;
            font-size: 18px;

            .s-number {
                width: 16px;
            }
        }

        // & .s-name--full {
        //     margin-left: 1;
        // }

        & a {
            margin-right: 0.5rem;
            margin-left: 10px;

            font-size: 18px;
            font-weight: 500;
            color: var(--orange);
        }

        & a span {
            margin-left: 1rem;
            color: var(--black) !important;
        }

        & a span span {
            margin-left: unset;
        }

        & svg {
            fill: red;
        }
    }

    &-info-content {
        max-width: 250px;
        font-weight: 300;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-content-wrapper {
        padding: 25px 15px;
    }

    ///form
    &-checkout-page &-steps-wrapper .wa-form .wa-field {
        margin-bottom: 15px;

        font-size: 16px;

        & .wa-name {
            margin-bottom: 5px;
            font-weight: 500;

            &::first-letter {
                display: inline-block;
                text-transform: capitalize;
            }
        }
    }

    &-checkout-page &-steps-wrapper .wa-form {
        & h3 {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-bottom: 25px;
            font-weight: normal;
            font-size: 20px;
            text-transform: uppercase;

            .form-subtitle {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 16px;
                color: var(--orange);
                text-transform: none;
            }
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper .wa-field.wa-field-address {
        margin-top: -10px;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper .field {
        display: inline-block;
        width: 100%;
        font-weight: 400;
        margin-bottom: 15px;

        & input {
            margin-top: 0.5rem;
        }

        & select {
            -webkit-appearance: none;
            margin-top: 5px;

            color: var(--gray-400);
            background: var(--white) url("../img/dropdown.svg") no-repeat 95%;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper .service-agreement-wrapper {
        display: inline-block;

        & a {
            color: var(--orange);
        }

        & label input {
            margin-right: 0.5rem;
        }

        .wa-value {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header h5 span {
        margin-left: 10px;
    }

    //info-block

    &-checkout-page &-steps-wrapper &-step-wrapper &-column {
        display: flex;
        flex-direction: column;
    }

    // &-checkout-page &-steps-wrapper &-step-wrapper &-column-info {
    //     margin-top: 5rem;
    // }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-content-wrapper
        &-step-content
        &-contact-form
        &-column-info
        &-info-block {
        padding: 0.5rem 2.5rem 0;
        margin-bottom: 15px;

        border-radius: 9px;
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-content-wrapper
        &-step-content
        &-contact-form
        &-column-info
        &__box {
        position: relative;
        padding: 15px;
        background: var(--gray-bg);

        img {
            width: 190px;
        }

        .s-icon {
            position: absolute;
            width: 75px;
            height: 75px;
            bottom: 0;
            right: 0;
        }
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-content-wrapper
        &-step-content
        &-contact-form
        &-column-info
        &-info-block
        &-info-header {
        display: flex;
        align-items: center;
        padding: unset !important;
        margin-bottom: 15px;

        font-size: 18px;

        & img {
            margin-right: 1rem;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header {
        display: flex;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header &-layout {
        display: flex;
        justify-content: space-between;
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-header
        &-layout
        &-column {
        margin-bottom: unset;
        svg {
            width: 25px;
            height: 25px;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header &-column {
        display: flex;
        justify-content: center;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-footer {
        padding: 10px 18px;
        background-color: var(--white-200);
        border-radius: 10px;

        .btn-fill {
            justify-content: center;
            gap: 10px;
            margin: 0 auto;
            max-width: 320px;
            font-size: 16px;
        }
        .s-footer__arr {
            width: 25px;
            height: 25px;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-submit {
        display: flex;
        align-items: center;

        padding: 1rem 3.65rem;

        font-size: 1.6rem;
        line-height: 1.3;
        text-transform: uppercase;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-submit &-footer__arr {
        width: 2rem;
        height: 1rem;

        margin-left: 1rem;
        fill: var(--white);
    }

    //is-next

    &-checkout-page &-steps-wrapper &-step-wrapper.is-next {
        font-size: 1.8rem;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper.is-next &-step-header h5 {
        color: var(--gray-500);

        & span {
            display: inline-block;
            margin-left: 10px;
        }
    }

    &-checkout-page &-steps-wrapper &-step-header h5 span,
    &-checkout-page &-steps-wrapper &-step-header h5 a {
        display: inline-block;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    ///step 3

    &-checkout-page &-shipping-name {
        margin-left: 1rem;
        font-weight: normal;
        font-size: 1.8rem;
    }
    &__ship-added &-shipping-name {
        margin-left: 3rem;
        margin-top: 1rem;
    }

    &-checkout-page &-shipping-input {
        width: 2rem;
        height: 2rem;
    }

    &-checkout-page &-rate-wrapper {
        margin-left: 3rem;

        font-size: 1.8rem;

        & .ruble {
            margin-left: 0.4rem;
        }
    }

    &-checkout-page &-shipping-image {
        margin-left: 1rem;
    }

    &-checkout-page &-shipping-description {
        font-size: 1.6rem;

        & a {
            color: black;
        }
    }

    &-checkout-page .shipping-10 {
        position: relative;

        & .s-shipping-details {
            margin-top: 5.8rem;
        }
    }

    &-checkout-page &-shipping-item:nth-of-type(2) &-rate-wrapper {
        position: absolute;
        top: 6rem;
    }

    &-shipping-methods {
        margin: 0;

        .for-customers__loyalty-tabs {
            gap: 15px;
            margin: 0;
            margin-bottom: 35px;
        }

        .for-customers__loyalty-tab,
        &-tab,
        .s-payment-tab {
            font-size: 18px;
            min-height: 45px !important;
            border-radius: 9px;
            text-align: center;

            svg {
                width: 25px;
                height: 25px;
            }
        }

        &__deliv {
            margin-top: 15px;
        }

        &-tab {
            justify-content: center;
        }

        .mail .wa-name h3 {
            font-size: 20px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: 15px;
            font-weight: 300;
        }
        &-info .tooltip-v2 {
            margin-left: 7px;
        }

        &-info .tooltip-v2 {
            .tooltip-text {
                left: 50%;
                right: unset !important;
                width: 225px;
                transform: translate(-75%, 0);
            }

            .tooltip-text-v2 {
                transform: translate(-55%, 0);
            }
        }

        .form-subtitle {
            display: flex;
            align-items: center;
        }

        &-footer {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            b {
                font-weight: 500;
            }
        }

        .wa-form {
            display: block !important;
        }
    }

    //step 4
    &__hidden {
        display: none !important;
    }

    &-checkout-page &-ship__wrapper {
        margin-bottom: 3.5rem;
    }

    &-rate__sec {
        position: unset !important;

        & .s-delivery-wrapper {
            margin-top: 1rem;
            font-size: 1.6rem;
        }

        & .s-delivery {
            display: block;
            margin-top: 0.3rem;
        }
    }

    &-ship__sec {
        margin-top: unset !important;
        margin-left: 3.2rem;

        & .s-shipping-description {
            margin-bottom: 1.5rem;
        }
    }

    &-checkout-page &-payment-options &-payment-item {
        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }

        & span {
            display: block;
            width: 100%;

            margin-top: 1rem;
            margin-left: 3.5rem;

            font-weight: normal;
            font-size: 2.2rem;
        }

        & .s-payment-input {
            width: 2rem;
            height: 2rem;
        }

        & .s-payment-description {
            margin-left: 3.5rem;
            margin-top: -1rem;

            font-size: 1.8rem;
            color: var(--gray-500);

            & a {
                color: black;
            }
        }

        & .s-payment__img-wrap {
            width: 10rem;
            height: 3.5rem;

            margin-left: 1rem;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                border: 0.1rem solid var(--gray-400);
                border-radius: 0.5rem;
                overflow: hidden;
            }
        }
    }
    &-payment {
        margin-bottom: 25px !important;
        &-total {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            span {
                font-size: 18px;
            }
        }
        &-tab {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    //step 5
    &__final-step {
        & .s-contact-header {
            margin-bottom: 35px;

            text-transform: uppercase;
            font-size: 20px;
        }

        & .s-userpic {
            width: 3.5rem;
            height: 3.5rem;

            border-radius: 50%;
            object-fit: cover;
            object-position: top;
            overflow: hidden;
        }

        & .s-user-name {
            display: block;

            font-weight: normal;
            font-size: 18px;
        }

        & .s-address-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin: 10px 0;
            font-size: 18px;

            span {
                font-weight: 500;
            }
        }

        & thead .s-column-name,
        & thead .s-column-amount,
        & thead .s-column-total {
            display: none;
        }

        & .s__final-item {
            position: relative;
            display: inline-block;
            width: 100%;
            padding: 15px 10px;
            font-weight: 300;

            background-color: var(--white-200);
            border-radius: 5px;

            & .s-name {
                font-size: 16px;
            }

            & .s-value,
            & .s-total {
                font-size: 18px;
            }

            & .s-value .bold {
                margin-left: 1rem;
                color: var(--black);
            }

            & .s-column-name {
                display: inline-block;
                width: 100%;

                margin-bottom: 25px;
            }

            & .s-column-amount,
            & .s-column-total {
                display: inline-block;
            }

            & .s-column-amount {
                min-width: 9.2rem;
                margin-right: 2.5rem;
            }

            & .s-column-total {
                position: absolute;
                right: 1.5rem;

                width: 15rem;
                text-align: right;
            }

            & .ruble {
                font-weight: 500;
            }
        }

        .comment {
            margin-top: 5px;
            padding: 15px 20px;
            min-height: 96px !important;
        }
        & .s-total-wrapper {
            font-size: 16px;
            & tbody {
                display: flex;
                justify-content: flex-end;
            }

            & tbody tr:nth-of-type(1) {
                display: inline-block;
                margin: 10px 0;
                text-align: end;
            }

            & tbody tr:nth-of-type(2) {
                display: inline-block;
                margin-bottom: 2.5rem;
            }
        }

        & .s-total-wrapper .column-1,
        & .s-total-wrapper .column-2 {
            display: inline-block;
            font-weight: normal;
        }
        & .s-total-wrapper .column-1 {
            text-align: right;
            font-weight: 300;
            & strong {
                margin-left: 0.5rem;
                font-weight: 300;
                color: var(--black);
            }
        }

        & .s-total-wrapper .column-2 {
            width: 100%;
        }

        & .s-total-wrapper .s-total-sum {
            display: inline-block;
            margin-bottom: 2.5rem;
            font-size: 16px;
        }

        & .s-cart-table {
            margin-top: 25px;
        }

        & .s-textarea-wrapper {
            margin-bottom: 35px;
            label {
                color: var(--gray-800);
            }
        }
    }
    &-confirm-wrapper {
        max-width: 950px;
    }

    //success page
    &__check-success {
        & .s-checkout-success {
            max-width: 705px;
        }
        & .s-step-header {
            padding: 1.7rem 2rem !important;
        }

        & h2 {
            font-size: 1.6rem;
            font-weight: normal;
        }

        h4 {
            margin-top: 10px;
            font-size: 24px;
        }

        & .s-text-section {
            margin-top: 10px;
            margin-bottom: 25px;
            color: var(--gray-800);
            p {
                font-weight: 500;
                font-size: 16px;
            }
        }

        & .s-order-number {
            margin-left: 5px;
            color: var(--orange);
        }

        & .s-step-content-wrapper {
            padding: 15px !important;
        }

        & .s-success-back {
            display: inline-block;
            margin-top: 3.5rem;
            padding: 0.9rem 1rem;

            font-size: 1.6rem;
            color: currentColor;

            & span {
                margin-left: 0.5rem;
            }

            & svg {
                width: 2rem;
                height: 1rem;
            }
        }
    }

    &-login-form {
        margin-top: 15px;

        .wa-buttons-wrapper,
        .wa-login-submit {
            width: 100%;
        }
        .wa-signup-url {
            margin-top: 10px;
        }
        .wa-uncaught-errors {
            width: 100%;
            margin-top: 10px;
            margin-bottom: unset;
            padding: 5px;
            background-color: var(--orangered-light);

            border-radius: 10px;
        }
    }
    &-payment .tabs-filter__btn label {
        align-items: center;

        svg {
            min-width: 25px;
            min-height: 25px;
        }
    }

    &-login-toggle {
        input {
            accent-color: var(--orange);
        }
    }
    &-field-create-user {
        display: flex;
        gap: 5px;
        width: 100%;
        label {
            display: flex;
        }
        input {
            width: 22px;
            height: 22px;
            margin-right: 2px;
            accent-color: var(--orange);
        }
    }

    &-checkout-page {
        &-steps-wrapper .wa-form .wa-field {
            label {
                display: flex;
            }
            input {
                width: 20px;
                height: 20px;
                margin-right: 2px;
                accent-color: var(--orange);
            }
        }
    }
}

@include rmin($sm-phone-2) {
    .s {
        &__ship-added &-shipping-name {
            margin-left: 1rem;
            margin-top: 0.5rem;
        }
        &-checkout-page &-shipping-item:nth-of-type(2) &-rate-wrapper {
            top: 3.2rem;
        }
    }
}

@include rmin($tablet) {
    .s {
        &-checkout-page &-steps-wrapper .wa-form {
            & h3 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}

@include rmin($desktop) {
    .s {
        &-login-form {
            .wa-login-form-fields {
                max-width: 500px !important;
                width: 500px !important;
            }
            .wa-login-form-fields .js-login-form-fields-block {
                margin-left: unset !important;
                margin-right: unset !important;
            }
            .wa-login-form-actions {
                flex-direction: column;
            }
            .wa-login-form-wrapper {
                margin-top: 25px !important;
                margin-bottom: 0 !important;
            }
            .wa-login-form-wrapper .wa-login-form-actions .wa-buttons-wrapper {
                order: -1 !important;
            }
        }

        &__number {
            padding-left: 15px !important;
        }
        &-checkout-page &-steps-wrapper &-step-wrapper &-step-header {
            a {
                font-size: 26px;
            }
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-header h5 {
            font-size: 26px;

            .s-number {
                width: 25px;
            }
        }

        &-checkout-page &-steps-wrapper .wa-form h3 {
            .form-subtitle {
                font-size: 18px;
            }

            width: 100%;
            margin-bottom: 35px;
            font-size: 22px;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper header {
            // height: 7rem;
            padding: 20px !important;
            padding-left: 50px !important;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .s-header--sm {
            padding: 1.7rem 5rem;
            line-height: 2.6rem;

            min-height: 7rem;
        }
        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            &-step-content-wrapper
            &-step-content
            &-contact-form
            &-column-info
            &-info-block
            &-info-header {
            font-size: 20px;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-content-wrapper {
            padding: 50px;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-footer {
            padding: 20px 50px !important;

            .btn-fill {
                justify-content: center;
                gap: 10px;
                margin: 0;
                max-width: 285px;
                height: 55px;
                font-size: 18px;
            }
            // .s-footer__arr {
            //     width: 25px;
            //     height: 35px;
            // }
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-submit {
            justify-content: center;

            min-width: 20rem;
            padding: 1.6rem;
            line-height: 1;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper:not(:first-child) {
            margin-top: 25px;
        }

        &-info-content {
            font-size: 18px;
            max-width: 330px;
        }

        &-layout &-checkout-page {
            input[type="text"],
            input[type="search"],
            input[type="password"],
            input[type="email"],
            input[type="url"],
            input[type="date"],
            input[type="phone"],
            input[type="number"],
            select {
                width: 50%;
                min-width: 450px;

                padding: 15px 20px;
            }
        }

        &-layout &-checkout-page .wa-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 950px;
        }

        &-checkout-page &-steps-wrapper .wa-form .wa-field {
            margin-bottom: 25px;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            .service-agreement-wrapper {
            width: 100% !important;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            .wa-field.wa-field-address {
            margin-top: 1.5rem;
            margin-bottom: unset;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .field input {
            margin-top: 5px;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .field {
            display: block;
            margin-bottom: 25px;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            .wa-field.wa-field-address {
            width: 100%;

            & p {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            & p span {
                display: block;
                width: 48%;
            }
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            &-step-content-wrapper
            &-step-content
            &-contact-form
            &-column-info
            &-info-block {
            min-width: 450px;

            margin-bottom: 0;

            padding: 0 2.5rem;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            &-step-content-wrapper
            &-step-content
            &-contact-form
            &-column-info
            &__box {
            padding: 25px;
            max-width: 450px;

            img {
                width: 227px;
            }

            .s-icon {
                width: 100px;
                height: 100px;
            }
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-column-info {
            flex-direction: row;
            gap: 50px;
            margin-top: 25px;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-header svg {
            width: 2.5rem;
            height: 2rem;
        }

        ///step 3

        &-checkout-page &-shipping-name {
            font-size: 2.2rem;
        }

        &-checkout-page &-ship__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 2rem;

            & .s-column:nth-child(2) {
                width: 50%;

                & .s-rate-wrapper {
                    margin-left: 1rem;
                }
            }
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .field select {
            margin-top: 5px;
        }

        &-checkout-page label {
            margin-bottom: unset;
        }

        &-checkout-page .shipping-10 &-shipping-details {
            margin-top: 2.6rem;
        }

        &-checkout-page .shipping-10 &-layout {
            display: flex;
            flex-wrap: wrap;
        }

        &-checkout-page .shipping-10 &-column:nth-child(1),
        &-checkout-page .shipping-10 &-column:nth-child(2) {
            width: 50%;
        }

        &-checkout-page &-shipping-item:nth-of-type(2) &-rate-wrapper {
            position: unset;
        }

        &-checkout-page &-rate-wrapper {
            font-size: 2.2rem;
        }

        &-shipping-methods {
            .for-customers__loyalty-tabs {
                display: flex;
            }
            .for-customers__loyalty-tab,
            .s-payment-tab {
                padding: 0 !important;
                padding-left: 25px !important;
                padding-right: 25px !important;
                min-height: 55px !important;
            }
            &-tab {
                padding: 7px 15px;
            }

            .mail .wa-name h3 {
                font-size: 22px;
            }

            &-footer {
                gap: 25px;
            }
        }

        &-wa-value-deliv {
            flex-direction: column !important;

            select {
                width: 100% !important;
            }
            span {
                width: 100% !important;
            }
        }

        //step 4

        &-rate__sec {
            position: unset !important;
            margin-left: 1rem !important;
        }

        &-ship__sec {
            max-width: 47.7rem;

            margin-left: unset;
            margin-bottom: 1rem;

            & .s-shipping-description {
                margin-bottom: unset;
                margin-top: 1.2rem;
            }
        }

        &__ship-selected {
            margin-top: 5rem;
        }

        &-checkout-page &-payment-options &-payment-item {
            &:not(:last-child) {
                margin-bottom: 5rem;
            }

            & span {
                width: unset;
                margin-top: unset;
                margin-left: 1rem;

                font-size: 2.2rem;
            }

            & .s-payment-description {
                margin-top: 0.5rem;
                margin-left: unset;
            }
        }

        &-payment {
            margin-top: 0;
            margin-bottom: 50px !important;

            .tabs-filter__btn {
                font-size: 20px;
            }

            &-total {
                gap: 25px;
                font-size: 18px;
                span {
                    font-size: 28px;
                }
            }
        }
        //step 5
        &__final-step {
            & thead .s-column-name,
            & thead .s-column-amount,
            & thead .s-column-total {
                display: inline-block;

                font-size: 20px;
            }

            & thead tr {
                display: flex;
                justify-content: flex-end;

                margin-bottom: 15px;
            }

            & thead .s-column-total,
            & thead .s-column-amount {
                width: 15rem;
            }

            & thead .s-column-total {
                font-weight: 300;
                text-align: right;
            }

            & thead .s-column-amount {
                font-weight: 300;
                margin-right: 20px;
            }

            & .s-contact-header {
                margin-bottom: 50px;
                font-size: 22px;
            }

            & .s-user-name {
                font-size: 20px;
            }

            & .s-address-wrapper {
                font-size: 20px;
            }

            & .s__final-item {
                position: unset;
                display: block;
                margin-bottom: 0.5rem;

                & tr {
                    display: flex;
                }
            }

            & .s__final-item .s-name {
                font-size: 20px;
            }

            & .s__final-item .s-value,
            & .s__final-item .s-total {
                font-size: 20px;
            }

            & .s__final-item .s-column-amount {
                margin-left: auto;

                text-align: center;
            }

            & .s__final-item .s-column-name {
                // min-width: 450px;
                max-width: 450px;
                width: unset;
                margin-bottom: unset;
            }

            & .s__final-item .s-column-total {
                position: unset;
            }

            & .s-total-wrapper tr {
                display: flex !important;
                width: 100%;
                text-align: right;

                font-size: 2rem;
            }

            & .s-total-wrapper .column-1 {
                margin-left: auto;
                margin-right: 50px;
            }

            & .s-total-wrapper .column-2 {
                font-size: 24px;
                width: 150px;
            }

            & .s-total-wrapper tbody tr:nth-of-type(1) {
                align-items: center;
                margin-bottom: 0;
            }

            & .s-total-wrapper tbody tr:nth-of-type(2) {
                margin-bottom: 3.5rem;
            }

            & .s-total-wrapper .s-total-sum {
                margin-bottom: 7.5rem;
                font-size: 20px;
            }
        }

        //success page
        &__check-success {
            & .s-step-header {
                padding: 2.7rem 5rem !important;
            }

            & h2 {
                font-size: 2.6rem;
            }

            & h4 {
                margin-top: 35px;
                font-size: 30px;
                text-transform: uppercase;
            }

            & .s-step-content-wrapper {
                padding: 55px 50px !important;
            }

            & .s-text-section {
                margin-bottom: 35px;
            }

            & .s-text-section p {
                font-size: 20px;
            }

            & .s-success-back {
                display: inline-flex;
                align-items: center;

                margin-top: 3.5rem;
                padding: 1.55rem 2rem !important;

                font-size: 1.6rem;
                color: currentColor;

                & svg {
                    width: 2rem;
                    height: 1rem;
                }

                & span {
                    margin-left: 1rem;
                }
            }
        }
    }
}

#step-shipping {
    .select2-container--default
        .select2-selection--single
        .select2-selection__arrow {
        height: 40px !important;

        @include rmin($desktop) {
            height: 50px;
        }
    }
    .select2-container .select2-selection--single {
        height: 40px !important;
        border-radius: 9px !important;

        .select2-selection__rendered {
            line-height: 40px !important;
        }

        @include rmin($desktop) {
            display: flex;
            align-items: center;
            height: 50px !important;
        }
    }

    .shipping-16 .select2-container,
    .shipping-20 .select2-container,
    .shipping-40 .select2-container {
        width: 99.5% !important;

        @include rmin($desktop) {
            min-width: unset !important;
            max-width: unset !important;
        }
    }

    .select2-container--default .select2-selection--single {
        border-color: var(--gray-500) !important;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        display: flex;
        align-items: center;

        @include rmin($desktop) {
            padding-left: 20px;
            font-size: 16px !important;
        }
    }

    #np2_wh_shipping_fields20 {
        display: block;
    }
}

#np2_cities_16 .shipping-16 .select2-container {
    @include rmin($desktop) {
        width: 99.9% !important;
        max-width: unset !important;
        min-width: unset !important;
    }
}

.s-shipping-methods .shipping-16 .select2-container,
.s-shipping-methods .shipping-40 .select2-container {
    @include rmin($desktop) {
        width: 99.9% !important;
        max-width: unset !important;
        min-width: unset !important;
    }
}

.s-shipping-methods .form-subtitle {
    @include rmin($desktop) {
        font-weight: 300;
        font-size: 16px !important;
        gap: 10px;

        span {
            font-weight: 500;
            font-size: inherit !important;
        }
    }
}

.shipping-16,
.shipping-20 {
    .rate .hint {
        display: flex;

        @include rmin($desktop) {
            font-weight: 300;
            font-size: 16px !important;
            gap: 10px;

            strong {
                font-weight: 500;
                font-size: inherit !important;
            }
        }
    }
}

#np2_address_shipping_fields16,
#np2_wh_shipping_fields40 {
    width: 100%;
}

.np2_flatfloor {
    @include rmin($desktop) {
        width: 50% !important;
    }
}

.s__product-counter {
    @include rmin($desktop) {
        .s-amount-column {
            display: flex;
            justify-content: center;
            width: 202px;
        }
        .s-total-column {
            width: 202px;
        }
        .s-price-column {
            width: 202px;
        }
    }
}

.s-login-form {
    .wa-login-submit {
        background: var(--black-200) !important;
    }
}

.s-cart-products .s-total-sum {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .column-1 {
        font-size: 16px;
        font-weight: 300;
    }
    .column-2 {
        font-size: 20px;
    }

    @include rmin($desktop) {
        margin-top: 25px;
        justify-content: flex-end;

        .column-1 {
            font-size: 20px;
            margin-right: 50px;
        }

        .column-2 {
            font-size: 24px;
        }
    }
}
