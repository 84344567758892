@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.hero {
    position: relative;

    h2 {
        font-size: 30px;
        margin-bottom: 10px;
        padding: unset;
    }

    &__slide-link {
        display: block;
        width: 100%;
        height: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .glide__slides {
        // height: 160px;
        // height: 85vh;
        // max-height: 780px;
        background-color: #000;
    }

    .glide__slide {
        display: flex;
        align-items: center;

        color: #fff;
    }

    .glide__slide-cover {
        // background-image: url(../img/hero-main.jpg);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        height: 123px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            height: 100%;
            left: 50%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
        }

        @include rmin($sm-phone) {
            height: 144px;
        }

        @include rmin($sm-phone-2) {
            height: 159px;
        }

        @include rmin($desktop) {
            height: 365px;
        }
    }

    .glide__slide-info {
        width: 225px;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .glide__arrows {
        display: none;
    }

    .glide__bullets {
        display: none !important;
    }

    // .glide__bullets {
    //     position: static;
    //     width: 100%;
    //     display: flex;
    //     flex-wrap: wrap;
    //     margin-bottom: 20px;

    //     bottom: 65px;
    //     padding: 0 10px;

    //     box-shadow: none;

    //     z-index: 2;
    //     transform: none;
    // }

    // .glide__bullet {
    //     margin: 0;
    //     flex-basis: 50%;
    //     border-radius: 0;
    //     min-height: 25px;
    //     height: auto;


    //     font-weight: 700;
    //     font-size: 13px;
    //     line-height: unset;

    //     background-color: #fff;
    //     border: unset;
    //     box-shadow: unset;

    //     &:hover,
    //     &:focus {
    //         color: var(--orange);
    //     }

    //     &:nth-child(1) {
    //         border-top-left-radius: 5px;
    //         border-right: $border;
    //         border-bottom: $border;
    //     }

    //     &:nth-child(2) {
    //         border-top-right-radius: 5px;
    //         border-bottom: $border;
    //     }

    //     &:nth-child(3) {
    //         border-right: $border;
    //     }

    //     &:nth-child(5) {
    //         flex-basis: 100%;
    //         border-bottom-left-radius: 5px;
    //         border-bottom-right-radius: 5px;
    //         border-top: $border;
    //     }

    //     &:nth-last-child(1) {
    //         border-bottom: 1px solid var(--light-gray);
    //         border-bottom-left-radius: unset;
    //         border-bottom-right-radius: unset;
    //     }
    // }

    // .glide__bullet--active {
    //     color: var(--orange);
    // }
}

@include rmin($sm-phone) {
    .hero {
        .glide__bullets {
            bottom: 75px;
        }
    }
}

@include rmin($tablet) {
    .hero {
        .glide__slides {
            // height: 85vh;
            // max-height: 400px;
            // height: 400px;
        }

        h2 {
            line-height: 50px;
            font-size: 40px;
            margin-bottom: 20px;
        }

        .glide__slide {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .glide__slide-cover {
            // background-image: url(../img/hero-main.jpg);
        }

        .glide__slide-info {
            width: 300px;

            margin-top: 110px;
            margin-right: 100px;
            padding-left: unset;

            text-align: center;
        }

        .glide__bullets {
            position: absolute;
            bottom: 70px;

            margin-bottom: 0;

            transform: translateX(-50%);
        }

        .glide__bullet {

            font-size: 16px;
            height: 35px;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                border-radius: unset;
                border-bottom: unset;
                border: unset;
                flex-basis: 33.333%;
            }

            &:nth-child(1) {
                border-top-left-radius: 10px;
            }

            &:nth-child(2) {
                border-left: $border;
                border-right: $border;
            }

            &:nth-child(3) {
                border-top-right-radius: 10px;
            }

            &:nth-child(4),
            &:nth-child(5) {
                border-top: $border;
                flex-basis: 50%;
            }

            &:nth-child(4) {
                border-right: $border;
                border-bottom-left-radius: 10px;
            }

            &:nth-child(5) {
                border-bottom-right-radius: 10px;
            }

            &:nth-last-child(1),
            &:nth-last-child(2) {
                border-bottom-left-radius: unset;
                border-bottom-right-radius: unset;
                border-bottom: 1px solid var(--light-gray);
            }
        }
    }
}

@include rmin($desktop) {
    .hero {
        h2 {
            line-height: 50px;
            font-size: 40px;
            margin-bottom: 20px;
        }

        &__slide-link{
            // width: 56%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .glide__slide {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .glide__slide-info {
            width: 300px;
            margin-right: 270px;
            text-align: center;
        }

        .glide__arrows {
            display: block;
        }

        .glide__bullets {
            margin: 0 auto;
            max-width: $max-width;
            flex-wrap: unset;
            // padding: unset;
        }

        .glide__bullet {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                flex-basis: 20%;
            }

            &:nth-child(1) {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:nth-child(3),
            &:nth-child(4) {
                border-radius: unset;
            }

            &:nth-child(5) {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: unset;
                flex-basis: 20%;
            }

            &:not(:last-child) {
                border-right: $border;
            }
        }
    }
}

@include rmin($desktop-md) {
    .hero {
        h2 {
            font-size: 55px;
        }

        .glide__slide-info {
            width: 400px;
            margin-top: 150px;
        }
    }
}

@include rmin($desktop-lg) {
    .hero {
        h2 {
            font-size: 55px;
        }

        .glide__slide-info {
            width: 400px;
            margin-top: 150px;
        }
    }
}
