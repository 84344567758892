.s {
    &-layout {
        display: table;
        width: 100%;
    }

    &-content-block {
        position: relative;
        min-height: 22.3rem;

        margin: 35px 0;
    }

    &__product-inner {
        position: relative;
    }
    &-cart-page &-empty-section {
        margin: 35px 0;
        border: 1px solid var(--gray-400);
        border-radius: 10px;
        overflow: hidden;
    }

    &-cart-page &-empty-section > &-section-header {
        padding: 14px 20px;
        background-color: var(--white-200);
    }

    &-cart-page &-empty-section > &-section-header h2 {
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: 20px;
        font-weight: normal;
    }

    &-cart-page &-empty-section > &-section-body {
        padding: 15px;
    }

    &-cart-page &-empty-section > &-section-body p {
        display: block;
        margin: 10px 0;
        font-size: 24px;
        font-weight: 400 !important;
    }

    &-cart-page &-empty-section > &-section-body span {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: var(--gray-500);
    }

    &-cart-page &-empty-section > &-section-footer {
        display: block;
        padding: 15px;
        padding-top: 10px;
    }

    &-cart-page &-empty-section > &-section-footer &-link-back {
        display: inline-flex;
        align-items: center;
        padding: 1rem;

        font-size: 1.4rem;
        line-height: 1.3;
        color: var(--black);
        background-color: transparent;
    }

    &-cart-page &-empty-section > &-section-footer &-link__ico {
        width: 2rem;
        height: 1rem;

        margin-right: 1rem;
    }

    /////// cart
    &-cart-product {
        padding: 30px 0;
        border-bottom: 0.5px solid var(--gray-400);

        &:first-child {
            border-top: 0.5px solid var(--gray-400);
        }
    }
    //product

    &__product-item {
        display: flex;

        & .s__product-text {
            width: 100%;
        }
    }
    &-product-image {
        display: block;
    }
    &-product__img-wrapper {
        width: 70px;
        height: 70px;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    //title
    &-texs-column {
        display: flex;
        flex-direction: column;

        margin-left: 15px;
        text-transform: uppercase;
        color: var(--black);

        & .s-sku-wrapper {
            margin-bottom: -20px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    &-product-name {
        margin-bottom: 10px;

        font-weight: 500;
        font-size: 16px;

        & a {
            color: currentColor;
        }
    }

    //delete btn
    &-actions-column {
        display: flex;
        align-items: flex-end;
        max-width: 2rem;

        margin-left: auto;
    }
    &-delete-button {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--gray-400);
        cursor: pointer;
        transition: all 0.1s ease;

        &:hover {
            color: var(--orange);
        }
        &:active {
            transform: scale(0.95);
        }
        & > span {
            @include r($desktop) {
                display: none;
            }
        }
        svg {
            width: 22px;
            height: 22px;
            stroke-width: 3px;
            stroke: currentColor;
        }
    }
    //counter
    &__product-counter {
        display: flex;
        align-items: center;
        margin-top: 18px;

        & .s-quantity-wrapper {
            position: relative;

            max-width: 70px;
            padding: 0 !important;
        }

        .s-product-quantity {
            padding: 7px 20px !important;
            font-size: 14px !important;
            text-align: center;
        }

        & .js-decrease,
        & .js-increase {
            position: absolute;
            top: 0;

            width: 1.5rem;
            height: 100%;

            padding: 0 !important;
            background: transparent;
        }

        & .plus {
            right: 0;
            border-radius: 0 7px 7px 0;
        }
        & .minus {
            left: 0;
            border-radius: 7px 0 0 7px;
        }

        & .plus:hover,
        & .minus:hover {
            cursor: pointer;
            background: var(--gray-400);
        }
        .plus:active,
        .minus:active {
            background: var(--gray-500);
        }

        ///prices
        & .right span {
            display: inline-block;
            font-size: 12px;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        & .s-product-price-wrap {
            @include r($desktop) {
                display: flex;
                gap: 10px;
            }
        }

        & .s-product-price-sale {
            color: var(--gray-400);
            text-decoration: line-through;
        }

        & .s-product-total span,
        & .s-product-price span {
            margin-left: 0.3rem;
            font-size: 16px;
            font-weight: 700;
        }

        & .s-old__price .s-product__old {
            display: flex;

            margin-left: 1rem;
            margin-top: 2.2rem;
            font-size: 1.6rem;
            text-decoration: line-through;
            color: var(--gray-400);

            & span {
                display: block !important;
                font-size: 1.6rem;
            }
        }
        & .s-old__price span:nth-of-type(1) {
            display: none;
        }

        & .s-total-column {
            order: 1;
            margin-left: auto;

            text-align: right;
        }
        & .s-price-column {
            text-align: left;
            margin-left: 15px;
        }
    }
    //total price

    &-cart-page &-cart-total {
        display: flex;
        justify-content: flex-end;

        @include rmin($desktop) {
            margin-top: 30px;
        }
    }

    &-cart-page &-cart-total &-table-section {
        @include r($desktop) {
            width: 100%;
        }

        .btn-fill {
            gap: 35px;
            padding: 0 25px;
            justify-content: center;
        }
    }

    &-cart-page &-cart-total &-table-section &-totals-table &-total-wrapper {
        display: inline-table;
        margin-bottom: 1.5rem;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-total-wrapper
        td
        span {
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-total-wrapper
        &-column-price
        span {
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 2.2rem;
    }

    &-cart-page &-cart-total &-table-section &-totals-table &-cart-checkout {
        display: flex;
        justify-content: flex-end;
    }
    &-cart-checkout {
        td {
            // max-width: 2000px;
            width: 100%;
        }

        button {
            font-size: 16px;
        }

        @include rmin($desktop) {
            button {
                gap: 50px;
                font-size: 18px;
                min-height: 55px;
            }
        }
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-cart-checkout
        &-submit-button {
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-cart-checkout
        &-btn-arr {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        fill: var(--white);
    }

    //return btn
    &-content-block &-cart__return {
        margin-bottom: 35px;
    }

    &-content-block &-cart__return span:nth-child(1) {
        display: block;
        margin-bottom: 20px;
        font-size: 22px;
    }

    &-content-block &-cart__return &-cart__return-btn {
        display: inline-flex;
        align-items: center;
        padding: 1rem;

        font-size: 1.4rem;
        line-height: 1.3;
        color: var(--black);
        background-color: transparent;
    }

    &-content-block &-cart__return &-cart__arr {
        margin-right: 1rem;
    }

    //before card
    &-content-block &-cart-page &-sup__table {
        display: none;
    }

    &-cart-bottom {
        display: flex;
        // flex-direction: column;

        @include r($desktop) {
            flex-direction: column-reverse !important;
        }
    }

    &-cart-bonus {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin: 35px 0;
        max-width: 620px;
        order: 2;
        &__label {
            color: var(--gray-500);
        }

        &__top {
            display: flex;
            align-items: center;
            gap: 5px;
            .product-filter__option-input
                + .product-filter__option-text::before {
                border-radius: 5px;
            }
            .product-filter__option {
                padding: 0;
            }
            .product-filter__option-text {
                font-size: 16px;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 5px;
            input {
                padding: 10px 15px !important;
                font-size: 16px !important;
                max-width: 450px !important;
                width: 100%;
                // color: var(--black-200) !important;
                border-radius: 9px;
            }
            .order-page__btn--main {
                font-weight: 500;
                font-size: 16px;
                min-width: unset;
                min-height: 40px;
                max-width: 160px;
                width: 100%;
                background-color: var(--orange);
            }
        }

        &__confirmation {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            padding: 5px;
            border-radius: 9px;
            border: 1px solid var(--gray-400);
            &-title {
                padding: 15px;
                font-weight: 700;
                color: var(--orange);
                border-radius: 7px;
                background: #fff2ec;
            }

            &-info {
                ul {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px;

                    li {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        color: var(--gray-800);

                        span {
                            color: var(--black-200);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .s {
        //before cart
        &-content-block {
            margin: 100px 0;
        }

        &-content-block &-cart-page &-sup__table {
            display: table;

            // max-width: 53.4rem;
            width: 48%;
            margin-bottom: 25px;

            margin-left: auto;

            & span {
                display: inline-block;
                font-size: 16px;
            }

            & span::first-letter {
                text-transform: capitalize;
            }
            & td {
                width: 30%;
                text-align: center;
            }
            & td:nth-of-type(1) {
                text-align: left;
                // width: 45%;
            }
            & td:nth-of-type(2) {
                text-align: center;
            }

            & td:last-child {
                // width: 23%;
                text-align: right;
            }

            & td:nth-of-type(3) span {
                margin-right: 2rem;
            }
        }

        &-cart-page &-empty-section > &-section-footer &-link-back {
            padding: 1.3rem 1.9rem;
            font-size: 1.6rem;
        }

        &-cart-page &-empty-section > &-section-header h2 {
            font-size: 26px;
        }
        &-cart-page &-empty-section > &-section-body p {
            margin-top: 35px;
            font-size: 30px;
        }

        &-cart-page &-empty-section > &-section-body span {
            font-size: 20px;
        }
        &-empty-section {
            margin: 100px 0 !important;
            & .s-section-header {
                padding: 25px 50px !important;
            }

            & .s-section-body {
                padding: 50px !important;
                padding-bottom: 35px !important;
            }

            & .s-section-footer {
                padding: 50px !important;
                padding-top: 0 !important;
                padding-bottom: 55px !important;
            }
        }
        ///cart
        //product

        &__product-inner {
            position: unset;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        &-product__img-wrapper {
            width: 110px;
            height: 110px;
        }
        //total price

        &-cart-page &-cart-total {
            margin-top: 30px;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper {
            margin-bottom: 2.5rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper
            &-column-price
            span {
            font-size: 2.8rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper
            &-column-price
            span:not(:last-child) {
            margin-left: 1.7rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-cart-checkout
            &-submit-button {
            display: inline-flex;
            justify-content: center;
            min-width: 20rem;
            font-size: 1.6rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper
            td
            span {
            line-height: 1.2;
        }

        //title
        &-texs-column {
            margin-left: 25px;
            max-width: 400px;
            & .s-sku-wrapper {
                margin-bottom: 15px;
                font-size: 16px;
            }
        }
        &-product-name {
            margin-bottom: 15px;
            font-size: 20px;
        }
        //delete btn
        &-actions-column {
            display: flex;
            align-items: flex-end;
            margin-left: 1.5rem;
            margin-bottom: unset;
        }

        //counter

        &__product-counter {
            position: relative;
            width: 50%;

            margin-top: unset;
            margin-left: unset;
            font-size: 20px;

            & .s-total-column span,
            & .s-price-column span {
                display: none;
                font-size: 20px;
            }
            & .s-amount-column {
                position: unset;
                margin-left: auto;
            }

            & .s-total-column .s-product-total span,
            & .s-price-column .s-product-price span {
                display: inline-block !important;
            }

            & .s-price-column {
                order: -1;
            }
            & .s-old__price {
                position: absolute;
                left: 0.7rem;
                top: 0.5rem;

                & .s-product__old {
                    font-size: 1.9rem;

                    & span {
                        font-size: 1.9rem;
                    }
                }
            }
            & .s-quantity-wrapper {
                max-width: 100px;
            }
        }

        // cart

        &-cart-bottom {
            display: unset;
        }

        &-cart-bonus {
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin: 50px 0;
            max-width: 620px;

            &__label {
                color: var(--gray-500);
            }

            &__top {
                display: flex;
                align-items: center;
                gap: 5px;
                .product-filter__option-input
                    + .product-filter__option-text::before {
                    border-radius: 5px;
                }
                .product-filter__option {
                    padding: 0;
                }
                .product-filter__option-text {
                    font-size: 16px;
                }
            }

            &__bottom {
                flex-direction: row;
                input {
                    padding: 15px 20px !important;
                }
                .order-page__btn--main {
                    font-size: 16px;
                    min-width: unset;
                    max-width: 160px;
                    width: 100%;
                }
            }
        }

        //return btn
        &-content-block &-cart__return {
            margin-bottom: 50px;
        }

        &-content-block &-cart__return span:nth-child(1) {
            margin-bottom: 20px;
            font-size: 26px;
        }

        &-content-block &-cart__return &-cart__return-btn {
            padding: 1.3rem 1.6rem;
            font-size: 1.6rem;
        }
    }
}

input[type="search"],
.s-layout input[type="text"],
.s-layout input[type="url"] {
    width: 100%;
    padding: 7px;
    font-size: 18px;
    border: 1px solid var(--gray-400);
    border-radius: 7px;
}

.tooltip-v2 {
    display: inline-flex;
    cursor: pointer;
    bottom: 1px !important;

    &--pos {
        @include r($desktop) {
            position: relative;
        }
    }

    @include rmin($desktop) {
        position: relative;
    }
}

.tooltip-v2 .tooltip-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    visibility: hidden;
    background-color: var(--black-200);
    color: var(--white);
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000000000;
    font-size: 14px;
    font-weight: 300;

    @include rmin($desktop) {
        top: 30px;
        left: 50%;
        width: 326px;
        transform: translateX(-47%);
    }
}

.tooltip-v2 .tooltip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    border-width: 8.66px;
    border-style: solid;
    border-color: transparent transparent var(--black-200);

    @include r($desktop) {
        left: 25px;
    }
    @include rmin($desktop) {
        right: 50%;
    }
}

.tooltip-v2:hover .tooltip-text,
.tooltip-v2:focus .tooltip-text {
    visibility: visible;
}

.s-login-toggle {
    li {
        display: flex;
        align-items: center;
    }
}

.rate .hint {
    display: flex;
    align-items: center;
}

.s__final-step .s-confirm-wrapper label {
    display: flex;
    align-items: center;
}
