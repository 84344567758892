// .wa-step-auth-section .wa-section-header,
// .wa-step-confirm-section .wa-total-section,
// .wa-step-confirm-section .wa-actions-section {
//   display: none;
// }

.line::before {
  margin-top: 0 !important;
}

div:not(.wa-dialog-background):not(.page-backdrop):not(#map):not(.star-value):empty,
.wa-line.wa-required-text {
  display: none;
}
[data-id="pickup"] .wa-date {
  display: none;
}

.pickup #wa-step-details-section {
  display: none;
}

.wa-fields-group.line {
  margin-right: 0 !important;
}

.wa-line,
.wa-order-form-wrapper .wa-step-shipping-section .wa-delivery-types-section {
  margin-top: 0 !important;
}

.wa-fields-group > .wa-field-wrapper {
  margin-top: inherit !important;
}

.wa-fields-group.line > .wa-field-wrapper {
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.wa-field-wrapper.size-medium {
  width: auto !important;
}
.wa-field-wrapper {
  display: block !important;
}

.wa-field-wrapper .wa-select {
  height: auto !important;
}
.wa-region-wrapper {
  margin-bottom: 6px;

  @include rmin($desktop) {
    margin-top: 15px;
  }
}
// .input__fieldset:not(:last-of-type):nth-child(odd) {
//   margin-right: 0 !important;
//   flex-basis: auto !important;
// }