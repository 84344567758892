.searchpro__page {
    &-empty{
        max-width: 357px;
        margin: 100px auto;
        text-align: center;
    }
    &-empty_block-content{
        margin-top: 25px;
    }
    &-empty_item{
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5.5px 15px;

        font-size: 14px;
        line-height: 16px;
        color: var(--orange);
        text-decoration: underline;
        background-color: var(--white-200);
        border-radius: 50px;
    }
    &-img{
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }
}