.training-categories {
    margin-top: 30px;
    margin-bottom: 30px;

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -6px;
        margin-left: -3px;
        margin-right: -3px;

        width: 100%;
    }

    &__item {
        margin-top: 6px;
        margin-left: 3px;
        margin-right: 3px;
        width: calc(50% - 6px);

        .btn-details {
            display: none;
        }
    }

    &__item-link {
        position: relative;
        width: 100%;
        padding-top: 106%;

        img {
            position: absolute;
            top: 0;
        }
    }

    &__item-link {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        // min-height: 185px;
        // height: 185px;

        text-decoration: none;
        background-size: cover;
        // background-position: bottom center;
        background-repeat: no-repeat;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 45px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        }
    }

    &__item-info {
        position: relative;
        z-index: 1;
        margin-left: 10px;
        margin-bottom: 10px;

        h3 {
            font-weight: 500;
            font-size: 16px;
            color: #fff;
        }
    }
}

@include rmin($sm-phone) {
    .training-categories {
        &__item-link {
            padding-top: 108%;
        }
    }
}

@include rmin($sm-phone-2) {
    .training-categories {
        &__item-link {
            padding-top: 111%;
        }
    }
}

@include rmin($tablet) {
    .training-categories {
        &__item {
            width: calc(33% - 6px);
        }
    }
}

@include rmin($desktop) {
    .training-categories {
        display: flex;

        &__list {
            margin-top: -30px;
            margin-left: -15px;
            margin-right: -15px;
        }

        &__item {
            margin-top: 30px;
            margin-left: 15px;
            margin-right: 15px;
            width: calc(33.3% - 30px);

            .btn-details {
                display: block;
            }

            &:last-child {
                width: calc(33.3% - 30px);
            }
        }

        &__item-link {
            min-height: 500px;
            height: 500px;

            &:hover {
                position: relative;
                z-index: 1;
                box-shadow: inset 0px 0px 0px 5px var(--orange-300);
            }
        }

        &__item-info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-left: 25px;
            margin-right: 25px;
            margin-bottom: 20px;

            h3 {
                font-size: 40px;
            }

            .btn-details {
                padding: 5px 35px;
                background-color: unset;
                border: 2px solid var(--orange-300);

                &::after {
                    top: 8px;
                    left: 45%;

                    transform: rotate(-45deg) translateX(-50%);
                    border-width: 3px;
                }
            }
        }

        &__item-link:hover &__item-info {
            .btn-details {
                background-color: var(--orange-300);
            }
        }
    }
}
