.brand-d {
    &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        gap: 35px;
        margin: 25px 0 5px;
        max-width: $max-width;

        @include rmin($desktop) {
            margin: 50px 0;

            .category__main-content {
                display: none;
            }
        }

        h1 {
            margin: unset;
            text-align: center;
        }
    }
    &__filter-list {
    }
    &__cards {
        --card-size: 50%;
    }
}

@include rmin($desktop) {
    .brand-d {
        &__cards {
            --card-size: 25%;

            .cards__item {
                border-left: unset;
                border-right: 1px solid var(--gray-300) !important;

                &:nth-child(4) {
                    border-top: unset;
                }
                &:nth-child(4n) {
                    border-right: unset !important;
                }
                &:nth-child(odd) {
                    border-left: unset;
                }
            }
        }

        &__wrap .category__title {
            margin-bottom: 15px;
        }
        &__wrap .category__title h1::after {
            bottom: 0;
        }
    }
}
