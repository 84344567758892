// breakpoints
$sm-phone: 360;
$sm-phone-2: 414;
$tablet: 680;
$desktop: 1024;
$desktop-s: 1280;
$desktop-md: 1919;
$desktop-lg: 2540;

$max-width: 1280px;

$font-main: "Mag", sans-serif;

$border: 1px solid var(--light-gray);
$border-orange: 1px solid var(--orange);
$border-light-orange: 1px solid var(--light-orange);
$border-header: 1px solid var(--dark-gray);

// colors
:root {
    --light-orange: #ff7a00;
    --orange-2: #ff7a00;
    --orange: #fa5a0d; //orange-500
    --orangered: #fc1520;
    --orangered-light: #FFEDEE;

    --light-gray-2: #f5f5f5;
    --light-gray: #c1c1c1;
    --gray: #919191;
    --gray-2: #c7cac7;
    --gray-bg: #f0f0f0;
    --dark-gray: #3e3e3e;
    --grayish: #f4f4f4;
    --gray-bg: #f0f0f0;

    --white: #ffffff;
    --white-100: #f5f5f5;
    --white-200: #f8f8f8;

    --gray-800: #656565;
    --gray-700: #404040;
    --gray-500: #909090;
    --gray-400: #c4c4c4;
    --gray-100: #c1c1c1;
    --gray-300: #dadada;

    --green: #00b93f;

    --card-hover: #fff0d9;
    --black: #000000;
    --black-200: #1d1d1b;
}

// --grayish: #f4f4f4;
// --gray-100: #c1c1c1;
// --gray-200: #c7cac7;
// --gray-300: #dadada;
// --gray-400: #C4C4C4;
// --gray-500: #919191;
// --gray-600: #909090;
