.sort-n {
  display: none;
}

@include rmin($desktop) {
  .sort-n {
    display: block;
    
    &__wrap, 
    &__list {
      display: flex;
      align-items: center;
    }
  
    &__wrap {
      justify-content: center;
    }
  
    &__title {
      margin-right: 10px;
      font-size: 16px;
    }
    &__list {
    }
  
    &__item {
      padding: 3px 10px 1px;
  
      font-size: 14px;
      border-radius: 25px;
      color: var(--gray-500);
      cursor: pointer;
      
      &:hover {
        color: var(--white);
        background-color: var(--orange);
      }
  
      &--active {
        color: var(--white);
        background-color: var(--orange);
      }
    }
  }
}