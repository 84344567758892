.shop-cart,
.s-order-page {
    // display: flex;
    // flex-direction: column;

    .wa-section-header,
    .wa-delivery-types-section,
    .wa-delivery-variants-section {
        display: block !important;
        margin: 0 -10px !important;
        padding: 0 10px 10px;

        &:not(:last-child) {
            margin-bottom: 10px !important;
        }

        // border-bottom: 1px solid var(--gray-2);

        .wa-header {
            // display: block !important;

            color: #000 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
        }

        .wa-login-link {
            font-size: 14px;

            color: var(--orange);
        }

        .wa-contact-name {
            font-size: 16px !important;
        }

        .wa-logout-link {
            display: none;
        }
    }

    .wa-order-bonus strong {
        color: var(--orange);
    }

    .wa-details-fields-section {
        .wa-label {
            color: #000 !important;
            font-size: 14px !important;
            font-weight: 700 !important;
        }
    }

    .wa-details-fields-section .wa-description {
        display: none;
    }

    .wa-methods-list {
        display: flex;
        flex-direction: column;

        border-collapse: collapse;
    }

    .wa-type-wrapper,
    .wa-method-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-bottom: 0 !important;
        padding: 10px;

        color: var(--gray-500) !important;
        font-size: 16px !important;
        font-weight: 600;
        text-align: center !important;

        background-color: transparent;
        border-color: var(--gray-400);
        border-radius: unset !important;

        box-shadow: unset !important;
        cursor: pointer;

        .wa-description {
            color: inherit !important;
        }

        // .wa-image-wrapper {
        //     display: none !important;
        // }

        &.is-active {
            color: #fff !important;

            background-color: var(--orange-2) !important;

            .wa-description {}

            .wa-price,
            .wa-name,
            .wa-date {
                color: #fff !important;
            }
        }

    }

    .wa-type-wrapper {
        display: block;
    }

    .wa-details-rates-section,
    .wa-type-wrapper {
        .wa-name {
            font-size: 16px !important;
            color: #000 !important;
        }

        .wa-price {
            // color: var(--orange-2);
        }
    }

    .wa-type-wrapper {
        .wa-name {
            font-weight: 700 !important;
        }
    }

    .wa-details-description {
        color: #000 !important;
    }

    .wa-schedule-wrapper {
        margin: 15px 0 !important;
    }

    .wa-additional-item .wa-label,
    .wa-comment-item .wa-label,
    .wa-additional-item .wa-value,
    .wa-comment-item .wa-value,
    .wa-schedule-wrapper .wa-date,
    .wa-item .wa-name,
    .wa-cell .wa-name {
        font-size: 13px !important;
        font-weight: 700 !important;
        color: #000 !important;
    }

    .wa-schedule-wrapper .wa-time,
    .wa-value,
    .wa-cell-value {
        text-align: right;
        font-size: 14px !important;
        color: #000 !important;
    }

    .wa-schedule-wrapper>.wa-label {
        font-size: 18px !important;
        font-weight: 700 !important;
    }

    &__title,
    .s-header {
        margin-top: 0;
        padding: 0;

        font-weight: 700;
    }

    .s-delete-button {
        // display: flex !important;
        // align-items: center !important;
        padding: 6px 0;

        font-size: 18px;
        font-weight: 400;
        color: #000;

        // background: transparent;
    }

    .s-delete-icon {
        width: .8em;
        height: 1em;
    }

    .s-delete-button:hover,
    .s-delete-button:focus {
        // color: var(--orange) !important;

        // .s-delete-icon {
        //     fill: var(--orange) !important;
        // }
    }

    // 
    .wa-order-form-wrapper .wa-step-shipping-section .wa-delivery-types-section .wa-types-list .wa-type-wrapper.is-active {
        color: #fff !important;
        background-color: var(--orange-2) !important;
        background: var(--orange-2) !important;
        border-color: var(--orange-2) !important;

        .wa-name {
            margin-top: 5px;
        }
    }

    .wa-order-form-wrapper .wa-step-payment-section .wa-methods-list.wide .wa-method-wrapper {
        background-color: #fff !important;
        background: #fff !important;
        font-weight: 500;
    }

    .wa-order-form-wrapper .wa-step-payment-section .wa-methods-list.wide .wa-method-wrapper.is-active {
        border-color: var(--orange-2) !important;
        background: #F8F8F8 !important;
        background-color: #F8F8F8 !important;
        color: var(--orange-2) !important;
    }

    .wa-order-form-wrapper .wa-step-payment-section .wa-methods-list .wa-method-wrapper .wa-method {

    }

    &__bag-wrapper,
    #js-order-cart {
        order: 1;
    }

    #js-order-form {
        margin: 0 -10px;
        padding: 10px;

        background-color: #f7f7f7;
    }

    .wa-order-form-wrapper {
        background-color: #fff;
        padding: 10px;
    }

    &__summary-wrapper {
        order: -1;
    }

    &__bag {
        display: none !important;
    }

    &__bag-item {
        display: flex;
        padding-top: 25px;
        padding-bottom: 25px;

        &:not(:last-child) {
            border-bottom: $border;
        }
    }

    &__bag-img,
    .wa-column-image {
        position: absolute;
        left: 0;
        top: 0;

        --size: 80px !important;
        flex-basis: var(--size);
        min-width: var(--size);
        min-height: var(--size);
        max-width: var(--size);
        max-height: var(--size);
        margin: 0;
    }

    .wa-form-footer {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__checkout {

        h2,
        h3 {
            padding: 0;
            text-align: center;
        }

        h2 {
            margin: 10px 0;
            font-size: 26px;
        }

        h3 {
            margin-bottom: 10px;

            font-size: 18px;
            color: var(--light-orange);
        }
    }

    .wa-step-section,
    &__section {
        margin: 0 !important;
        padding: 15px 0 !important;
        border-bottom: 1px solid var(--gray-2);
    }

    &__section-bonus {
        strong {
            display: block;
            font-size: 14px;
            text-align: center;
        }
    }

    &__section-review {
        margin-top: -20px;
        text-align: center;

        border-top: 0 !important;
    }

    &__section-review.is-opened {
        .input__review {
            display: block !important;
            animation: slide-in-bottom 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
    }

    &__option {
        margin-top: 5px;

        padding-left: unset;
        padding-right: unset;

        span {
            font-size: 14px !important;
        }
    }

    &__bonus-info {
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
        }
    }

    &__additional-info {
        font-size: 12px !important;
    }

    .s-styled-select,
    &__input-wrapper {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            right: 13px;
            bottom: 13px;

            border-bottom: 2px solid var(--gray);
            border-right: 2px solid var(--gray);
            width: 8px;
            height: 8px;
            transform: rotate(45deg);
            pointer-events: none;
        }
    }

    .wa-step-details-section .s-styled-select {
        &::after {
            content: none;
        }
    }

    .wa-select,
    &__input {
        appearance: none;
    }

    &__store-list {
        margin-top: 10px;
    }

    &__store {
        font-weight: 700;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .wa-comment-section .wa-link,
    &__review {
        color: var(--light-orange) !important;
        text-align: center;
        font-size: inherit;
        font-weight: 300;

        cursor: pointer;
        border-bottom: 1px dashed currentColor;

        &:hover {
            color: var(--orange);
        }
    }

    .wa-item-total {
        .wa-name {
            font-size: 16px !important;
        }

        .wa-price {
            font-size: 22px !important;
            font-weight: 700 !important;
        }
    }

    &__review-wrapper--active &__review {
        display: none;
    }

    &__review-wrapper--active {}

    &__tabs-wrapper {
        margin: 5px 0;

        .tabs__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .tabs__item {
            margin: 5px 0;
            font-weight: 700;
        }

        .tabs__item--current {
            text-decoration: underline;
        }

        .tabs__content {
            margin-top: 10px;
            padding: 0 25px;
        }

        .input__label-field {
            font-size: 16px;
        }

        .input__fieldset {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    &__input-container {
        margin: 0 !important;
    }

    // begin summary
    .s-section-header,
    .wa-cart-body,
    &__summary {
        padding: 10px !important;
        // background-color: var(--light-gray-2);

        h4,
        .wa-name {
            font-size: 18px;
            margin-right: 0;
        }
    }

    &__summary-item {
        display: flex;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &__summary-img-wrapper {
        width: 25%;
    }

    .wa-product-body {
        position: relative;

        // display: block;
        flex-direction: column;
        // flex-wrap: nowrap;
        // justify-content: unset;
        padding-left: 85px;
    }

    .wa-column-price {
        order: -1;
        width: auto !important;
        margin-bottom: 6px !important;

        text-align: unset !important;

        .wa-price-total {
            font-size: 18px !important;
            font-weight: 700 !important;
        }
    }

    .wa-column-quantity {
        padding-left: 0 !important;
    }

    .wa-quantity-box {
        display: flex;
    }

    .js-product-quantity {
        position: relative;
        width: 37px !important;

        font-size: 12px;
        text-align: center;

        border: 1px solid #dcdcdc;
    }

    .wa-column-details {
        // flex-basis: calc();
    }

    .wa-details {
        // display: flex;
        // flex-wrap: wrap;
    }

    .wa-sku {
        font-weight: 700;
        color: var(--orange);
    }

    .wa-column-details,
    &__summary-info-wrapper {
        flex: 1 0 auto;
        flex-basis: calc(100% - 110px);
        margin: 0;
        margin-bottom: 8px;

        strong,
        .wa-name {
            // display: block;

            font-size: 14px !important;
            font-weight: 300;
            color: inherit;
        }
    }

    .wa-sku {
        margin-right: 0;
    }

    .js-delete-product {
        position: absolute;
        right: 0;
        bottom: 0;

        font-size: 0 !important;

        .wa-tooltip::after,
        .wa-tooltip::before {
            content: none;
        }
    }

    .wa-icon {
        display: inline-block;
        width: 25px;
        height: 25px;
        padding: 0 !important;

        svg {
            max-width: 100%;
            max-height: 100%
        }
    }

    .js-increase,
    .js-decrease {
        padding: 0 12px;
        height: 27px;

        border-width: 1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .plus,
    .minus {
        width: 12px;
        height: 12px;
        color: #aaa;


        // line-height: 25px;

        svg {}
    }

    .s-back-link {
        display: none;
    }

    &__summary-info {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        span {
            font-weight: 700;
        }
    }

    &__sale {
        text-decoration: line-through;
        color: var(--light-gray);
    }

    .js-product-full-price &__price {
        margin-left: 3px;
        color: var(--orange);
    }

    .js-delete-product,
    &__summary-count {
        display: flex;
        align-items: center;

        color: var(--orange) !important;

        svg,
        .cards__favorite-icon {
            margin-left: 5px;

            cursor: pointer;
            fill: var(--orange);

            &:hover {
                fill: var(--gray);
            }
        }
    }

    &__bag-title {
        color: inherit;
        font-weight: bold;
        text-decoration: none;
    }

    &__total-wrapper {
        margin-top: 5px;
        margin-bottom: 5px;

        border-top: $border;
        border-bottom: $border;

        h4 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__total {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__total-price {
        font-weight: 700;
        font-size: 18px;
    }

    &__total-amount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-weight: 700;
        }
    }

    &__bonus {
        display: block;
        margin-top: 10px;

        text-align: center;
        font-weight: 300;
        font-size: 14px;
    }

    // end summary


    .btn-approve--checkout {}

    .btn-approve--summary {
        display: none;
        pointer-events: none;
    }

    &__input-form {
        margin-bottom: 30px;

        .input__fieldset {
            &:last-of-type {
                flex-basis: 50%;
            }
        }
    }

    .wa-cart-details {

        .wa-column-price,
        .wa-price-section {
            margin-top: 0px !important;
        }
    }
}

.s-cart-empty-wrapper {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    margin: 100px 0 0;

    .s-description {
        margin-top: 16px;
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        text-align: center;
        font-weight: 700;
    }
}

.checkout-result.success {}

@include rmin(600) {

    .shop-cart,
    .s-order-page {

        .wa-product {
            padding-top: 15px !important;
        }

        .wa-product-body {
            display: flex !important;
            flex-direction: row !important;
            padding: 0 !important;
        }

        .wa-column-image {
            position: static;

            --size: 100px !important;

            padding-right: 0;
        }

        .wa-column-details {
            flex: unset;
            flex-basis: auto;
            max-width: 160px !important;
            padding-right: 15px;
        }

        .wa-column-quantity {
            width: 150px !important;
        }

        .wa-column-price {
            order: 0;
        }

        .js-delete-product {
            top: 0;
            bottom: auto;
        }

        .delete {
            width: 20px !important;
            height: 20px !important;
        }

        .wa-cart-details {
            display: block !important;

            .wa-column-content,
            .wa-column-price {
                display: block !important;
            }

            .wa-price-total {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

@media screen and (max-width: 1300px) and (min-width: 840px) {

    .shop-cart,
    .s-order-page {
        .wa-product-body {
            justify-content: space-around;
        }
    }
}

@include rmin($desktop) {

    .shop-cart,
    .s-order-page {
        display: flex;
        flex-direction: unset;

        &__bag {
            display: block !important;
        }

        .wa-product-body,
        &__bag-item {
            display: flex;
            padding-top: 25px;
            padding-bottom: 25px;
            justify-content: unset !important;

            &:not(:last-child) {
                border-bottom: $border;
            }
        }

        .wa-column-image,
        &__bag-img {
            position: static;
            --size: 15% !important;
            margin: 0 !important;
            padding-right: 0 !important;
        }

        .wa-column-details {
            display: flex !important;
            flex-basis: unset !important;
            margin: 0 !important;
            max-width: none !important;
        }

        .wa-column-details,
        &__bag-info {
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 15px;

            span {
                margin: 3px 0;
            }
        }

        .wa-column-details .wa-name,
        &__bag-title {
            margin-bottom: 5px;

            font-size: 22px;
            font-weight: 700 !important;
        }

        .wa-column-quantity,
        &__bag-sizes {
            display: flex !important;
            align-items: center;
            justify-content: space-around;

            width: 18% !important;
            padding: 0 15px !important;
            border-left: $border !important;
            border-right: $border !important;

            div {
                // display: flex;
                // align-items: center;
                // flex-direction: column;
            }
        }

        .wa-step-auth-section .wa-section-header {
            display: flex !important;
            justify-content: space-between !important;

            .wa-contact-name {
                font-size: 17px !important;
            }
        }

        .wa-total-section {
            margin-bottom: 10px;
            flex: unset !important;
            flex-basis: 100% !important;
        }

        .wa-cart-details {
            .wa-order-bonus {
                font-size: 16px;

                strong {
                    font-size: 1.2em;
                }
            }

            .wa-column-price {
                display: none !important;
            }
        }

        &__bag-sizes-border {
            display: block;
            border: $border;
            width: 50px;
            text-align: center;
        }

        .wa-column-price,
        &__bag-price {
            position: relative;
            display: flex !important;
            width: 18% !important;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 15px;

            .shop-cart__sale-wrapper {
                font-size: 18px;
            }

            .js-product-full-price,
            .shop-cart__sale,
            .shop-cart__price {
                font-weight: 700;
            }

            .js-product-full-price,
            .shop-cart__price {
                font-size: 30px;
            }

            .shop-cart__summary-count {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        &__sale-text {}

        &__checkout {

            h2,
            h3 {
                text-align: unset;
            }
        }

        &__bag-wrapper,
        #js-order-cart {
            order: -1;

            flex: 1;
            margin-right: 30px;
        }

        &__summary {
            position: sticky;
            top: 160px;
            padding: 15px;
        }

        &__summary-info-wrapper {
            strong {
                font-size: 16px;
            }
        }

        #js-order-form {
            max-width: calc(50% - 50px);
            margin: 0;
            padding: 0;

            background-color: #f7f7f7;
        }

        &__summary-wrapper {
            order: 1;

            width: 325px;
        }

        &__input-form {
            display: block;
        }

        .wa-step-auth-section .wa-fields-group,
        &__input-container {
            display: flex;
            flex-wrap: wrap;
            margin-right: 0 !important;
        }

        &__section-bonus {
            strong {
                display: flex;
                align-items: flex-end;
                flex-basis: 50%;

                margin-top: 10px;
                padding-left: 15px;
            }
        }

        &__section-bonus &__input-container {
            align-items: center;
        }

        &__section-bonus .input__fieldset {
            flex-basis: calc(50% - 20px) !important;
            flex-grow: unset;
        }

        &__tabs-wrapper {
            margin-bottom: 15px;

            .tabs__list {
                justify-content: unset;
            }

            .tabs__item {
                padding: unset;

                &:not(:first-child) {
                    margin-left: 50px;
                }

                &:nth-child(1) {
                    order: 0;
                }

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 1;
                }
            }

            .tabs__content {
                padding: unset;
            }
        }

        &__delivery-wrapper {
            .input__fieldset {
                max-width: 250px;
            }
        }

        &__section-review {
            margin-top: 0;

            text-align: unset;
            border-top: $border !important;
        }

        .btn-approve--checkout {
            display: none;
            pointer-events: none;
        }

        .btn-approve--summary {
            display: block;
            pointer-events: auto;

            margin-top: 5px;
        }
    }

    .wa-methods-list {
        display: flex;
        flex-direction: unset !important;
    }

    .s-cart-empty-wrapper {
        .s-description {
            font-size: 34px;
        }
    }
}

.np2_flatfloor, #np2_custom_street16 {
    margin-top: 10px;
}

.np2_flatfloor, .np2_canhideflat {
    width: 100% !important;
    
    input {
        width: 100% !important;
        min-width: unset !important;
        max-width: unset !important;
    }
}

.wa-field-ne_perezvanivat_mne {
    display: flex;
    align-items: center;
    flex-direction:row-reverse;
    gap: 5px;

    input {
        width: 22px;
        height: 22px;
        accent-color: var(--orange);
    }
}

.wa-order-form-wrapper .wa-step-confirm-section {
    // border-top: none !important;
    // padding: 0 !important;
}

.wa-order-form-wrapper .wa-step-section {
    // margin: 0 !important;
}

// .shop-cart,
// #js-order-cart {
//     display: flex;
//     flex-direction: column;

//     &__title {
//         margin-top: 0;
//     }

//     &__bag-wrapper {
//         order: 1;
//     }

//     &__summary-wrapper {
//         order: -1;
//     }

//     &__bag {
//         display: none !important;
//     }

//     &__checkout {

//         h2,
//         h3 {
//             padding: 0;
//             text-align: center;
//         }

//         h2 {
//             margin: 10px 0;
//             font-size: 26px;
//         }

//         h3 {
//             margin-bottom: 10px;

//             font-size: 18px;
//             color: var(--light-orange);
//         }
//     }

//     &__section {
//         margin: 0 !important;
//         padding: 15px 0 !important;
//         border-top: $border;
//     }

//     &__section-bonus {
//         strong {
//             display: block;
//             font-size: 14px;
//             text-align: center;
//         }
//     }

//     &__section-review {
//         margin-top: -20px;
//         text-align: center;

//         border-top: 0 !important;
//     }
//     &__section-review.is-opened {
//         .input__review {
//             display: block !important;
//             animation: slide-in-bottom 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
//         }
//     }

//     &__option {
//         margin-top: 5px;

//         padding-left: unset;
//         padding-right: unset;

//         span {
//             font-size: 14px !important;
//         }
//     }

//     &__bonus-info {
//         display: flex;
//         flex-direction: column;

//         a {
//             text-decoration: none;
//         }
//     }

//     &__additional-info {
//         font-size: 12px !important;
//     }

//     &__input-wrapper {
//         position: relative;

//         &::after {
//             position: absolute;
//             content: '';
//             right: 13px;
//             bottom: 13px;

//             border-bottom: 2px solid var(--gray);
//             border-right: 2px solid var(--gray);
//             width: 8px;
//             height: 8px;
//             transform: rotate(45deg);
//             pointer-events: none;
//         }
//     }

//     &__input {
//         appearance: none;
//     }

//     &__store-list {
//         margin-top: 10px;
//     }

//     &__store {
//         font-weight: 700;

//         &:not(:last-child) {
//             margin-bottom: 10px;
//         }
//     }

//     &__review {
//         color: var(--light-orange) !important;
//         text-align: center;
//         font-size: inherit;
//         font-weight: 300;

//         cursor: pointer;
//         border-bottom: 1px dashed currentColor;

//         &:hover {
//             color: var(--orange);
//         }
//     }

//     &__review-wrapper--active &__review {
//         display: none;
//     }
//     &__review-wrapper--active {
//     }



//     &__tabs-wrapper {
//         margin: 5px 0;

//         .tabs__list {
//             display: flex;
//             flex-wrap: wrap;
//             justify-content: space-around;
//         }

//         .tabs__item {
//             margin: 5px 0;
//             font-weight: 700;
//         }

//         .tabs__item--current {
//             text-decoration: underline;
//         }

//         .tabs__content {
//             margin-top: 10px;
//             padding: 0 25px;
//         }

//         .input__label-field {
//             font-size: 16px;
//         }

//         .input__fieldset {
//             &:not(:last-child) {
//                 margin-bottom: 15px;
//             }
//         }
//     }

//     &__input-container {
//         margin: 0 !important;
//     }

//     // begin summary
//     &__summary {
//         padding: 10px;
//         background-color: var(--light-gray-2);

//         h4 {
//             font-size: 18px;
//         }
//     }

//     &__summary-item {
//         display: flex;

//         &:not(:first-child) {
//             margin-top: 10px;
//         }
//     }

//     &__summary-img-wrapper {
//         width: 25%;
//     }

//     &__summary-info-wrapper {
//         flex: 1;
//         margin-left: 5px;

//         strong {
//             font-size: 14px;
//             font-weight: 300;
//         }
//     }

//     &__summary-info {
//         display: flex;
//         justify-content: space-between;
//         margin-top: 5px;

//         span {
//             font-weight: 700;
//         }
//     }

//     &__sale {
//         text-decoration: line-through;
//         color: var(--light-gray);
//     }

//     &__price {
//         margin-left: 3px;
//         color: var(--orange);
//     }

//     &__summary-count {
//         display: flex;
//         align-items: center;

//         .cards__favorite-icon {
//             margin-left: 5px;

//             cursor: pointer;
//             fill: var(--orange);

//             &:hover {
//                 fill: var(--gray);
//             }
//         }
//     }

//     &__bag-title {
//         color: inherit;
//         font-weight: bold;
//         text-decoration: none;
//     }

//     &__total-wrapper {
//         margin-top: 5px;
//         margin-bottom: 5px;

//         border-top: $border;
//         border-bottom: $border;

//         h4 {
//             margin-top: 10px;
//             margin-bottom: 10px;
//         }
//     }

//     &__total {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         &:last-child {
//             margin-top: 10px;
//             margin-bottom: 10px;
//         }
//     }

//     &__total-price {
//         font-weight: 700;
//         font-size: 18px;
//     }

//     &__total-amount {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         span {
//             font-weight: 700;
//         }
//     }

//     &__bonus {
//         display: block;
//         margin-top: 10px;

//         text-align: center;
//         font-weight: 300;
//         font-size: 14px;
//     }

//     // end summary


//     .btn-approve--checkout {}

//     .btn-approve--summary {
//         display: none;
//         pointer-events: none;
//     }

//     &__input-form {
//         margin-bottom: 30px;

//         .input__fieldset {
//             &:last-of-type {
//                 flex-basis: 50%;
//             }
//         }
//     }
// }

// @include rmin($desktop) {
//     .shop-cart,
//     #js-order-cart {
//         flex-direction: unset;

//         &__bag {
//             display: block !important;
//         }

//         &__bag-item {
//             display: flex;
//             padding-top: 25px;
//             padding-bottom: 25px;

//             &:not(:last-child) {
//                 border-bottom: $border;
//             }
//         }

//         &__bag-img {
//             width: 15%;
//         }

//         &__bag-info {
//             width: 45%;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             padding: 0 15px;

//             span {
//                 margin: 3px 0;
//             }
//         }

//         &__bag-title {
//             font-size: 22px;
//             margin-bottom: 5px;
//         }

//         &__bag-sizes {
//             display: flex;
//             align-items: center;
//             justify-content: space-around;

//             width: 17%;
//             padding: 0 15px;
//             border-left: $border;
//             border-right: $border;

//             div {
//                 display: flex;
//                 align-items: center;
//                 flex-direction: column;
//             }
//         }

//         &__bag-sizes-border {
//             display: block;
//             border: $border;
//             width: 50px;
//             text-align: center;
//         }

//         &__bag-price {
//             position: relative;
//             display: flex;
//             width: 23%;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;
//             padding: 0 15px;

//             .shop-cart__sale-wrapper {
//                 font-size: 18px;
//             }

//             .shop-cart__sale, .shop-cart__price {
//                 font-weight: 700;
//             }
//             .shop-cart__price {
//                 font-size: 30px;
//             }

//             .shop-cart__summary-count {
//                 position: absolute;
//                 top: 0;
//                 right: 0;
//             }
//         }

//         &__sale-text {}

//         &__checkout {

//             h2,
//             h3 {
//                 text-align: unset;
//             }
//         }

//         &__bag-wrapper {
//             order: -1;

//             flex: 1;
//             margin-right: 30px;
//         }

//         &__summary {
//             position: sticky;
//             top: 160px;
//             padding: 15px;
//         }

//         &__summary-info-wrapper {
//             strong {
//                 font-size: 16px;
//             }
//         }

//         &__summary-wrapper {
//             order: 1;

//             width: 325px;
//         }

//         &__input-form {
//             display: block;
//         }

//         &__input-container {
//             display: flex;
//             flex-wrap: wrap;
//             margin-right: 0 !important;
//         }

//         &__section-bonus {
//             strong {
//                 display: flex;
//                 align-items: flex-end;
//                 flex-basis: 50%;

//                 margin-top: 10px;
//                 padding-left: 15px;
//             }
//         }

//         &__section-bonus &__input-container {
//             align-items: center;
//         }

//         &__section-bonus .input__fieldset {
//             flex-basis: calc(50% - 20px) !important;
//             flex-grow: unset;
//         }

//         &__tabs-wrapper {
//             margin-bottom: 15px;

//             .tabs__list {
//                 justify-content: unset;
//             }

//             .tabs__item {
//                 padding: unset;

//                 &:not(:first-child) {
//                     margin-left: 50px;
//                 }

//                 &:nth-child(1) {
//                     order: 0;
//                 }

//                 &:nth-child(2) {
//                     order: 2;
//                 }

//                 &:nth-child(3) {
//                     order: 1;
//                 }
//             }

//             .tabs__content {
//                 padding: unset;
//             }
//         }

//         &__delivery-wrapper {
//             .input__fieldset {
//                 max-width: 250px;
//             }
//         }

//         &__section-review {
//             margin-top: 0;

//             text-align: unset;
//             border-top: $border !important;
//         }

//         .btn-approve--checkout {
//             display: none;
//             pointer-events: none;
//         }

//         .btn-approve--summary {
//             display: block;
//             pointer-events: auto;

//             margin-top: 5px;
//         }
//     }
// }


// .wa-order-form-wrapper .wa-step-confirm-section {
//     border-top: none !important;
//     padding: 0 !important;
// }

// .wa-order-form-wrapper .wa-step-section {
//     margin: 0 !important;
// }
