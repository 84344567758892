.product-filter {
    &__actions {
        display: flex;
        justify-content: space-between;
        flex: 1;
        flex-direction: row-reverse;

        button {
            width: unset;
            height: 35px;
            padding: 0;
            flex-grow: unset;
            border: unset;
            font-weight: 500;

            @include rmin($desktop) {
                padding: 2px 10px 0;
            }
        }

        @include rmin($desktop) {
            display: none;
        }
        .product-filter__counter {
            padding: 2px 4px 0 4px;
            font-size: 12px;
            border-radius: 60px;
        }
    }

    &__actions-left {
        .btn-n__default {
            display: none;
        }

        @include rmin($desktop) {
            display: flex;
            align-items: center;
            gap: 5px;

            .btn-n__default {
                display: block;
            }
        }
    }

    &__filters-block--opened {
        .tabs-category__list {
            padding: 15px 10px 0;
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-bottom: 10px;

        @include rmin($desktop) {
            display: none;
        }
    }

    &__quick-filter {
        .tabs__sub-category-filter {
            padding: unset;
        }
        .tabs__sub-link {
            display: flex;
            align-items: center;
            padding-left: 8px;
            height: 30px;
        }
        .product-filter__counter {
            display: flex;
        }

        .product-filter__reset {
            display: flex;
            padding-left: 7px;
            padding-right: 7px;

            svg {
                fill: currentColor;
                width: 20px;
                height: 20px;
            }
        }
    }

    &__search {
        @include r($desktop) {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
    }

    &__desktop {
        display: none;
        @media (min-width: 1024px) {
            display: flex;
            align-items: center;
        }
    }
    &__all-toggler {
        display: flex !important;
        align-items: center !important;

        svg {
            margin-right: 5px;
            width: 20px;
            height: 20px;
        }
    }
    &__clean-btn {
        padding: 7px 15px;
        font-size: 16px;
        text-align: left;
        background: var(--orange);
        color: var(--white);
    }

    &__counter {
        margin-left: 5px;
        padding: 3px 10px 2px 10px;
        font-size: 14px;
        line-height: 14px;
        color: var(--white);
        background: var(--orange);
        border-radius: 50px;
    }

    &__selected {
        font-size: 13px;
        color: var(--gray-500);
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    &__tabs-content {
        .product-filter__filter-wrapper .wrapper {
            font-weight: 500;
        }
    }

    &__arrow-back {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }
}

.product-filter {
    &__main-fields {
        position: fixed;
        // top: 50px;
        top: calc(50px + 35px);
        left: 0;
        right: 0;
        z-index: 3;

        display: flex;
        padding: 12px 10px;

        background-color: #fff;

        transition: transform 0.5s;
        will-change: transform;
    }

    // filters fields
    &__filters-fields {
    }

    // sorting fields
    &__sorting-fields {
    }

    // buttons
    &__all-toggler,
    &__toggler,
    &__close-toggler,
    &__mobile-hide {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 100%;
        padding: 14px 15px 13px 15px;

        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        color: inherit;
        border-bottom: 1px solid var(--light-gray);

        transition: color 0.1s ease-in, border-color 0.1s ease-in;
        will-change: color, border-color;

        font-size: 16px;
        text-align: left;
        font-weight: bold;

        svg {
            transition: transform 0.1s ease-in-out;
            will-change: transform;
        }

        &:hover,
        &:focus {
            color: var(--light-orange);
            border-color: currentColor;

            svg {
                fill: currentColor;
                transform: translateX(2px);
            }
        }

        &:active {
            color: inherit;
        }
    }

    &__all-toggler {
        display: block;
        min-width: 0;
        padding: 5px;

        font-size: 14px;
        text-align: center;

        border: 1px solid var(--light-gray);

        & {
            flex-grow: 1;
        }
    }

    &__close-toggler {
        &:hover,
        &:focus {
            // color: inherit;
            border-color: var(--light-gray);

            svg {
                transform: none;
            }
        }
        &--category-btn {
            background: var(--gray-200);
        }
    }

    &__toggler-box {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__toggler {
        // border: none;
    }

    &__toggler-title {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -3.5px;
            left: 0;
            right: 0;

            height: 2px;
            background-color: transparent;
        }
    }

    &__toggler--current &__toggler-title::after {
        background-color: #000;
    }

    // block
    &__filters-block,
    &__options-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;

        // display: none;
        // height: 100vh;
        // height: calc(var(--vh, 1vh) * 100);

        background-color: #fff;
        overflow-y: auto;
        overscroll-behavior: contain;
        transform: translateY(100vh);
        transition: transform 0.5s ease, opacity 0.35s ease;
        opacity: 0;
        will-change: transform, opacity;
    }

    &__filters-block {
        // pointer-events: none;
    }

    &__filters-block--opened,
    &__toggler--opened ~ &__filters-block {
        // display: block;
        transform: none;
        opacity: 1;
        overflow-y: hidden;
        pointer-events: unset;
    }

    &__toggler--opened ~ &__options-wrapper {
        display: flex;

        transform: none;
        opacity: 1;
    }

    &__filters-block &__filter-wrapper--product-category {
        display: none;
    }

    .s-range-item {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-top: 5px;

        font-size: 16px;
        color: var(--gray);

        input {
            display: flex;
            padding: 5px 10px;
            max-width: 70px;
            margin-right: 10px;
            margin-left: 5px;

            font-size: 14px;
            line-height: 18px;
            border: 2px solid #d6dbe0;
            border-radius: 3px;
            color: #001a34;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: border-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
            will-change: transform, border-color, background-color;
        }
    }

    &__filter-wrapper,
    &__options-wrapper {
        display: flex;
        flex-direction: column;
        max-height: 78vh;
        // max-height: 100vh;
        /* Use vh as a fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);

        overflow-y: auto;
        overscroll-behavior-y: contain;
    }

    &__filter-wrapper &__filter-wrapper {
        height: auto;
    }

    &__filter-wrapper--sm {
        height: auto;
    }

    &__list {
        // --height: calc(100% - 69px);
        // flex-grow: 1;
        max-height: var(--height);
        height: calc(var(--vh, 1vh) * 100);

        overflow-y: auto;
        overscroll-behavior-y: contain;

        /* width */
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--light-gray);
            border-radius: 10px;
            
            &:hover {
                background: var(--orange);
            }
        }
    }

    &__filter-wrapper > &__list {
        height: var(--height);
    }

    .wrapper {
        // height: 26px;
        // flex-grow: 1;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: .65px;
    }

    .wrapper-layout {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    label[for="checkbox"] {
        display: block;
        margin-bottom: 10px;
    }

    .checkbox-toggler {
        display: inline-block;
        position: relative;
        width: 55px;
        height: 20px;
        border: 2px solid white;
        box-shadow: 0 0 0 2px red;
        border-radius: 32px;
        margin: 0 15px;
    }

    .checkbox-toggler .toggler {
        width: 14px;
        height: 14px;
        background: red;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        z-index: -1;
        transition: left 150ms linear;
        transform: translateY(-50%);
    }

    .wrapper input:checked + .checkbox-toggler .toggler {
        left: 70%;
    }

    &__item {
    }

    &__mobile-hide {
        display: none;
    }

    // row
    &__row {
    }

    &__filter-head {
        padding-top: 20px;
        padding-bottom: 20px;

        font-size: 21px;
    }

    // options --> last menu
    &__options-wrapper {
        overflow-y: hidden;
        // display: none;
    }

    &__options-list {
        // padding: 20px 0;
    }

    &__options-item {
    }

    &__option {
        display: block;
        padding: 7.8px 15px;

        .filter-link {
            pointer-events: none;
        }
    }

    &__option-input {
    }

    &__option-text {
        position: relative;

        font-size: 18px;

        cursor: pointer;

        &::after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;

            height: 2px;

            background-color: transparent;
        }
    }

    &__option-input + &__option-text {
        display: flex;
        align-items: center;

        &::before {
            content: "";

            min-width: 20px;
            height: 20px;
            margin-right: 10px;

            background-color: #f9f9f9;
            border: 1px solid #9b9b9b;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 5px;

            height: 10px;
            width: 10px;

            background-color: #000;

            transform: translateY(-50%) scale(1.2);
            opacity: 0;
            transition: transform 0.15s ease, opacity 0.15s ease;
            will-change: transform, opacity;
        }
    }

    &__option-input:checked + &__option-text::after {
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }

    &__option-text--current {
    }

    &__submit-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6;

        padding: 40px 10px;

        background-color: #fff;
        border-top: 1px solid var(--light-gray);
        opacity: 0;
        transform: translateY(100%);
        pointer-events: none;
        transition: transform 0.3s ease-in;
        will-change: transform, opacity, pointer-events;
    }

    &__submit-wrapper--visible {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
    }

    &__submit-btn {
        width: 100%;

        border-radius: 12px;
    }

    // select filter
    &__select-filter-wrapper {
        padding: 15px;
        // background-color: var(--white-200);
        // border-bottom: 1px solid var(--gray-300);
    }

    &__selected-category {
        display: flex;
        padding: 7px 15px;

        font-size: 13px;
        color: var(--gray-500);
        background-color: var(--white-200);
    }

    &__select-filter-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    &__select-filter-title {
        display: flex;
        justify-content: space-between;

        strong,
        button {
            font-size: 14px;
            font-weight: 600;
        }

        button {
            color: var(--orange);

            &:hover {
                color: var(--light-orange);
            }
        }
    }

    &__select-filter-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        margin-top: 5px;

        color: var(--white);
        font-size: 14px;
        font-weight: 600;
        background-color: var(--orange);
        border-radius: 5px;

        cursor: pointer;

        &:not(:last-child) {
            margin-right: 5px;
        }

        span {
            position: relative;
            display: block;
            width: 10px;
            height: 10px;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                right: 0;
                content: " ";
                height: 10px;
                width: 2px;
                background-color: var(--white);
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        &:hover {
            background-color: var(--light-orange);
        }
    }
}

.site-header__container--scroll-up ~ main .product-filter__main-fields {
    transform: translateY(-85px);
}
.product-filter__filter-wrapper-v2 {
}

.product-filter-v2 {
    .product-filter__all-toggler,
    .product-filter__close-toggler,
    .product-filter__mobile-hide,
    .product-filter__toggler {
        font-weight: 500;
    }

    .dropdown-n__drop-list--mobile .dropdown-n__drop-list__input {
        margin: 0;
    }

    .product-filter__filters-block--opened,
    .product-filter__toggler--opened ~ .product-filter__filters-block {
        // top: 85px;
        // height: calc(100vh - 85px);
    }

    .dropdown-n__drop-list .product-filter__options-list {
        height: auto;
    }
    .product-filter__submit-wrapper {
        padding-top: 15px;
        padding-bottom: 10px;
        border-top: none;
        transform: translateY(0);
        opacity: 1;
    }

    .product-filter {
        .wrapper-layout {
            font-weight: 500 !important;
        }
        &__toggler-price {
            display: block;
        }
        &__toggler-title {
            &::after {
                content: "";
                position: absolute;
                bottom: -3.5px;
                left: 0;
                right: 0;

                height: 2px;
                background-color: transparent;
            }
        }

        &__toggler--current &__toggler-title::after {
            background-color: none;
        }

        &__btn-top {
            display: flex;
            justify-content: space-between;
            svg {
                transform: rotate(90deg);
            }
        }
        &__tabs-content {
            // top: 85px;
            .product-filter__box {
                padding: 15px 10px;
                padding-bottom: 10px;
                margin-bottom: 0;
            }
            .product-filter__search {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                padding: 0 10px;
                a {
                    background: none;
                }
            }
        }
    }

    .s-range-item input {
        text-align: center;
        min-width: 90px;
        max-width: 100%;
        width: 100%;
        border-radius: 10px;
    }

    .product-filter .wrapper {
        font-weight: 500;
    }
}

@include rmin($tablet) {
    .product-filter {
        // select filter
        &__select-filter-list {
            margin-top: unset;
        }
    }
}

@include rmin($desktop) {
    .product-filter {
        display: block;

        // block
        &__filters-block,
        &__options-wrapper {
            position: static;

            transform: none;
            opacity: 1;
        }

        &__filters-block,
        &__options-wrapper,
        &__filters-block &__filter-wrapper--product-category {
            display: block;
        }

        &__mobile-hide {
            display: flex;
        }

        &__filters-block > &__filter-wrapper:not(:last-child) {
            margin-bottom: 20px;

            border-bottom: 4px solid var(--orange);
        }

        &__filters-block,
        &__filter-wrapper,
        &__options-wrapper,
        &__filter-wrapper > &__list,
        &__list {
            max-height: none;
            height: auto;

            overflow-x: hidden;

            overscroll-behavior: unset;
            overscroll-behavior-y: unset;
        }

        &__box {
        }

        // togglers
        &__toggler,
        &__close-toggler,
        &__mobile-hide {
            padding: 10px 0;

            font-size: 18px;

            border-bottom-color: transparent;

            svg {
                width: 14px;
                height: 12px;
            }
        }

        &__main-fields,
        &__close-toggler--category-btn,
        &__options-wrapper > &__close-toggler {
            display: none;
        }

        .s-range-item {
            margin-top: 10px;

            input {
                max-width: none;
            }
        }

        .wrapper {
            margin-top: 0;
            margin-bottom: 10px;

            font-size: 16px;
        }

        .wrapper-layout {
            justify-content: space-between;
        }

        &__submit-wrapper {
            position: static;

            display: block;
            padding: 10px 0;

            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
            border-top: 0;
        }

        &__toggler-title::after {
            bottom: -10px;

            height: 3px;
        }

        &__toggler--opened {
            border-bottom-color: var(--light-gray);
        }

        &__toggler--opened &__toggler-title::after {
            background-color: var(--orange);
        }

        &__toggler:not([class*="--opened"]) svg {
            transform: rotateZ(90deg) !important;
        }

        &__toggler[class*="--opened"] svg {
            transform: rotateZ(0) !important;
        }

        &__filter-head {
            // padding: 0;
        }

        &__options-list {
        }
        // options
        &__options-list,
        &__mobile-hide + div:not([class]) {
            max-height: 0;
            margin: 0;
            margin-bottom: 10px;

            transition: max-height 0.4s;
            overflow-y: hidden;
            will-change: max-height;
        }

        &__toggler--opened + &__options-list,
        &__filter-wrapper
            > &__toggler--opened
            + &__options-wrapper
            > &__options-list,
        &__toggler:not([class*="--opened"]) + div:not([class]) {
            // &__toggler + &__options-list,
            // &__filter-wrapper > &__toggler + &__options-wrapper > &__options-list,
            // &__toggler + div:not([class]) {
            max-height: 2500px;
        }

        &__list > &__item:not(&__options-item) {
            margin-bottom: 15px;
        }

        &__option {
            padding: 7px 10px;
        }

        &__option--current {
        }

        &__option-text::after {
            content: "";
            bottom: -2.5px;
        }

        &__mobile-hide + div:not([class]) {
            padding-left: 10px;

            & * {
                font-weight: normal;
            }
        }

        &__option--current &__option-text::after {
            background-color: var(--orange);
        }

        &__option--opened &__option-text {
            position: relative;
        }

        // sorting
        &__sorting-fields {
            position: absolute;
            top: 0;
            min-width: 800px;
            left: 385px;
        }

        &__sorting-fields &__close-toggler--category-btn {
            display: block;
            min-width: auto;

            margin-right: 15px;

            border: none !important;

            // &:hover,
            // &:focus {
            // 	border: none !important;
            // }

            svg {
                display: none;
            }
        }

        &__sorting-fields &__filter-wrapper {
            display: flex;
            flex-direction: unset;
            align-items: baseline;
        }

        &__sorting-fields &__list {
            display: flex;
        }

        &__sorting-fields &__item {
            margin-bottom: 0 !important;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__sorting-fields &__item &__toggler {
            font-size: 16px;
            font-weight: normal;

            border: none !important;
            border-bottom: none !important;

            &:hover,
            &:focus {
            }
        }

        &__sorting-fields &__toggler-title::after {
            bottom: -5px;

            height: 2px;
        }

        &__sorting-fields &__submit-wrapper {
            display: none;
        }

        // select filter
        &__select-filter-wrapper {
            margin-bottom: 25px;
            padding: unset;
            background-color: unset;
            border-bottom: unset;
        }

        &__select-filter-title {
            justify-content: unset;

            button {
                margin-left: 15px;
            }
        }
    }
}
